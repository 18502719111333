import types from './types';

const initialState = {
  data: {},
  success: false,
  loading: false
}

export default (state = initialState, action) => {
  switch(action.type){
    case types.UPLOAD_PROOF_PAYMENT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        success: action.payload.success
      }
    case types.IS_LOADING_UPLOAD_PROOF_PAYMENT:
      return {
        ...state,
        loading: action.payload
      }
    case types.RESET_UPLOAD_PROOF_PAYMENT:
      return initialState;
    default:
      return state;
  }
}

export const uploadProofPayment = payload => ({
  type: types.UPLOAD_PROOF_PAYMENT,
  payload
})
export const uploadProofPaymentSuccess = payload => ({
  type: types.UPLOAD_PROOF_PAYMENT_SUCCESS,
  payload
})
export const setLoadingUploadProofPayment = payload => ({
  type: types.IS_LOADING_UPLOAD_PROOF_PAYMENT,
  payload
})
export const resetUploadProofPayment = payload => ({
  type: types.RESET_UPLOAD_PROOF_PAYMENT,
  payload
})
