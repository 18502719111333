import types from "./constant";

const initialState = {
  count: 0,
  data: [],
  isLoadingGet: false,
  isLoadingUpdate: false,
  isLoadingAdd: false,
  isLoadingDelete: false,
  success: false,
  message: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SHOWCASES_SUCCESS:
      return { ...state, data: action.payload };
    case types.SET_COUNT_GET_SHOWCASES:
      return { ...state, count: action.payload };
    case types.IS_LOADING_GET_SHOWCASES:
      return { ...state, isLoadingGet: action.payload };
    case types.ADD_SHOWCASE_SUCCESS:
      return Object.assign({}, state, {
        data: [action.payload, ...state.data],
      });
    case types.IS_LOADING_ADD_SHOWCASE:
      return { ...state, isLoadingAdd: action.payload };
    case types.UPDATE_SHOWCASE_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        ),
      });
    case types.IS_LOADING_UPDATE_SHOWCASE:
      return { ...state, isLoadingUpdate: action.payload };
    case types.DELETE_SHOWCASE_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.filter((item) => item.id !== action.payload.id),
      });
    case types.IS_LOADING_DELETE_SHOWCASE:
      return { ...state, isLoadingDelete: action.payload };
    case types.SET_MESSAGE_SHOWCASE:
      return {
        ...state,
        success: action.payload.success,
        message: action.payload.message,
      };
    case types.SET_SUCCESS_SHOWCASE:
      return { ...state, success: action.payload };
    case types.RESET_SHOWCASE:
      return initialState;
    default:
      return state;
  }
};

export const getShowcases = (payload) => ({
  type: types.GET_SHOWCASES,
  payload,
});
export const getShowcasesSuccess = (payload) => ({
  type: types.GET_SHOWCASES_SUCCESS,
  payload,
});
export const setLoadingGetShowcases = (payload) => ({
  type: types.IS_LOADING_GET_SHOWCASES,
  payload,
});

export const addShowcase = (payload) => ({
  type: types.ADD_SHOWCASE,
  payload,
});
export const addShowcaseSuccess = (payload) => ({
  type: types.ADD_SHOWCASE_SUCCESS,
  payload,
});
export const setLoadingAddShowcase = (payload) => ({
  type: types.IS_LOADING_ADD_SHOWCASE,
  payload,
});

export const updateShowcase = (payload) => ({
  type: types.UPDATE_SHOWCASE,
  payload,
});
export const updateShowcaseSuccess = (payload) => ({
  type: types.UPDATE_SHOWCASE_SUCCESS,
  payload,
});
export const setIsLoadingUpdateShowcase = (payload) => ({
  type: types.IS_LOADING_UPDATE_SHOWCASE,
  payload,
});

export const deleteShowcase = (payload) => ({
  type: types.DELETE_SHOWCASE,
  payload,
});
export const deleteShowcaseSuccess = (payload) => ({
  type: types.DELETE_SHOWCASE_SUCCESS,
  payload,
});
export const setIsLoadingDeleteShowcase = (payload) => ({
  type: types.IS_LOADING_DELETE_SHOWCASE,
  payload,
});

export const setSuccess = (payload) => ({
  type: types.SET_SUCCESS_SHOWCASE,
  payload,
});
export const setMessage = (payload) => ({
  type: types.SET_MESSAGE_SHOWCASE,
  payload,
});
export const resetShowcase = (payload) => ({
  type: types.RESET_SHOWCASE,
});
