import { toast } from "react-toastify";

export const notification = {
  default: (text, style) =>
    toast(text, {
      style: {
        minHeight: style && style.minHeight ? style.minHeight : 35,
        top: style && style.top ? style.top : 50,
        ...style,
      },
      position: "top-center",
      autoClose: 3500,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
    }),
  warn: (text, style) =>
    toast.warn(text, {
      style: {
        minHeight: style && style.minHeight ? style.minHeight : 35,
        top: style && style.top ? style.top : 50,
        ...style,
      },
      position: "top-center",
      autoClose: 3500,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
    }),
  info: (text, style) =>
    toast.info(text, {
      style: {
        minHeight: style && style.minHeight ? style.minHeight : 35,
        top: style && style.top ? style.top : 50,
        ...style,
      },
      position: "top-center",
      autoClose: 3500,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
    }),
  success: (text, style) =>
    toast.success(text, {
      style: {
        minHeight: style && style.minHeight ? style.minHeight : 35,
        top: style && style.top ? style.top : 50,
        ...style,
      },
      position: "top-center",
      autoClose: 3500,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
    }),
  error: (text, style) =>
    toast.error(text, {
      style: {
        minHeight: style && style.minHeight ? style.minHeight : 35,
        top: style && style.top ? style.top : 50,
        ...style,
      },
      position: "top-center",
      autoClose: 3500,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
    }),
};
