export default {
  GET_COMPLAINTS : 'get-complaints',
  GET_COMPLAINTS_SUCCESS : 'get-complaints-success',
  IS_LOADING_GET_COMPLAINTS : 'set-is-loading-het-complaints',
  SET_COUNT_COMPLAINTS : 'set-count-complaints',

  GET_DETAIL_COMPLAINT : 'get-detail-complaint',
  GET_DETAIL_COMPLAINT_SUCCESS : 'get-detail-complaint-success',
  IS_LOADING_GET_DETAIL_COMPLAINT : 'set-is-loading-het-detail-complaint',

  GET_COMPLAINT_HISTORIES : 'get-complaint-histories',
  GET_COMPLAINT_HISTORIES_SUCCESS : 'get-complaint-histories-success',
  IS_LOADING_GET_COMPLAINT_HISTORIES : 'is-loading-get-complaint-histories',

  GET_COMPLAINT_DISCUSSIONS : 'get-complaint-discussions',
  GET_COMPLAINT_DISCUSSIONS_SUCCESS : 'get-complaint-discussions-success',
  IS_LOADING_GET_COMPLAINT_DISCUSSIONS : 'is-loading-get-complaint-discussions',

  GET_SERVICES_COMPLAINT : 'get-services-complaint',
  GET_SERVICES_COMPLAINT_SUCCESS : 'get-services-complaint-success',
  IS_LOADING_GET_SERVICES_COMPLAINT : 'is-loading-get-services-complaint',

  POST_COMPLAINT_DISCUSSION : 'post-complaint-discussion',
  POST_COMPLAINT_DISCUSSION_SUCCESS : 'post-complaint-discussion-success',
  IS_LOADING_POST_COMPLAINT_DISCUSSION : 'is-loading-post-complaint-discussion',

  POST_BID_PRICE_COMPLAINT : 'post-price-bid-complaint',
  POST_BID_PRICE_COMPLAINT_SUCCESS : 'post-price-bid-complaint-success',
  IS_LOADING_POST_BID_PRICE_COMPLAINT : 'is-loading-post-price-bid-complaint',
  SET_BID_PRICE_SUCCESS : 'set-price-bid-success',

  POST_DELIVERY_RECEIPT_COMPLAINT : 'post-delivery-receipt-complaint',
  POST_DELIVERY_RECEIPT_COMPLAINT_SUCCESS : 'post-delivery-receipt-complaint-success',
  IS_LOADING_POST_DELIVERY_RECEIPT_COMPLAINT : 'is-loading-post-delivery-receipt-complaint',
  SET_DELIVERY_RECEIPT_SUCCESS : 'set-delivery-receipt-success',

  PUT_APPROVED_COMPLAINT : 'put-approved-complaint',
  PUT_APPROVED_COMPLAINT_SUCCESS : 'put-approved-complaint-success',
  IS_LOADING_PUT_APPROVED_COMPLAINT : 'is-loading-put-approved-complaint',
  SET_APPROVED_SUCCESS : 'set-approved-success',

  ASK_FOR_HELP_COMPLAINT : 'ask-for-help-complaint',
  ASK_FOR_HELP_COMPLAINT_SUCCESS : 'ask-for-help-complaint-success',
  IS_LOADING_ASK_FOR_HELP_COMPLAINT : 'is-loading-ask-for-help-complaint',
  SET_SUCCESS_ASK_FOR_HELP_COMPLAINT   : 'set-ask-for-help-success-complaint',
};
