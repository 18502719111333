import types from './constant';

const initialState = {
  categories:[],
  isLoadingCategories:false,
  showcases:[],
  isLoadingShowcase:false,
  isLoadingAddShowcase:false,
  detail:{},
  count:0,
  notification:{
    data:{
      empty:0,
      active:0,
      check:0,
      block:0,
      archive:0,
      delete:0
    },
    loading: false
  },
  data:[],
  isLoadingGet: false,
  isLoadingUpdate: false,
  isLoadingAdd: false,
  isLoadingUpdateMultiple: false,
  success:false,
  message:'',
};

export default (state = initialState, action)=>{
  switch (action.type) {
    case types.GET_PRODUCTS_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: {
          ...state.notification,
          data: action.payload
        }
      }
    case types.IS_LOADING_GET_PRODUCTS_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          loading: action.payload
        }
      }
    case types.GET_PRODUCTS_SUCCESS:
      return {...state, data:action.payload}
    case types.SET_COUNT_GET_PRODUCTS:
      return {...state, count:action.payload}
    case types.IS_LOADING_GET_PRODUCTS:
      return {...state, isLoadingGet:action.payload}
    case types.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        detail:{
          ...action.payload,
          GrocierPrices:  action.payload.GrocierPrices && action.payload.GrocierPrices.length > 0 ? action.payload.GrocierPrices.sort((a, b) => a.min_order - b.min_order) : [],
          ImageProducts:  action.payload.ImageProducts && action.payload.ImageProducts.length > 0 ? action.payload.ImageProducts.sort((a, b) => a.is_default === b.is_default ? 0 : a.is_default ? -1 : 1) : []
        }
      }
    // case types.ADD_PRODUCT_SUCCESS:
    //   return Object.assign({}, state, {
    //     data: [...state.data, action.payload],
    //     detail: action.payload,
    //   })
    case types.IS_LOADING_ADD_PRODUCT:
      return {...state, isLoadingAdd:action.payload}
    case types.UPDATE_PRODUCT_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload.updated || action.payload.tab === "all" ? state.data.map(item => item.id === action.payload.id ? {...item, ...action.payload} : item) : state.data.filter(item => item.id !== action.payload.id),
        count: state.count > 0 && !action.payload.updated ? state.count - 1 : state.count,
        detail: {
          ...state.detail,
          ...action.payload,
          GrocierPrices: action.payload.grocier_price && action.payload.grocier_price.length > 0 ? action.payload.grocier_price.sort((a, b) => a.min_order - b.min_order) : []
        }
      })
    case types.IS_LOADING_UPDATE_PRODUCT:
      return {...state, isLoadingUpdate:action.payload}
    case types.SET_MESSAGE_PRODUCT:
      return {...state,success: action.payload.success, message: action.payload.message}

    case types.UPDATE_MULTIPLE_STATUS_PRODUCT_SUCCESS:
      return {
        ...state,
        data: state.data.filter(item => !action.payload.ids.includes(item.id))
      }
    case types.IS_LOADING_UPDATE_MULTIPLE_STATUS_PRODUCT:
      return { ...state, isLoadingUpdateMultiple: action.payload }
    case types.GET_SHOWCASES_SUCCESS:
      return {...state, showcases:action.payload}
    case types.IS_LOADING_GET_SHOWCASES:
      return {...state, isLoadingShowcase:action.payload}
    case types.ADD_SHOWCASE_SUCCESS:
      return Object.assign({}, state, {
        showcases: [action.payload, ...state.showcases]
      })
    case types.IS_LOADING_ADD_SHOWCASE:
      return {...state, isLoadingAddShowcase:action.payload}
    case types.GET_CATEGORIES_SUCCESS:
      return {...state, categories:action.payload}
    case types.IS_LOADING_GET_CATEGORIES:
      return {...state, isLoadingCategories:action.payload}
    case types.SET_SUCCESS_PRODUCT:
      return {...state, success: action.payload}
    case types.RESET_PRODUCT:
      return initialState
    default:
      return state;
  }
}
export const setSuccess = payload => ({
  type: types.SET_SUCCESS_PRODUCT,
  payload
})
export const setMessage = payload => ({
  type: types.SET_MESSAGE_PRODUCT,
  payload
})

export const updateMultipleStatus = payload => ({
  type: types.UPDATE_MULTIPLE_STATUS_PRODUCT,
  payload
})
export const updateMultipleStatusSuccess = payload => ({
  type: types.UPDATE_MULTIPLE_STATUS_PRODUCT_SUCCESS,
  payload
})
export const setIsLoadingUpdateMultipleStatus = payload => ({
  type: types.IS_LOADING_UPDATE_MULTIPLE_STATUS_PRODUCT,
  payload
})

export const getProductsNotification = payload => ({
  type: types.GET_PRODUCTS_NOTIFICATION,
  payload
})
export const getProductsNotificationSuccess = payload => ({
  type: types.GET_PRODUCTS_NOTIFICATION_SUCCESS,
  payload
})
export const setIsLoadingGetProductsNotification = payload => ({
  type: types.IS_LOADING_GET_PRODUCTS_NOTIFICATION,
  payload
})

export const getProducts = payload => ({
  type: types.GET_PRODUCTS,
  payload
})
export const getProductsSuccess = payload => ({
  type: types.GET_PRODUCTS_SUCCESS,
  payload
})
export const setCountGetProducts = payload => ({
  type: types.SET_COUNT_GET_PRODUCTS,
  payload
})
export const setIsLoadingGetProducts = payload => ({
  type: types.IS_LOADING_GET_PRODUCTS,
  payload
})

export const getProduct = payload => ({
  type: types.GET_PRODUCT,
  payload
})
export const getProductSuccess = payload => ({
  type: types.GET_PRODUCT_SUCCESS,
  payload
})

export const addProduct = payload => ({
  type: types.ADD_PRODUCT,
  payload
})
export const addProductSuccess = payload => ({
  type: types.ADD_PRODUCT_SUCCESS,
  payload
})
export const setIsLoadingAddProduct = payload => ({
  type: types.IS_LOADING_ADD_PRODUCT,
  payload
})


export const updateProduct = payload => ({
  type: types.UPDATE_PRODUCT,
  payload
})
export const updateProductSuccess = payload => ({
  type: types.UPDATE_PRODUCT_SUCCESS,
  payload
})
export const setIsLoadingUpdateProduct = payload => ({
  type: types.IS_LOADING_UPDATE_PRODUCT,
  payload
})

export const getShowcases = payload => ({
  type: types.GET_SHOWCASES,
  payload
})
export const getShowcasesSuccess = payload => ({
  type: types.GET_SHOWCASES_SUCCESS,
  payload
})
export const setLoadingGetShowcases = payload => ({
  type: types.IS_LOADING_GET_SHOWCASES,
  payload
})

export const addShowcase = payload => ({
  type: types.ADD_SHOWCASE,
  payload
})
export const addShowcaseSuccess = payload => ({
  type: types.ADD_SHOWCASE_SUCCESS,
  payload
})
export const setLoadingAddShowcase = payload => ({
  type: types.IS_LOADING_ADD_SHOWCASE,
  payload
})

export const getCategories = payload => ({
  type: types.GET_CATEGORIES,
  payload
})
export const getCategoriesSuccess = payload => ({
  type: types.GET_CATEGORIES_SUCCESS,
  payload
})
export const setLoadingGetCategories = payload => ({
  type: types.IS_LOADING_GET_CATEGORIES,
  payload
})

export const resetProduct = payload => ({
  type: types.RESET_PRODUCT
})
