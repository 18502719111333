import axios from 'axios';
import config from '../config';
export const sellerBaseUrl = process.env.NODE_ENV === 'development' ? config.development.SELLER_BASE_URL : config.production.SELLER_BASE_URL;
export const apiUrl = process.env.NODE_ENV === 'development' ? config.development.API_BASE_URL : config.production.API_BASE_URL;
export const baseUrl = process.env.NODE_ENV === 'development' ? config.development.BASE_URL : config.production.BASE_URL;
export const baseUrlPlasgos = process.env.NODE_ENV === 'development' ? config.development.API_BASE_URL : config.production.API_BASE_URL;
export const baseUrlMobilePlasgos = process.env.NODE_ENV === 'development' ? config.development.MOBILE_BASE_URL : config.production.MOBILE_BASE_URL;
export const baseUrlPlasgosExpress = process.env.NODE_ENV === 'development' ? config.development.API_EXPEDITION : config.production.API_EXPEDITION;
export const apiGoogleMap = process.env.NODE_ENV === 'development' ? config.development.API_KEY_GOOGLE_MAP : config.production.API_KEY_GOOGLE_MAP;
export const Api = axios.create({baseURL: baseUrlPlasgos});
export const ApiExpress = axios.create({baseURL: baseUrlPlasgosExpress});
