import { Api } from './api';

export default {
  get: payload => Api.get('/v1/complaints/seller',{
    headers: {
      token: payload.token
    }
  }),
  getDetail: payload => Api.get(`/v1/complaints/seller/${payload.complaint_id}`,{
    headers: {
      token: payload.token
    }
  }),
  getHistories: payload => Api.get(`/v1/complaints/histories/${payload.complaint_id}`,{
    headers: {
      token: payload.token
    }
  }),
  getDiscussions: payload => Api.get(`/v1/complaints/discussions/${payload.complaint_id}`, {
    headers:{token:payload.token}
  }),
  postDiscussion: payload => Api.post(`/v1/complaints/discussions/${payload.complaint_id}`, {
    text:payload.text
  },{
    headers:{token:payload.token}
  }),
  postBid: payload => Api.post(`/v1/complaints/histories/price-bid/${payload.complaint_id}`, {
    refund:payload.refund
  },{
    headers:{token:payload.token}
  }),
  postDeliveryReceipt: payload => Api.post(`/v1/complaints/histories/delivery-receipt/${payload.complaint_id}`, payload.data,{
    headers:{token:payload.token}
  }),
  putApprove: payload => Api.put(`/v1/complaints/approved-by-seller/${payload.complaint_id}`, {},{
    headers:{token:payload.token}
  }),
  askForHelp: payload => Api.put(`/v1/complaints/ask-for-help/${payload.complaint_id}`, {},{
    headers:{token:payload.token}
  }),
  getServices: payload => Api.get(`/v1/services`),
}
