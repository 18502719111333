export default {
  //get address
  GET_ALL_BANK_ACCOUNT: "GET_ALL_BANK_ACCOUNT",
  GET_ALL_BANK_ACCOUNT_SUCCESS: "GET_ALL_BANK_ACCOUNT_SUCCESS",
  IS_LOADING_GET_BANK_ACCOUNT: "IS_LOADING_GET_BANK_ACCOUNT",
  //create address
  CREATE_BANK_ACCOUNT: "CREATE_BANK_ACCOUNT",
  CREATE_BANK_ACCOUNT_SUCCESS: "CREATE_BANK_ACCOUNT_SUCCESS",
  IS_LOADING_CREATE_BANK_ACCOUNT: "IS_LOADING_CREATE_BANK_ACCOUNT",
  //verify otp bank account
  VERIFY_OTP_BANK_ACCOUNT: "VERIFY_OTP_BANK_ACCOUNT",
  VERIFY_OTP_BANK_ACCOUNT_SUCCESS: "VERIFY_OTP_BANK_ACCOUNT_SUCCESS",
  IS_LOADING_VERIFY_OTP_BANK_ACCOUNT: "IS_LOADING_VERIFY_OTP_BANK_ACCOUNT",
  //resend otp
  RESEND_OTP_BANK_ACCOUNT: "RESEND_OTP_BANK_ACCOUNT",
  RESEND_OTP_BANK_ACCOUNT_SUCCESS: "RESEND_OTP_BANK_ACCOUNT_SUCCESS",
  IS_LOADING_RESEND_OTP_BANK_ACCOUNT: "IS_LOADING_RESEND_OTP_BANK_ACCOUNT",
  //edit address
  EDIT_BANK_ACCOUNT: "EDIT_BANK_ACCOUNT",
  EDIT_BANK_ACCOUNT_SUCCESS: "EDIT_BANK_ACCOUNT_SUCCESS",
  IS_LOADING_EDIT_BANK_ACCOUNT: "IS_LOADING_EDIT_BANK_ACCOUNT",
  //set default address
  SET_DEFAULT_BANK_ACCOUNT: "SET_DEFAULT_BANK_ACCOUNT",
  SET_DEFAULT_BANK_ACCOUNT_SUCCESS: "SET_DEFAULT_BANK_ACCOUNT_SUCCESS",
  IS_LOADING_SET_DEFAULT_BANK_ACCOUNT: "IS_LOADING_SET_DEFAULT_BANK_ACCOUNT",
  //delete address
  DELETE_BANK_ACCOUNT: "DELETE_BANK_ACCOUNT",
  DELETE_BANK_ACCOUNT_SUCCESS: "DELETE_BANK_ACCOUNT_SUCCESS",
  IS_LOADING_DELETE_BANK_ACCOUNT: "IS_LOADING_DELETE_BANK_ACCOUNT",
  //GET_BANKS
  GET_BANKS: "GET_BANKS",
  GET_BANKS_SUCCESS: "GET_BANKS_SUCCESS",
  IS_LOADING_GET_BANKS: "IS_LOADING_GET_BANKS",
  //RESPONSE_SUCCESS
  RESPONSE_SUCCESS: "RESPONSE_SUCCESS_BANK",
};
