import types from './types';

const initialState = {
  fb_pixel:{
    data: [],
    added: false,
    updated: false,
    deleted: false,
    loading_get: false,
    loading_add: false,
    loading_update: false,
    loading_delete: false,
  }
}

export default (state=initialState, action) => {
  switch (action.type) {
    case types.GET_FB_PIXEL_SUCCESS:
      return {
        ...state,
        fb_pixel: {
          ...state.fb_pixel,
          data: action.payload
        }
      }
    case types.IS_LOADING_GET_FB_PIXEL:
      return {
        ...state,
        fb_pixel: {
          ...state.fb_pixel,
          loading_get: action.payload
        }
      }
    case types.ADD_FB_PIXEL_SUCCESS:
      return {
        ...state,
        fb_pixel: {
          ...state.fb_pixel,
          data: [action.payload, ...state.fb_pixel.data]
        }
      }
    case types.IS_LOADING_ADD_FB_PIXEL:
      return {
        ...state,
        fb_pixel: {
          ...state.fb_pixel,
          loading_add: action.payload
        }
      }
    case types.UPDATE_FB_PIXEL_SUCCESS:
      return {
        ...state,
        fb_pixel:{
          ...state.fb_pixel,
          data: state.fb_pixel.data.map(item => item._id === action.payload._id ? {
            ...action.payload
          } : item)
        }
      }
    case types.IS_LOADING_UPDATE_FB_PIXEL:
      return {
        ...state,
        fb_pixel:{
          ...state.fb_pixel,
          loading_update: action.payload
        }
      }
    case types.DELETE_FB_PIXEL_SUCCESS:
      return {
        ...state,
        fb_pixel:{
          ...state.fb_pixel,
          data: state.fb_pixel.data.filter(item => item._id !== action.payload.id)
        }
      }
    case types.IS_LOADING_DELETE_FB_PIXEL:
      return {
        ...state,
        fb_pixel:{
          ...state.fb_pixel,
          loading_delete: action.payload
        }
      }
    case types.SET_ADDED_FB_PIXEL:
      return {
        ...state,
        fb_pixel:{
          ...state.fb_pixel,
          added: action.payload
        }
      }
    case types.SET_UPDATED_FB_PIXEL:
      return {
        ...state,
        fb_pixel:{
          ...state.fb_pixel,
          updated: action.payload
        }
      }
    case types.SET_DELETED_FB_PIXEL:
      return {
        ...state,
        fb_pixel:{
          ...state.fb_pixel,
          deleted: action.payload
        }
      }
    default:
      return state;
  }
}
export const getFBPixel = payload => ({
  type:types.GET_FB_PIXEL,
  payload
})
export const getFBPixelSuccess = payload => ({
  type:types.GET_FB_PIXEL_SUCCESS,
  payload
})
export const setIsLoadingGetFBPixel = payload => ({
  type:types.IS_LOADING_GET_FB_PIXEL,
  payload
})

export const addFBPixel = payload => ({
  type:types.ADD_FB_PIXEL,
  payload
})
export const addFBPixelSuccess = payload => ({
  type:types.ADD_FB_PIXEL_SUCCESS,
  payload
})
export const setIsLoadingAddFBPixel = payload => ({
  type:types.IS_LOADING_ADD_FB_PIXEL,
  payload
})

export const updateFBPixel = payload => ({
  type:types.UPDATE_FB_PIXEL,
  payload
})
export const updateStoreFBPixel = payload => ({
  type:types.UPDATE_STORE_FB_PIXEL,
  payload
})
export const updateProductFBPixel = payload => ({
  type:types.UPDATE_PRODUCT_FB_PIXEL,
  payload
})
export const updateFBPixelSuccess = payload => ({
  type:types.UPDATE_FB_PIXEL_SUCCESS,
  payload
})
export const setIsLoadingUpdateFBPixel = payload => ({
  type:types.IS_LOADING_UPDATE_FB_PIXEL,
  payload
})

export const deleteFBPixel = payload => ({
  type:types.DELETE_FB_PIXEL,
  payload
})
export const deleteFBPixelSuccess = payload => ({
  type:types.DELETE_FB_PIXEL_SUCCESS,
  payload
})
export const setIsLoadingDeleteFBPixel = payload => ({
  type:types.IS_LOADING_DELETE_FB_PIXEL,
  payload
})

export const setAddedFBPixel = payload => ({
  type:types.SET_ADDED_FB_PIXEL,
  payload
})
export const setUpdatedFBPixel = payload => ({
  type:types.SET_UPDATED_FB_PIXEL,
  payload
})
export const setDeletedFBPixel = payload => ({
  type:types.SET_DELETED_FB_PIXEL,
  payload
})
