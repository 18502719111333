import {put, call, takeLatest} from 'redux-saga/effects';
import createHostGuest from 'cross-domain-storage/guest';

import Api from '../../services';
import types from './constant';
import * as actions from './reducer';
import { baseUrl, sellerBaseUrl } from '../../services/api';


export const getTokenFromHost = async (cb) => {
  try{
    var storageHost = createHostGuest(baseUrl);
    await storageHost.get('key_x-log', async (error, value) => {
      if(value && value.length > 0){
        cb(value)
      }else{
        await localStorage.clear()
        window.location.href=`${baseUrl}/login?p=${encodeURIComponent(sellerBaseUrl)}`;
      }
    })
  }catch(e){}
}
function* watchAuthenticatonUser(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingAuthentication(true));
  try{
    const response = yield call(Api.authentication.sessions, payload);
    const { data } = response;
    if (data.success) {
      if(data.store){
        let result = {
          user: data,
          token: data.token,
          logged_in: true
        }
        yield put(actions.getAuthenticationSuccess(result));
        yield localStorage.setItem('key_x-log', data.token)
        yield localStorage.setItem('lstlgn', JSON.stringify({nm:data.name, pic:data.avatar_img}))
      }else{
        window.location.href=`${baseUrl}/open-shop?p=${encodeURIComponent(sellerBaseUrl)}`
      }
    }else{
      throw Error;
    }
  }catch(e){
    let result = {
      user: {},
      token: '',
      logged_in: false
    }
    yield put(actions.getAuthenticationSuccess(result));
  }finally{
    yield createHostGuest(baseUrl).close();
    yield put(actions.setIsLoadingAuthentication(false));
  }
}
function* watchLastLogin(value){
  const { payload } = value;
  yield put(actions.setIsLoadingLastLogin(true));
  yield put(actions.getLastLoginSuccess({}))
  try{
    const response = yield call(Api.authentication.lastLogin, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.getLastLoginSuccess(data.data))
    }
  }catch(e){
    yield put(actions.setIsLoadingLastLogin(false));
    yield put(actions.getLastLoginSuccess({}))
  }finally{
    yield put(actions.setIsLoadingLastLogin(false));
  }
}
const loginSagas = [
  takeLatest(types.AUTHENTICATION, watchAuthenticatonUser),
  takeLatest(types.LAST_LOGIN, watchLastLogin)
];

export default loginSagas;
