import {put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../services';
import types from './types';
import * as actions from './reducer';

function* watchGetDistrictAreas(value){
  const { payload } = value;
  yield put(actions.setLoading(true));
  try{
    const response = yield call(Api.coveragearea.district, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.getDistrictAreasSuccess(data.data));
    }
  }catch(e){
    yield put(actions.setLoading(false));
  }finally{
    yield put(actions.setLoading(false));
  }
}

const sagas = [
  takeLatest(types.GET_DISTRICT_AREAS, watchGetDistrictAreas),
];

export default sagas;
