export default {
  development: {
    API_BASE_URL: "http://localhost:3000", //api servier
    API_EXPEDITION: "http://localhost:3001", //server expedition
    SELLER_BASE_URL: "http://localhost:3008",
    BASE_URL: "http://localhost:3003", //host
    MOBILE_BASE_URL: "http://localhost:3006", //host
    CLIENT_ID_GOOGLE:
      "442505152144-8tmprpb40te588nikuv2atc4lvm95f22.apps.googleusercontent.com",
    API_KEY_GOOGLE_MAP: "AIzaSyCfQTgPWvPy7D8jaWrZwH704ja_ZfEySp0",
    APP_ID_FACEBOOK: "282430640258986",
    IMAGE_EMPTY: "https://image-plasgos.s3.amazonaws.com/no-image-thumb.png",
    IMAGE_ERROR:
      "https://image-plasgos.s3-ap-southeast-1.amazonaws.com/img-error.png",
  },
  production: {
    API_BASE_URL: "https://server-plasgos.herokuapp.com",
    API_EXPEDITION: "https://api-plasgos-expedisi.herokuapp.com",
    SELLER_BASE_URL: "https://seller.plasgos.co.id",
    BASE_URL: "https://www.plasgos.co.id",
    BASE_URL_MOBILE: "https://www.plasgos.co.id",
    CLIENT_ID_GOOGLE:
      "442505152144-8tmprpb40te588nikuv2atc4lvm95f22.apps.googleusercontent.com",
    API_KEY_GOOGLE_MAP: "AIzaSyCxuf4mY0RQpfLk2UVesq3rWO3ch9mz3xU",
    APP_ID_FACEBOOK: "126890704834924",
    IMAGE_EMPTY: "https://image-plasgos.s3.amazonaws.com/no-image-thumb.png",
    IMAGE_ERROR:
      "https://image-plasgos.s3-ap-southeast-1.amazonaws.com/img-error.png",
  },
};
