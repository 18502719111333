import { Api } from './api';

export default {
  get: payload => Api.get(`/v2/warehouses/seller`,{
    headers:{
      token: payload.token
    }
  }),
  add: payload => Api.post(`/v2/warehouses/seller/${payload.store_id}`,payload.data,{
    headers:{
      token: payload.token
    }
  }),
  update: payload => Api.put(`/v2/warehouses/seller/${payload.id}`,payload.data,{
    headers:{
      token: payload.token
    }
  }),
  delete: payload => Api.delete(`/v2/warehouses/seller/${payload.id}`,{
    headers:{
      token: payload.token
    }
  })
}
