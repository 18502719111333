import { Api } from './api';

export default {
  sessions: payload => Api.get('/v1/auth/',{
    headers: {
      token: payload.token
    }
  }),
  lastLogin: payload => Api.get(`/v1/log-history/last/${payload.id}`),
};
