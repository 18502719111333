import types from './constant';

const initialState = {
  count:0,
  data:[],
  isLoadingGet: false,
};

export default (state = initialState, action)=>{
  switch (action.type) {
    case types.GET_REVIEWS_SUCCESS:
      return {...state, data:action.payload}
    case types.SET_COUNT_GET_REVIEWS:
      return {...state, count:action.payload}
    case types.IS_LOADING_GET_REVIEWS:
      return {...state, isLoadingGet:action.payload}
    case types.RESET_REVIEW:
      return initialState
    default:
      return state;
  }
}

export const getReviews = payload => ({
  type: types.GET_REVIEWS,
  payload
})
export const getReviewsSuccess = payload => ({
  type: types.GET_REVIEWS_SUCCESS,
  payload
})
export const setCountGetReviews = payload => ({
  type: types.SET_COUNT_GET_REVIEWS,
  payload
})
export const setLoadingGetReviews = payload => ({
  type: types.IS_LOADING_GET_REVIEWS,
  payload
})

export const resetReviews = payload => ({
  type: types.GET_REVIEWS,
  payload
})
