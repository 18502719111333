export default {
  GET_RFQ : 'get-rfq',
  GET_RFQ_SUCCESS : 'get-rfq-success',

  GET_RFQ_DETAIL : 'get-rfq-detail-for-seller',
  GET_RFQ_DETAIL_SUCCESS : 'get-rfq-detail-success-for-seller',

  GET_MY_REQUEST : 'get-my-request',
  GET_MY_REQUEST_SUCCESS : 'get-my-request-success',

  GET_MY_OFFER : 'get-my-offer',
  GET_MY_OFFER_SUCCESS : 'get-my-offer-success',

  IS_LOADING_GET_RFQ : 'is-loading-get-rfq',
  SET_COUNT_RFQ : 'set-count-rfq',
  RESET_RFQ : 'reset-rfq',

  CREATE_OFFER_RFQ : 'create-offer-rfq',
  CREATE_OFFER_RFQ_SUCCESS : 'create-offer-rfq-success',
  IS_LOADING_CREATE_OFFER_RFQ : 'is-loading-create-offer-rfq',
  SET_CREATED_OFFER_RFQ : 'set-created-offer-rfq',

  UPDATE_OFFER_RFQ : 'update-offer-rfq',
  UPDATE_OFFER_RFQ_SUCCESS : 'update-offer-rfq-success',
  IS_LOADING_UPDATE_OFFER_RFQ : 'is-loading-update-offer-rfq',
  SET_UPDATED_OFFER_RFQ : 'set-updated-offer-rfq',

  DELETE_OFFER_RFQ : 'delete-offer-rfq',
  DELETE_OFFER_RFQ_SUCCESS : 'delete-offer-rfq-success',
  IS_LOADING_DELETE_OFFER_RFQ : 'is-loading-delete-offer-rfq',
  SET_DELETED_OFFER_RFQ : 'set-deleted-offer-rfq',

  CREATE_COMMENT_OFFER_RFQ : 'create-comment-offer-rfq',
  CREATE_COMMENT_OFFER_RFQ_SUCCESS : 'create-comment-offer-rfq-success',
  IS_LOADING_CREATE_COMMENT_OFFER_RFQ : 'is-loading-create-comment-offer-rfq',

}
