import {put, call, takeLatest, delay} from 'redux-saga/effects';
import Api from '../../services';
import types from './constant';
import * as actions from './reducer';

function* watchGetDecorationMobile(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetDecoration(true));
  try{
    const response = yield call(Api.decoration.getMobile, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDecorationMobileSuccess(data.data));
    }else{
      throw Error;
    }
  }catch(e){
    yield put(actions.setIsLoadingGetDecoration(false));
  }finally{
    yield put(actions.setIsLoadingGetDecoration(false));
  }
}
function* watchCreateDecorationMobile(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingCreateDecoration(true));
  try{
    const formData = new FormData();
    formData.append('type', payload.type);
    for (let i = 0; i < payload.banners.length; i++){
      formData.append(`${payload.banners[i].name}`, payload.banners[i].file);
      formData.append(`banners[${i}]`, JSON.stringify(payload.banners[i]));
    }
    for (let i = 0; i < payload.products.length; i++){
      formData.append(`products[${i}]`, JSON.stringify(payload.products[i]));
    }
    const response = yield call(Api.decoration.createMobile, {
      token: payload.token,
      data: formData
    });
    const { data } = response;
    if (data.success) {
      yield put(actions.getDecorationMobileSuccess(data.data));
      yield put(actions.createDecorationSuccess(data.data));
      yield put(actions.setDecorationSuccess(true))
    }else{
      throw Error;
    }
  }catch(e){
    yield put(actions.setIsLoadingCreateDecoration(false));
  }finally{
    yield put(actions.setIsLoadingCreateDecoration(false));
    yield delay(2000)
    yield put(actions.setDecorationSuccess(false))
  }
}

function* watchGetDecorationWeb(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetDecoration(true));
  try{
    const response = yield call(Api.decoration.getWeb, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDecorationWebSuccess(data.data));
    }else{
      throw Error;
    }
  }catch(e){
    yield put(actions.setIsLoadingGetDecoration(false));
  }finally{
    yield put(actions.setIsLoadingGetDecoration(false));
  }
}
function* watchCreateDecorationWeb(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingCreateDecoration(true));
  try{
    const formData = new FormData();
    formData.append('type', payload.type);
    for (let i = 0; i < payload.banners.length; i++){
      formData.append(`${payload.banners[i].name}`, payload.banners[i].file);
      formData.append(`banners[${i}]`, JSON.stringify(payload.banners[i]));
    }
    for (let i = 0; i < payload.products.length; i++){
      formData.append(`products[${i}]`, JSON.stringify(payload.products[i]));
    }
    const response = yield call(Api.decoration.createWeb, {
      token: payload.token,
      data: formData
    });
    const { data } = response;
    if (data.success) {
      yield put(actions.getDecorationWebSuccess(data.data));
      yield put(actions.createDecorationSuccess(data.data));
      yield put(actions.setDecorationSuccess(true))
    }else{
      throw Error;
    }
  }catch(e){
    yield put(actions.setIsLoadingCreateDecoration(false));
  }finally{
    yield put(actions.setIsLoadingCreateDecoration(false));
    yield delay(2000)
    yield put(actions.setDecorationSuccess(false))
  }
}



const decorationSagas = [
  takeLatest(types.GET_DECORATION_MOBILE, watchGetDecorationMobile),
  takeLatest(types.CREATE_DECORATION_MOBILE, watchCreateDecorationMobile),
  takeLatest(types.GET_DECORATION_WEB, watchGetDecorationWeb),
  takeLatest(types.CREATE_DECORATION_WEB, watchCreateDecorationWeb),
];

export default decorationSagas;
