import {put, call, takeLatest} from 'redux-saga/effects';
import Api from '../../services';
import types from './types';
import * as actions from './reducer';
import * as actionsPackage from '../package/reducer';

function* watchUploadProofPayment(values){
  const { payload } = values;
  yield put(actions.setLoadingUploadProofPayment(true))
  try{
    const response = yield call(Api.payment.upload.proof, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.uploadProofPaymentSuccess(data))
      yield put(actionsPackage.updateUploadedProofPaymentPackage(data))
    }
  }catch(e){
    yield put(actions.setLoadingUploadProofPayment(false))
  }finally{
    yield put(actions.setLoadingUploadProofPayment(false))
  }
}

const saga = [
  takeLatest(types.UPLOAD_PROOF_PAYMENT, watchUploadProofPayment)
]

export default saga;
