export default {
  GET_NOTIFICATION_DISCUSSIONS: "get-notification-discussions",
  GET_NOTIFICATION_DISCUSSIONS_SUCCESS: "get-notification-discussions-success",
  IS_LOADING_GET_NOTIFICATION_DISCUSSIONS:
    "is-loading-get-notification-discussions",
  RESET_GET_NOTIFICATION_DISCUSSIONS: "reset-get-notification-discussions",

  GET_PRODUCT_DISCUSSIONS: "get-product-discussions",
  GET_DISCUSSIONS: "get-my-discussions",
  GET_DISCUSSIONS_SUCCESS: "get-discussions-success",
  IS_LOADING_GET_DISCUSSIONS: "is-loading-get-discussions-success",
  RESET_GET_DISCUSSIONS: "reset-get-discussions-success",

  ADD_DISCUSSION: "add-discussion",
  ADD_DISCUSSION_SUCCESS: "add-discussion-success",
  IS_LOADING_ADD_DISCUSSION: "is-loading-add-discussion",
  RESET_ADD_DISCUSSION: "reset-add-discussion",
  SET_ADDED_DISCUSSION: "set-added-discussion",

  DELETE_DISCUSSION: "delete-discussion",
  DELETE_DISCUSSION_SUCCESS: "delete-discussion-success",
  IS_LOADING_DELETE_DISCUSSION: "is-loading-delete-discussion-success",
  RESET_DELETE_DISCUSSION: "reset-delete-discussion-success",
  SET_DELETED_DISCUSSION: "set-deleted-discussion",

  ADD_COMMENT_DISCUSSION: "add-comment-discussion",
  ADD_COMMENT_DISCUSSION_SUCCESS: "add-comment-discussion-success",
  IS_LOADING_ADD_COMMENT_DISCUSSION:
    "is-loading-add-comment-discussion-success",
  RESET_ADD_COMMENT_DISCUSSION: "reset-add-comment-discussion-success",

  DELETE_COMMENT_DISCUSSION: "delete-comment-discussion",
  DELETE_COMMENT_DISCUSSION_SUCCESS: "delete-comment-discussion-success",
  IS_LOADING_DELETE_COMMENT_DISCUSSION:
    "is-loading-delete-comment-discussion-success",
  RESET_DELETE_COMMENT_DISCUSSION: "reset-delete-comment-discussion-success",
  SET_ADDED_COMMENT_DISCUSSION: "set-added-comment-discussion",
  SET_DELETED_COMMENT_DISCUSSION: "set-deleted-comment-discussion",
};
