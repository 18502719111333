export default {
  GET_DOCUMENT: 'get-document',
  ADD_DOCUMENT: 'add-document',
  UPDATE_DOCUMENT: 'update-document',

  UPLOAD_KTP: 'upload-ktp-document',
  UPLOAD_NPWP: 'upload-npwp-document',
  UPLOAD_AKTA: 'upload-akta-document',
  UPLOAD_SIUP: 'upload-siup-document',
  UPLOAD_SKDP: 'upload-skdp-document',
  UPLOAD_TDP: 'upload-tdp-document',

  DOCUMENT_SUCCESS: 'document-success',
  IS_LOADING_DOCUMENT: 'is-loading-get-document',
  IS_LOADING_UPLOAD_DOCUMENT: 'is-loading-upload-document',

  SET_SUCCESS_DOCUMENT: 'set-success-document',
  SET_MESSAGE_DOCUMENT: 'set-message-document',
  RESET_DOCUMENT: 'reset-document'
};
