import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../services';
import types from './constant';
import * as actions from './reducer';

function* watchGetReviews(values) {
  yield put(actions.setLoadingGetReviews(true));
  const { payload } = values;
  try {
    const response = yield call(Api.review.get, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.setCountGetReviews(data.count));
      yield put(actions.getReviewsSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getReviewsSuccess([]));
  } finally {
    yield put(actions.setLoadingGetReviews(false));
  }
}

const sagas = [
  takeLatest(types.GET_REVIEWS, watchGetReviews),
];

export default sagas;
