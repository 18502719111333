import types from './constant';

const initialState = {
  data: {},
  loading: false,
  l_upload: false,
  success:false
};

export default (state = initialState, action) => {
  switch(action.type){
    case types.DOCUMENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    case types.IS_LOADING_DOCUMENT:
      return { ...state, loading: action.payload}
    case types.IS_LOADING_UPLOAD_DOCUMENT:
      return { ...state, l_upload: action.payload}
    case types.SET_SUCCESS_DOCUMENT:
      return { ...state, success: action.payload }
    case types.SET_MESSAGE_DOCUMENT:
      return { ...state, message: action.payload }
    default:
      return state
  }
}

export const getDocument = payload => ({
  type: types.GET_DOCUMENT,
  payload
})
export const addDocument = payload => ({
  type: types.ADD_DOCUMENT,
  payload
})
export const getDocumentSuccess = payload => ({
  type: types.DOCUMENT_SUCCESS,
  payload
})
export const setIsLoadingUpload = payload => ({
  type: types.IS_LOADING_UPLOAD_DOCUMENT,
  payload
})
export const setIsLoadingDocument = payload => ({
  type: types.IS_LOADING_DOCUMENT,
  payload
})
export const setSuccess = payload => ({
  type: types.SET_SUCCESS_DOCUMENT,
  payload
})
export const setMessage = payload => ({
  type: types.SET_MESSAGE_DOCUMENT,
  payload
})
export const uploadKTP = payload => ({
  type: types.UPLOAD_KTP,
  payload
})
export const uploadNPWP = payload => ({
  type: types.UPLOAD_NPWP,
  payload
})
export const uploadAKTA = payload => ({
  type: types.UPLOAD_AKTA,
  payload
})
export const uploadSIUP = payload => ({
  type: types.UPLOAD_SIUP,
  payload
})
export const uploadSKDP = payload => ({
  type: types.UPLOAD_SKDP,
  payload
})
export const uploadTDP = payload => ({
  type: types.UPLOAD_TDP,
  payload
})
