export default {
  GET_WAREHOUSES : 'get-warehouses',
  GET_WAREHOUSES_SUCCESS : 'get-warehouses-success',
  IS_LOADING_GET_WAREHOUSES : 'is-loading-get-warehouses',

  ADD_WAREHOUSE : 'add-warhouse',
  ADD_WAREHOUSE_SUCCESS : 'add-warhouse-success',
  IS_LOADING_ADD_WAREHOUSE : 'is-loading-add-warhouse',
  ADDED_WAREHOUSE : 'added-warehouse',

  UPDATE_WAREHOUSE : 'update-warhouse',
  UPDATE_WAREHOUSE_SUCCESS : 'update-warhouse-success',
  IS_LOADING_UPDATE_WAREHOUSE : 'is-loading-update-warhouse',
  UPDATED_WAREHOUSE : 'updated-warehouse',

  DELETE_WAREHOUSE : 'delete-warhouse',
  DELETE_WAREHOUSE_SUCCESS : 'delete-warhouse-success',
  IS_LOADING_DELETE_WAREHOUSE : 'is-loading-delete-warhouse',
  DELETED_WAREHOUSE : 'deleted-warehouse'
}
