import { Api } from "./api";

const params = (payload) => {
  return {
    filter: payload.filter, // cod,non-cod
    limit: payload.limit, // number
    page: payload.page, // number
    start: payload.start, // DD-MM-YYYY
    end: payload.end, // DD-MM-YYYY
    invoice: payload.invoice, //string
    sort: payload.sort, // latest, oldest
    shipping_number: payload.shipping_number, // string
    courier: payload.courier, // id service,
    payment_invoice: payload.payment_invoice,
    warehouse_id: payload.warehouse_id,
    search: payload.search,
  };
};
export default {
  get: {
    receivers: (payload) =>
      Api.get(`/v2/packages/receivers`, {
        headers: {
          token: payload.token,
        },
        params: params(payload),
      }),
    multiple: (payload) =>
      Api.post(
        `/v2/packages/multiple`,
        {
          ids: payload.ids,
        },
        {
          headers: {
            token: payload.token,
          },
        }
      ),
    notification: (payload) =>
      Api.get(`/v2/packages/notification`, {
        headers: {
          token: payload.token,
        },
      }),
    process: {
      new: (payload) =>
        Api.get(`/v2/packages/process/new`, {
          headers: {
            token: payload.token,
          },
          params: params(payload),
        }),
      payment: (payload) =>
        Api.get(`/v2/packages/process/payment`, {
          headers: {
            token: payload.token,
          },
          params: params(payload),
        }),
      receipt: (payload) =>
        Api.get(`/v2/packages/process/receipt`, {
          headers: {
            token: payload.token,
          },
          params: params(payload),
        }),
    },
    history: {
      waiting_pickup: (payload) =>
        Api.get(`/v2/packages/history/waiting`, {
          headers: {
            token: payload.token,
          },
          params: params(payload),
        }),
      shipped: (payload) =>
        Api.get(`/v2/packages/history/shipped`, {
          headers: {
            token: payload.token,
          },
          params: params(payload),
        }),
      arrived: (payload) =>
        Api.get(`/v2/packages/history/arrived`, {
          headers: {
            token: payload.token,
          },
          params: params(payload),
        }),
      problem: (payload) =>
        Api.get(`/v2/packages/history/problem`, {
          headers: {
            token: payload.token,
          },
          params: params(payload),
        }),
      done: (payload) =>
        Api.get(`/v2/packages/history/done`, {
          headers: {
            token: payload.token,
          },
          params: params(payload),
        }),
      return: (payload) =>
        Api.get(`/v2/packages/history/return`, {
          headers: {
            token: payload.token,
          },
          params: params(payload),
        }),
      return_finish: (payload) =>
        Api.get(`/v2/packages/history/return_finish`, {
          headers: {
            token: payload.token,
          },
          params: params(payload),
        }),
      canceled: (payload) =>
        Api.get(`/v2/packages/history/canceled`, {
          headers: {
            token: payload.token,
          },
          params: params(payload),
        }),
    },
    detail: {
      by_order_id: (payload) =>
        Api.get(`/v2/packages/detail/${payload.order_id}`, {
          headers: {
            token: payload.token,
          },
        }),
    },
  },
  create: {
    package: (payload) =>
      Api.post(`/v2/packages/create`, payload.data, {
        headers: {
          token: payload.token,
        },
      }),
    payment: (payload) =>
      Api.post(`/v2/packages/create/payment`, payload.data, {
        headers: {
          token: payload.token,
        },
      }),
    pickup: (payload) =>
      Api.post(`/v2/packages/request/pickup`, payload.data, {
        headers: {
          token: payload.token,
        },
      }),
  },
  update: {
    print: {
      receipt: (payload) =>
        Api.patch(
          `/v2/packages/printed/receipt`,
          {
            ids: payload.ids,
          },
          {
            headers: {
              token: payload.token,
            },
          }
        ),
    },
  },
  remove: {
    single: (payload) =>
      Api.patch(
        `/v2/packages/remove/single/${payload.id}`,
        {},
        {
          headers: {
            token: payload.token,
          },
        }
      ),
    multiple: (payload) =>
      Api.patch(
        `/v2/packages/remove/multiple`,
        {
          ids: payload.ids,
        },
        {
          headers: {
            token: payload.token,
          },
        }
      ),
  },
  void: (payload) =>
    Api.patch(
      `/v2/packages/void/${payload.id}`,
      {
        reason: payload.reason,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
};
