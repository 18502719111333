import { Api } from "./api";

export default {
  get: (payload) =>
    Api.get(`/v2/discussions/seller`, {
      headers: {
        token: payload.token,
      },
      params: {
        limit: payload.limit,
        page: payload.page,
      },
    }),
  addComment: (payload) =>
    Api.post(`/v2/discussions/seller/comment`, payload.data, {
      headers: {
        token: payload.token,
      },
    }),

  notification: (payload) =>
    Api.get(`/v2/discussions/seller/notification`, {
      headers: {
        token: payload.token,
      },
    }),
  product: (payload) =>
    Api.get(`/v2/discussions/product/${payload.id}`, {
      headers: {
        token: payload.token,
      },
      params: {
        page: payload.page,
        limit: payload.limit,
      },
    }),
  add: (payload) =>
    Api.post(`/v2/discussions/seller/add`, payload.data, {
      headers: {
        token: payload.token,
      },
    }),
  delete: (payload) =>
    Api.delete(`/v2/discussions/seller/${payload.id}`, {
      headers: {
        token: payload.token,
      },
    }),
  comment: {
    add: (payload) =>
      Api.post(`/v2/discussions/seller/comment`, payload.data, {
        headers: {
          token: payload.token,
        },
      }),
    delete: (payload) =>
      Api.delete(`/v2/discussions/seller/comment/${payload.id}`, {
        headers: {
          token: payload.token,
        },
      }),
  },
};
