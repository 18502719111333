export default {
  GET_LANDING_PAGE_PRODUCTS: "get-landing-page-products",
  GET_LANDING_PAGE_PRODUCTS_SUCCESS: "get-landing-page-products-success",
  SET_COUNT_GET_LANDING_PAGE_PRODUCTS: "set-count-get-landing-page-products",
  IS_LOADING_GET_LANDING_PAGE_PRODUCTS: "is-loading-get-landing-page-products",

  GET_LANDING_PAGE_PRODUCT: "get-detail-page-product",
  GET_LANDING_PAGE_PRODUCT_SUCCESS: "get-detail-page-product-success",
  IS_LOADING_GET_LANDING_PAGE_PRODUCT: "is-loading-get-detail-page-product",
  RESET_LANDING_PAGE_PRODUCT: "reset-detail-landing-page-product",

  CHECK_URL_LANDING_PAGE: "check-url-landing-page",
  CHECK_URL_LANDING_PAGE_SUCCESS: "check-url-landing-page-success",
  IS_LOADING_CHECK_URL_LANDING_PAGE: "is-loading-check-url-landing-page",

  ADD_LANDING_PAGE_PRODUCT: "add-landing-page-product",
  ADD_LANDING_PAGE_PRODUCT_SUCCESS: "add-landing-page-product-success",
  IS_LOADING_ADD_LANDING_PAGE_PRODUCT: "is-loading-add-landing-page-product",
  RESET_ADD_LANDING_PAGE_PRODUCT: "reset-add-landing-page-product",

  UPDATE_LANDING_PAGE_PRODUCT: "update-landing-page-product",
  UPDATE_LANDING_PAGE_PRODUCT_SUCCESS: "update-landing-page-product-success",
  IS_LOADING_UPDATE_LANDING_PAGE_PRODUCT:
    "is-loading-update-landing-page-product",
  RESET_UPDATE_LANDING_PAGE_PRODUCT: "reset-update-landing-page-product",

  DELETE_LANDING_PAGE_PRODUCT: "delete-landing-page-product",
  DELETE_LANDING_PAGE_PRODUCT_SUCCESS: "delete-landing-page-product-success",
  IS_LOADING_DELETE_LANDING_PAGE_PRODUCT:
    "is-loading-delete-landing-page-product",

  GET_PHOTO_GALLERY: "get-photo-gallery",
  GET_PHOTO_GALLERY_SUCCESS: "get-photo-gallery-success",
  IS_LOADING_GET_PHOTO_GALLERY: "is-loading-get-photo-gallery",
  SET_COUNT_PHOTO_GALLERY: "set-count-photo-gallery",

  UPLOAD_PHOTO_GALLERY: "upload-photo-gallery",
  UPLOAD_PHOTO_GALLERY_SUCCESS: "upload-photo-gallery-success",
  IS_LOADING_UPLOAD_PHOTO_GALLERY: "is-loading-upload-photo-gallery",

  DELETE_PHOTO_GALLERY: "delete-photo-gallery",
  DELETE_PHOTO_GALLERY_SUCCESS: "delete-photo-gallery-success",
  IS_LOADING_DELETE_PHOTO_GALLERY: "is-loading-delete-photo-gallery",

  //LP Cntents
  SET_LANDING_PAGE_SECTION: "set-landing-page-section",
  SET_OPTIONS_SCROLL_TARGET: "set-options-scroll-target",
  SET_FROM_CONTENTS_OPTIONS_SCROLL_TARGET:
    "set-from-contents-options-scroll-target",
  REMOVE_OPTIONS_SCROLL_TARGET: "remove-options-scroll-target",
  RESET_OPTIONS_SCROLL_TARGET: "reset-options-scroll-target",

  IS_SELECT_VARIANT_MULTISELECT: "is-select-variant-multiselect",
  IS_SELECT_VARIANT_SELECT_OPTION: "is-select-variant-select-option",
  CURRRENT_VARIANT_MULTISELECT: "current-variant-multiselect",

  SET_SELECTED_VARIANT: "selected-variant",
  ADD_OPTIONS_GROUPS: "add-options-group",
  UPDATE_OPTIONS_GROUPS: "update-options-group",
  DELETE_OPTIONS_GROUPS: "delete-options-group",
  SORT_GROUPS: "sort-group",
  ADD_OPTIONS_OPSI_GROUPS: "add-options-opsi-group",
  UPDATE_OPTIONS_OPSI_GROUPS: "update-options-opsi-group",
  DELETE_OPTIONS_OPSI_GROUPS: "delete-options-opsi-group",

  ADD_OPTION: "add-option",
  UPDATE_OPTION: "update-option",
  DELETE_OPTION: "delete-option",
  SORT_OPTION: "sort-option",

  SET_ADD_COURIERS: "set-add-couriers",
  SET_EDIT_COURIERS: "set-edit-couriers",

  SET_SELECT_COURIER: "set-selct-courier",
  SET_CURRENT_COURIER_BEFORE_EDIT: "set-current-courier-before-edit",

  SET_IS_ADD_COLUMN: "set-is-add-column",
  SET_IS_EDITING_COLUMN: "set-is-editing-column",
  SET_IS_ADD_COLUMN_SECTION: "set-is-add-column-section",
  SET_IS_EDITING_COLUMN_SECTION: "set-is-editing-column-section",
  SET_IS_EDITING_SECTION: "set-is-editing-section",

  SET_POP_UP: "set-pop-up",
  SET_POP_UP_CLICK_OPTION: "set-pop-up-click-option",
  SET_IS_OPEN_POP_UP: "set-is-open-pop-up",
  SET_CLOSE_POP_UP: "set-close-pop-up",
  REMOVE_POP_UP_OPTION: "remove-pop-up-option",
  REMOVE_POP_UP_OPTION_SHOWN: "remove-pop-up-option-shown",
};
