import { Api } from './api';

export default {
  get: payload => Api.get(`/v2/reviews/seller`,{
    headers: {
      token: payload.token
    },
    params: {
      limit: payload.limit ? payload.limit : null,
      page: payload.page ? payload.page : null,
    }
  })
};
