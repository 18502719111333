export default {
  //fb_pixel
  GET_FB_PIXEL : 'GET_FB_PIXEL',
  GET_FB_PIXEL_SUCCESS : 'GET_FB_PIXEL_SUCCESS',
  IS_LOADING_GET_FB_PIXEL : 'IS_LOADING_GET_FB_PIXEL',

  ADD_FB_PIXEL : 'ADD_FB_PIXEL',
  ADD_FB_PIXEL_SUCCESS : 'ADD_FB_PIXEL_SUCCESS',
  IS_LOADING_ADD_FB_PIXEL : 'IS_LOADING_ADD_FB_PIXEL',

  UPDATE_FB_PIXEL : 'UPDATE_FB_PIXEL',
  UPDATE_STORE_FB_PIXEL : 'UPDATE_STORE_FB_PIXEL',
  UPDATE_PRODUCT_FB_PIXEL : 'UPDATE_PRODUCT_FB_PIXEL',
  UPDATE_FB_PIXEL_SUCCESS : 'UPDATE_FB_PIXEL_SUCCESS',
  IS_LOADING_UPDATE_FB_PIXEL : 'IS_LOADING_UPDATE_FB_PIXEL',

  DELETE_FB_PIXEL : 'DELETE_FB_PIXEL',
  DELETE_FB_PIXEL_SUCCESS : 'DELETE_FB_PIXEL_SUCCESS',
  IS_LOADING_DELETE_FB_PIXEL : 'IS_LOADING_DELETE_FB_PIXEL',

  SET_ADDED_FB_PIXEL : 'SET_ADDED_FB_PIXEL',
  SET_UPDATED_FB_PIXEL : 'SET_UPDATED_FB_PIXEL',
  SET_DELETED_FB_PIXEL : 'SET_DELETED_FB_PIXEL',
}
