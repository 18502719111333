import { Api } from "./api";

export default {
  //GET orders
  all: (payload) =>
    Api.get("/v2/orders/seller/all", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  new: (payload) =>
    Api.get("/v2/orders/seller/new", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  cancelled: (payload) =>
    Api.get("/v2/orders/seller/aborted", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  processed: (payload) =>
    Api.get("/v2/orders/seller/processed", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  pickup: (payload) =>
    Api.get("/v2/orders/seller/pickup", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  shipped: (payload) =>
    Api.get("/v2/orders/seller/shipped", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  arrived: (payload) =>
    Api.get("/v2/orders/seller/arrived", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  done: (payload) =>
    Api.get("/v2/orders/seller/done", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  complaint: (payload) =>
    Api.get("/v2/orders/seller/complaint", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  getOne: (payload) =>
    Api.get(`/v2/orders/seller/${payload.id}`, {
      headers: { token: payload.token },
    }),
  //UPDATE orders

  addPackage: (payload) =>
    Api.put(
      `/v2/orders/seller/update/pickup/single/${payload.id}`,
      payload.data,
      {
        headers: { token: payload.token },
      }
    ),
  updateAcceptOrder: (payload) =>
    Api.put(
      `/v2/orders/seller/update/accept/${payload.id}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),
  updateCancelOrders: (payload) =>
    Api.put(
      `/v2/orders/seller/update/reject/${payload.id}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),
  updateShippedOrders: (payload) =>
    Api.put(
      `/v2/orders/seller/update/confirm-shipping/${payload.id}`,
      {
        tracking_number: payload.tracking_number,
      },
      {
        headers: { token: payload.token },
      }
    ),
  updateToShipped: (payload) =>
    Api.put(
      `/v2/orders/seller/update/shipped/${payload.id}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),
  updateToArrived: (payload) =>
    Api.put(
      `/v2/orders/seller/update/delivered/${payload.id}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),
  updateMultipleAcceptOrder: (payload) =>
    Api.put(
      `/v2/orders/seller/update/multiple/accept`,
      {
        ids: payload.ids,
      },
      {
        headers: { token: payload.token },
      }
    ),
  updateIsPrintedOrder: (payload) =>
    Api.put(
      `/v2/orders/seller/label-printed`,
      {
        ids: payload.ids,
      },
      {
        headers: { token: payload.token },
      }
    ),
  updateReceiptPrinted: (payload) =>
    Api.put(
      `/v2/orders/seller/receipt-printed`,
      {
        ids: payload.ids,
      },
      {
        headers: { token: payload.token },
      }
    ),
};
