import { put, call, takeLatest, select, delay } from "redux-saga/effects";
import Api from "../../services";
import types from "./types";
import * as actions from "./reducer";

function* watchGetChats(values) {
  yield put(actions.setIsLoadingGetChats(true));
  const { payload } = values;
  try {
    const response = yield call(Api.chat.get.chats, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getChatsSuccess(data));
    }
  } catch (error) {
    // yield put(actions.getChatsSuccess());
  } finally {
    yield put(actions.setIsLoadingGetChats(false));
  }
}
function* watchGetMessages(values) {
  const { payload } = values;
  yield put(actions.setIsLoadingGetMessages(true));
  try {
    const response = yield call(Api.chat.get.message, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getMessagesSuccess(data));
    }
  } catch (error) {
    yield put(actions.setIsLoadingGetMessages(false));
  } finally {
    yield put(actions.setIsLoadingGetMessages(false));
  }
}
function* watchSendMessage(values) {
  const { payload } = values;
  const state = yield select();
  yield put(actions.setIsLoadingSendMessage(true));
  try {
    let dataValue = {
      ...payload.data,
      store_id: payload.store_id,
      user_id: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      product_id: payload.data.product_id,
      order_id: payload.data.order_id,
      Product: payload.data.product,
      Order: payload.data.order,
      deleted_by_user: false,
      deleted_by_store: false,
    };
    yield put(actions.sendMessageSuccess(dataValue));
    yield put(actions.setSendingChat(true));
    yield put(actions.setOrder({}));
    yield put(actions.setProduct({}));
    yield delay(2000);
    const response = yield call(Api.chat.send, payload);
    const { data } = response;
    if (data.success) {
      const messages = state.chat.messages.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      const seed = {
        ...data.data,
        Product: payload.data.product,
        Order: payload.data.order,
      };
      yield put(actions.getMessagesSuccess([seed, ...messages.pop()]));
      yield put(actions.setSendingChat(false));
    }
  } catch (error) {
    yield put(actions.setIsLoadingSendMessage(false));
  } finally {
    yield put(actions.setIsLoadingSendMessage(false));
  }
}
function* watchGetNotificationChats(values) {
  yield put(actions.setIsLoadingGetNotificationChats(true));
  const { payload } = values;
  try {
    const response = yield call(Api.chat.get.notification, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getNotificationChatsSuccess(data.data));
    }
  } catch (error) {
    // yield put(actions.getChatsSuccess());
  } finally {
    yield put(actions.setIsLoadingGetNotificationChats(false));
  }
}
const saga = [
  takeLatest(types.GET_CHATS, watchGetChats),
  takeLatest(types.GET_MESSAGES_BY_BUYER, watchGetMessages),
  takeLatest(types.SEND_MESSAGE, watchSendMessage),
  takeLatest(types.GET_NOTIFICATION_CHATS, watchGetNotificationChats),
];

export default saga;
