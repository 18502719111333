import { Api } from './api';

export default {
  get: payload => Api.get('/v2/products/seller',{
    headers: {
      token: payload.token
    },
    params: {
      limit: payload.limit ? payload.limit : null,
      page: payload.page ? payload.page : null,
      categories: payload.categories ? payload.categories : null,
      showcases: payload.showcases ? payload.showcases : null,
      search: payload.search ? payload.search : null,
      sort: payload.sort ? payload.sort : null,
      filter: payload.filter ? payload.filter : null,
      status: payload.status ? payload.status : null,
    }
  }),
  getNotification: payload => Api.get('/v2/products/seller/notification',{
    headers: {
      token: payload.token
    }
  }),
  getOne: payload => Api.get(`/v2/products/seller/detail/${payload.id}`,{
    headers: {
      token: payload.token
    }
  }),
  add: payload => Api.post(`/v2/products/seller`, payload.data, {
    headers: {
      token: payload.token
    }
  }),
  update: payload => Api.put(`/v2/products/seller/update/${payload.id}`, payload.data, {
    headers: {
      token: payload.token
    }
  }),
  updateMultipleStatus: payload => Api.put(`/v2/products/seller/update/status/multiple-id`, payload.data, {
    headers: {
      token: payload.token
    }
  }),
  addImages: payload => Api.post(`/v2/products/seller/images/${payload.id}`, payload.data, {
    headers: {
      token: payload.token,
      'Content-Type': 'multipart/form-data'
    }
  }),
  getShowcases: payload => Api.get(`/v2/showcases/seller`,{
    headers: {
      token: payload.token
    }
  }),
  addShowcase: payload => Api.post(`/v2/showcases/seller/add`,{name:payload.name},{
    headers: {
      token: payload.token
    }
  }),
  getCategories: payload => Api.get(`/v1/categories`),
};
