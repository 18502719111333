import { Api } from "./api";

export default {
  getProducts: (payload) =>
    Api.get("/v2/landing-pages/product", {
      headers: {
        token: payload.token,
      },
    }),
  getProduct: (payload) =>
    Api.get(`/v2/landing-pages/product/id/${payload.id}`, {
      headers: {
        token: payload.token,
      },
    }),
  getPhotoGallery: (payload) =>
    Api.get(`/v2/stores/seller/photo-gallery`, {
      headers: {
        token: payload.token,
      },
      params: {
        limit: payload.limit ? payload.limit : null,
        page: payload.page ? payload.page : null,
      },
    }),
  checkUrl: (payload) =>
    Api.post(
      `/v2/landing-pages/product/check-url`,
      {
        url: payload.url,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
  addProduct: (payload) =>
    Api.post("/v2/landing-pages/product", payload.data, {
      headers: {
        token: payload.token,
      },
    }),
  updateProduct: (payload) =>
    Api.put(`/v2/landing-pages/product/${payload.id}`, payload.data, {
      headers: {
        token: payload.token,
      },
    }),
  deleteProduct: (payload) =>
    Api.delete(`/v2/landing-pages/product/${payload.id}`, {
      headers: {
        token: payload.token,
      },
    }),
  uploadPhotoGallery: async (payload) => {
    try {
      const formData = new FormData();
      await payload.data.files.map((file, i) =>
        formData.append(`photo_gallery_${i}`, file)
      );
      return Api.post(`/v2/stores/seller/photo-gallery/upload`, formData, {
        headers: {
          token: payload.token,
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (e) {}
  },
  deletePhotoGallery: (payload) =>
    Api.delete(`/v2/stores/seller/photo-gallery/${payload.id}`, {
      headers: {
        token: payload.token,
      },
    }),
};
