import { Api } from './api';

export default {
  getInfo: payload => Api.get('/v2/stores/seller/info',{
    headers: {
      token: payload.token
    }
  }),
  updateInfo: payload => Api.put('/v2/stores/seller/info', payload.data,{
    headers: {
      token: payload.token
    }
  }),
  uploadAvatar: payload => Api.post(`/v2/stores/seller/upload-avatar`, payload.data, {
    headers: {
      token: payload.token,
      'Content-Type': 'multipart/form-data'
    }
  }),
  infoStore: payload => Api.get('/v1/stores/user',{
    headers: {
      token: payload.token
    }
  }),
  update: payload => Api.put(`/v1/stores/${payload.id}`, payload.data, {
    headers: {
      token: payload.token
    }
  }),
};
