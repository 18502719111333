export default {
  GET_SHOWCASES: 'get-showcases-page',
  GET_SHOWCASES_SUCCESS: 'get-showcases-page-success',
  SET_COUNT_GET_SHOWCASES: 'set-count-get-showcases-page',
  IS_LOADING_GET_SHOWCASES: 'is-loading-get-showcases-page',

  ADD_SHOWCASE: 'add-showcase-page',
  ADD_SHOWCASE_SUCCESS: 'add-showcase-page-success',
  IS_LOADING_ADD_SHOWCASE: 'is-loading-add-showcase-page',

  UPDATE_SHOWCASE: 'update-showcase-page',
  UPDATE_SHOWCASE_SUCCESS: 'update-showcase-page-success',
  IS_LOADING_UPDATE_SHOWCASE: 'is-loading-update-showcase-page',

  DELETE_SHOWCASE: 'delete-showcase-page',
  DELETE_SHOWCASE_SUCCESS: 'delete-showcase-page-success',
  IS_LOADING_DELETE_SHOWCASE: 'is-loading-delete-showcase-page',

  SET_SUCCESS_SHOWCASE: 'set-success-showcase',
  SET_MESSAGE_SHOWCASE: 'set-message-showcase',
  RESET_SHOWCASE: 'reset-showcase'
};
