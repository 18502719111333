import { put, call, takeLatest, delay } from "redux-saga/effects";
import Api from "../../services";
import types from "./types";
import * as actions from "./reducer";
import { notification } from "../../helpers";

function* watchCreatePackage(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingCreatePackage(true));
  try {
    const response = yield call(Api.packages.create.package, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.createPackageSuccess(data));
      if (payload.added) {
        yield put(actions.setAddedCreate(true));
      }
    }
  } catch (e) {
    if (e.response && e.response.data && e.response.data.message) {
      yield notification.error(e.response.data.message);
    }
    yield put(actions.setIsLoadingCreatePackage(false));
  } finally {
    yield delay(1000);
    yield put(actions.setAddedCreate(false));
    yield put(actions.createPackageSuccess({ success: false }));
    yield put(actions.setIsLoadingCreatePackage(false));
  }
}

function* watchGetMultipleIds(value) {
  const { payload } = value;
  yield put(actions.setIsLoadinggetMultiplePackages(true));
  try {
    const response = yield call(Api.packages.get.multiple, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getMultiplePackagesSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadinggetMultiplePackages(false));
  } finally {
    yield put(actions.setIsLoadinggetMultiplePackages(false));
  }
}
function* watchGetNotification(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetNotification(true));
  try {
    const response = yield call(Api.packages.get.notification, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getNotificationSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetNotification(false));
  } finally {
    yield put(actions.setIsLoadingGetNotification(false));
  }
}
function* watchGetProcessNew(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetProcess(true));
  try {
    const response = yield call(Api.packages.get.process.new, payload);
    const { data } = response;
    if (data.success) {
      yield put(
        actions.getProcessSuccess({
          notification: data.notification,
          data: data.data,
        })
      );
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetProcess(false));
  } finally {
    yield delay(1000);
    yield put(actions.setIsLoadingGetProcess(false));
  }
}
function* watchGetProcessPayment(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetProcess(true));
  try {
    const response = yield call(Api.packages.get.process.payment, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getProcessSuccess({ payments: data.data }));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetProcess(false));
  } finally {
    yield delay(1000);
    yield put(actions.setIsLoadingGetProcess(false));
  }
}
function* watchGetProcessReceipt(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetProcess(true));
  try {
    const response = yield call(Api.packages.get.process.receipt, payload);
    const { data } = response;
    if (data.success) {
      yield put(
        actions.getProcessSuccess({
          notification: data.notification,
          receipts: data.data,
        })
      );
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetProcess(false));
  } finally {
    yield delay(1000);
    yield put(actions.setIsLoadingGetProcess(false));
  }
}

function* watchGetHistoryWaitingPickup(value) {
  yield put(actions.setIsLoadingGetHistorySuccess(true));
  const { payload } = value;
  try {
    const response = yield call(
      Api.packages.get.history.waiting_pickup,
      payload
    );
    const { data } = response;
    if (data.success) {
      yield delay(1000);
      yield put(actions.getHistorySuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  } finally {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  }
}
function* watchGetHistoryShipped(value) {
  yield put(actions.setIsLoadingGetHistorySuccess(true));
  const { payload } = value;
  try {
    const response = yield call(Api.packages.get.history.shipped, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getHistorySuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  } finally {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  }
}
function* watchGetHistoryShipmentProblem(value) {
  yield put(actions.setIsLoadingGetHistorySuccess(true));
  const { payload } = value;
  try {
    const response = yield call(Api.packages.get.history.problem, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getHistorySuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  } finally {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  }
}
function* watchGetHistoryArrived(value) {
  yield put(actions.setIsLoadingGetHistorySuccess(true));
  const { payload } = value;
  try {
    const response = yield call(Api.packages.get.history.arrived, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getHistorySuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  } finally {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  }
}
function* watchGetHistoryDone(value) {
  yield put(actions.setIsLoadingGetHistorySuccess(true));
  const { payload } = value;
  try {
    const response = yield call(Api.packages.get.history.done, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getHistorySuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  } finally {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  }
}
function* watchGetHistoryReturn(value) {
  yield put(actions.setIsLoadingGetHistorySuccess(true));
  const { payload } = value;
  try {
    const response = yield call(Api.packages.get.history.return, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getHistorySuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  } finally {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  }
}
function* watchGetHistoryReturnFinish(value) {
  yield put(actions.setIsLoadingGetHistorySuccess(true));
  const { payload } = value;
  try {
    const response = yield call(
      Api.packages.get.history.return_finish,
      payload
    );
    const { data } = response;
    if (data.success) {
      yield put(actions.getHistorySuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  } finally {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  }
}
function* watchGetHistoryCanceled(value) {
  yield put(actions.setIsLoadingGetHistorySuccess(true));
  const { payload } = value;
  try {
    const response = yield call(Api.packages.get.history.canceled, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getHistorySuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  } finally {
    yield put(actions.setIsLoadingGetHistorySuccess(false));
  }
}

function* watchRequestPickupCod(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingRequestPickup(true));
  try {
    const response = yield call(Api.packages.create.pickup, payload);
    const { data } = response;
    if (data.success) {
      yield delay(1000);
      yield put(actions.requestPickupSuccess({ ...data, type: payload.type }));
    }
  } catch (e) {
    if (e.response && e.response.data && e.response.data.message) {
      yield notification.error(e.response.data.message);
    }
    yield put(actions.setIsLoadingRequestPickup(false));
  } finally {
    yield put(actions.setIsLoadingRequestPickup(false));
  }
}
function* watchRequestPickupNonCod(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingRequestPickup(true));
  try {
    const response = yield call(Api.packages.create.payment, payload);
    const { data } = response;
    if (data.success) {
      yield delay(1000);
      yield put(actions.requestPickupSuccess({ ...data, type: payload.type }));
    }
  } catch (e) {
    if (e.response && e.response.data && e.response.data.message) {
      yield notification.error(e.response.data.message);
    }
    yield put(actions.setIsLoadingRequestPickup(false));
  } finally {
    yield put(actions.setIsLoadingRequestPickup(false));
  }
}
function* watchUpdateReceiptPrinted(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingUpdatePackage(true));
  try {
    const response = yield call(Api.packages.update.print.receipt, payload);
    const { data } = response;
    if (data.success) {
      yield delay(1000);
      yield put(
        actions.updatePackageSuccess({
          ids: data.ids,
          type: payload.type,
          success: data.success,
        })
      );
    }
  } catch (e) {
    yield put(actions.setIsLoadingUpdatePackage(false));
  } finally {
    yield put(actions.setIsLoadingUpdatePackage(false));
  }
}

function* watchRemoveSingle(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingRemovePackage(true));
  try {
    const response = yield call(Api.packages.remove.single, payload);
    const { data } = response;
    if (data.success) {
      yield delay(1000);
      yield put(
        actions.removePackageSuccess({
          ids: data.ids,
          type: payload.type,
          success: data.success,
        })
      );
    }
  } catch (e) {
    yield put(actions.setIsLoadingRemovePackage(false));
  } finally {
    yield put(actions.setIsLoadingRemovePackage(false));
  }
}
function* watchRemoveMultiple(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingRemovePackage(true));
  try {
    const response = yield call(Api.packages.remove.multiple, payload);
    const { data } = response;
    if (data.success) {
      yield delay(1000);
      yield put(
        actions.removePackageSuccess({
          ids: data.ids,
          type: payload.type,
          success: data.success,
        })
      );
    }
  } catch (e) {
    yield put(actions.setIsLoadingRemovePackage(false));
  } finally {
    yield put(actions.setIsLoadingRemovePackage(false));
  }
}

function* watchVoidPackage(value) {
  const { payload } = value;
  yield put(actions.setIsLoadinggetVoidPackage(true));
  try {
    const response = yield call(Api.packages.void, payload);
    const { data } = response;
    if (data.success) {
      yield delay(1000);
      yield put(
        actions.voidPackageSuccess({
          id: data.id,
          success: data.success,
        })
      );
    }
  } catch (e) {
    if (e.response && e.response.data && e.response.data.message) {
      yield notification.error(e.response.data.message);
    }
    yield put(actions.setIsLoadinggetVoidPackage(false));
  } finally {
    yield put(actions.setIsLoadinggetVoidPackage(false));
  }
}

function* watchGetReceivers(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetReceivers(true));
  try {
    const response = yield call(Api.packages.get.receivers, payload);
    const { data } = response;
    if (data.success) {
      yield put(
        actions.getReceiversSuccess({
          count: data.count,
          data: data.data,
        })
      );
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetReceivers(false));
  } finally {
    yield delay(1000);
    yield put(actions.setIsLoadingGetReceivers(false));
  }
}

function* watchSetCustom() {
  yield put(actions.setCustomSuccess({ success: false }));
  yield put(actions.setCustomSuccess({ loading: true }));
  yield delay(1000);
  yield put(actions.setCustomSuccess({ loading: false }));
  yield put(actions.setCustomSuccess({ success: true }));
}

function* watchGetDetail(value) {
  yield put(actions.resetDetail());
  const { payload } = value;
  try {
    yield put(actions.setIsLoadingGetDetailSuccess(true));
    const response = yield call(Api.packages.get.detail.by_order_id, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDetailSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetDetailSuccess(false));
  } finally {
    yield delay(1500);
    yield put(actions.setIsLoadingGetDetailSuccess(false));
  }
}
const sagas = [
  takeLatest(types.CREATE_PACKAGE, watchCreatePackage),
  takeLatest(types.GET_NOTIFICATION, watchGetNotification),
  takeLatest(types.GET_PROCESS_NEW, watchGetProcessNew),
  takeLatest(types.GET_PROCESS_RECEIPT, watchGetProcessReceipt),
  takeLatest(types.GET_PROCESS_PAYMENT, watchGetProcessPayment),
  takeLatest(types.GET_MULTIPLE_IDS, watchGetMultipleIds),
  takeLatest(types.GET_HISTORY_WAITING_PICKUP, watchGetHistoryWaitingPickup),
  takeLatest(types.GET_HISTORY_SHIPPED, watchGetHistoryShipped),
  takeLatest(
    types.GET_HISTORY_SHIPMENT_PROBLEM,
    watchGetHistoryShipmentProblem
  ),
  takeLatest(types.GET_HISTORY_ARRIVED, watchGetHistoryArrived),
  takeLatest(types.GET_HISTORY_DONE, watchGetHistoryDone),
  takeLatest(types.GET_HISTORY_RETURN, watchGetHistoryReturn),
  takeLatest(types.GET_HISTORY_RETURN_FINISH, watchGetHistoryReturnFinish),
  takeLatest(types.GET_HISTORY_CANCELED, watchGetHistoryCanceled),

  takeLatest(types.GET_DETAIL_PACKAGE, watchGetDetail),

  takeLatest(types.CREATE_REQUEST_PICKUP_COD, watchRequestPickupCod),
  takeLatest(types.CREATE_REQUEST_PICKUP_NONCOD, watchRequestPickupNonCod),
  takeLatest(
    types.UPDATE_RECEIPT_PRINTED_MULTIPLE_PACKAGE,
    watchUpdateReceiptPrinted
  ),
  takeLatest(types.REMOVE_SINGLE_PACKAGE, watchRemoveSingle),
  takeLatest(types.REMOVE_MULTIPLE_PACKAGE, watchRemoveMultiple),
  takeLatest(types.VOID_PACKAGE, watchVoidPackage),
  takeLatest(types.GET_RECEIVERS_PACKAGE, watchGetReceivers),
  takeLatest(types.SET_CUSTOM, watchSetCustom),
];

export default sagas;
