import { put, call, takeLatest, delay } from "redux-saga/effects";
import Api from "../../services";
import types from "./types";
import * as actions from "./reducer";

function* watchGetLandingPageProducts(values) {
  yield put(actions.setIsLoadingGetLandingPageProducts(true));
  const { payload } = values;
  try {
    const response = yield call(Api.landingpage.getProducts, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.setCountGetLandingPageProducts(data.count));
      yield put(actions.getLandingPageProductsSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getLandingPageProductsSuccess([]));
  } finally {
    yield put(actions.setIsLoadingGetLandingPageProducts(false));
  }
}
function* watchAddLandingPageProducts(values) {
  yield put(actions.setIsLoadingAddLandingPageProduct(true));
  const { payload } = values;
  try {
    const response = yield call(Api.landingpage.addProduct, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        ...data.data,
        meta: null,
        success: data.success,
      };
      yield put(actions.addLandingPageProductSuccess(result));
    }
  } catch (error) {
    yield put(actions.resetAddLandingPageProduct());
    yield put(actions.setIsLoadingAddLandingPageProduct(false));
  } finally {
    yield delay(100);
    yield put(actions.setIsLoadingAddLandingPageProduct(false));
  }
}
function* watchUpdateLandingPageProducts(values) {
  yield put(actions.setIsLoadingUpdateLandingPageProduct(true));
  const { payload } = values;
  try {
    const response = yield call(Api.landingpage.updateProduct, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        ...payload.data,
        meta: null,
        success: data.success,
      };
      yield put(actions.updateLandingPageProductSuccess(result));
    }
  } catch (error) {
    yield put(actions.setIsLoadingUpdateLandingPageProduct(false));
  } finally {
    yield delay(500);
    yield put(actions.setIsLoadingUpdateLandingPageProduct(false));
    // yield put(actions.resetUpdateLandingPageProduct());
  }
}

function* watchDeleteLandingPageProducts(values) {
  yield put(actions.setIsLoadingDeleteLandingPageProduct(true));
  const { payload } = values;
  try {
    const response = yield call(Api.landingpage.deleteProduct, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.deleteLandingPageProductSuccess(payload.id));
    }
  } catch (error) {
    yield put(actions.setIsLoadingDeleteLandingPageProduct(false));
  } finally {
    yield put(actions.setIsLoadingDeleteLandingPageProduct(false));
  }
}
function* watchGetDetailLandingPageProduct(values) {
  yield put(actions.setIsLoadingLandingPageProduct(true));
  const { payload } = values;
  try {
    const response = yield call(Api.landingpage.getProduct, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDetailLandingPageProductSuccess(data.data));
    }
  } catch (error) {
    // yield put(actions.getDetailLandingPageProductSuccess({}));
  } finally {
    yield delay(500);
    yield put(actions.setIsLoadingLandingPageProduct(false));
  }
}

function* watchGetPhotoGallery(values) {
  yield put(actions.setIsLoadingGetPhotoGallery(true));
  const { payload } = values;
  try {
    const response = yield call(Api.landingpage.getPhotoGallery, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getPhotoGallerySuccess(data.data));
      yield put(actions.setCountPhotoGallery(data.count));
    }
  } catch (error) {
    yield put(actions.getPhotoGallerySuccess([]));
  } finally {
    yield put(actions.setIsLoadingGetPhotoGallery(false));
  }
}
function* watchUploadPhotoGallery(values) {
  yield put(actions.setIsLoadingUploadPhotoGallery(true));
  const { payload } = values;
  try {
    const response = yield call(Api.landingpage.uploadPhotoGallery, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.uploadPhotoGallerySuccess(data.data));
    }
  } catch (error) {
    yield put(actions.uploadPhotoGallerySuccess({}));
  } finally {
    yield put(actions.setIsLoadingUploadPhotoGallery(false));
  }
}
function* watchDeletePhotoGallery(values) {
  yield put(actions.setIsLoadingDeletePhotoGallery(true));
  const { payload } = values;
  try {
    const response = yield call(Api.landingpage.deletePhotoGallery, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.deletePhotoGallerySuccess(payload.id));
    }
  } catch (error) {
    // yield put(actions.deletePhotoGallerySuccess({}));
  } finally {
    yield put(actions.setIsLoadingDeletePhotoGallery(false));
  }
}

function* watchCheckUrlLandingPage(values) {
  yield put(actions.setIsLoadingCheckUrlLandingPage(true));
  const { payload } = values;
  try {
    const response = yield call(Api.landingpage.checkUrl, payload);
    yield delay(3000);
    const { data } = response;
    if (data.success) {
      const result = {
        ...data,
        status: response.status,
      };
      yield put(actions.checkUrlLandingPageSuccess(result));
    }
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      const result = {
        ...data,
        status: error.response.status,
      };
      yield put(actions.checkUrlLandingPageSuccess(result));
    }
  } finally {
    yield put(actions.setIsLoadingCheckUrlLandingPage(false));
  }
}
const sagas = [
  takeLatest(types.GET_LANDING_PAGE_PRODUCTS, watchGetLandingPageProducts),
  takeLatest(types.ADD_LANDING_PAGE_PRODUCT, watchAddLandingPageProducts),
  takeLatest(types.DELETE_LANDING_PAGE_PRODUCT, watchDeleteLandingPageProducts),
  takeLatest(types.GET_LANDING_PAGE_PRODUCT, watchGetDetailLandingPageProduct),
  takeLatest(types.UPDATE_LANDING_PAGE_PRODUCT, watchUpdateLandingPageProducts),
  takeLatest(types.GET_PHOTO_GALLERY, watchGetPhotoGallery),
  takeLatest(types.UPLOAD_PHOTO_GALLERY, watchUploadPhotoGallery),
  takeLatest(types.DELETE_PHOTO_GALLERY, watchDeletePhotoGallery),
  takeLatest(types.CHECK_URL_LANDING_PAGE, watchCheckUrlLandingPage),
];

export default sagas;
