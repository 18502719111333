import { Api } from './api';

export default {
  get: payload => Api.get('/v2/documents/seller',{
    headers: {
      token: payload.token
    }
  }),
  add: payload => Api.post('/v2/documents/seller/add',{
    no_ktp: payload.no_ktp,
    name: payload.name
  },{
    headers: {
      token: payload.token
    }
  }),
  uploadKTP: payload => Api.put('/v2/documents/seller/upload-ktp', payload.data,{
    headers: {
      token: payload.token,
      'Content-Type': 'multipart/form-data'
    }
  }),
  uploadNPWP: payload => Api.put('/v2/documents/seller/upload-npwp', payload.data,{
    headers: {
      token: payload.token,
      'Content-Type': 'multipart/form-data'
    }
  }),
  uploadAKTA: payload => Api.put('/v2/documents/seller/upload-akta', payload.data, {
    headers: {
      token: payload.token,
      'Content-Type': 'multipart/form-data'
    }
  }),
  uploadSIUP: payload => Api.put('/v2/documents/seller/upload-siup', payload.data,{
    headers: {
      token: payload.token,
      'Content-Type': 'multipart/form-data'
    }
  }),
  uploadSKDP: payload => Api.put('/v2/documents/seller/upload-skdp', payload.data,{
    headers: {
      token: payload.token,
      'Content-Type': 'multipart/form-data'
    }
  }),
  uploadTDP: payload => Api.put('/v2/documents/seller/upload-tdp', payload.data,{
    headers: {
      token: payload.token,
      'Content-Type': 'multipart/form-data'
    }
  }),
}
