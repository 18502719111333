import { Api } from './api';

export default {
  getRFQ: payload => Api.get('/v1/rfq',{
    params: {
      limit: payload.limit,
      page: payload.page
    }
  }),
  getRequest: payload => Api.get('/v1/rfq/buyer-request',{
    headers: {token:payload.token},
    params: {
      limit: payload.limit,
      page: payload.page
    }
  }),
  getOffer: payload => Api.get(`/v1/rfq/seller-offer`, {
    params: {
      page: payload.page,
      limit: payload.limit
    },
    headers: { token: payload.token },
  }),
  getDetail: payload => Api.get(`/v1/rfq/${payload.id}`, {
    headers: { token: payload.token },
  }),
  createRequest: async payload => {
    try{
      const formData = new FormData();
      await formData.append("user_name", payload.user_name)
      await formData.append("user_email", payload.user_email)
      await formData.append("user_phone_number", payload.user_phone_number)
      await formData.append("name_item", payload.name_item)
      await formData.append("category", payload.category)
      await formData.append("quantity", payload.quantity)
      await formData.append("unit", payload.unit)
      await formData.append("min_price", payload.min_price)
      await formData.append("max_price", payload.max_price)
      await formData.append("description", payload.description)
      await formData.append("last_response_date", payload.last_response_date.toString())
      await formData.append("address", payload.address)
      await formData.append("type_shipping_cost", payload.type_shipping_cost)
      await payload.image_item.map((image,i)=> formData.append(`image_item_${i}`, image.originFileObj))
      return Api.post(`/v1/rfq`, formData,{
        headers:{
          token: payload.token,
          'Content-Type': 'multipart/form-data'
        }
      })
    }catch(e){}
  },
  createOffer: payload => Api.post(`/v1/rfq/seller/offer`,payload.data,{
    headers: { token: payload.token },
  }),
  updateOffer: payload => Api.put(`/v1/rfq/seller/offer/${payload.id}`,payload.data,{
    headers: { token: payload.token },
  }),
  deleteOffer: payload => Api.delete(`/v1/rfq/seller/offer/${payload.id}`, {
    headers: { token: payload.token },
  }),
  createComment: payload => Api.post(`/v1/rfq/seller/offer/comment`,{
    id:payload.id,
    text:payload.text
  },{
    headers: { token: payload.token },
  }),
};
