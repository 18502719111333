import { Api } from "./api";

export default {
  get: (payload) =>
    Api.get("/v2/bank-accounts", {
      headers: { token: payload.token },
    }),
  add: (payload) =>
    Api.post(`/v2/bank-accounts/create/new`, payload.data, {
      headers: { token: payload.token },
    }),
  verifyOTPBankAccount: (payload) =>
    Api.post(
      `/v2/bank-accounts/create/verify`,
      {
        otp_code: payload.otp_code,
      },
      {
        headers: { token: payload.token },
      }
    ),
  resendOTPBankAccount: (payload) =>
    Api.get(`/v1/bank-accounts/resend-otp`, {
      headers: { token: payload.token },
    }),
  editById: (payload) =>
    Api.put(`/v1/bank-accounts/${payload.id}`, payload.data, {
      headers: { token: payload.token },
    }),
  editDefaultById: (payload) =>
    Api.put(
      `/v1/bank-accounts/is_default/${payload.id}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),
  deleteById: (payload) =>
    Api.put(
      `/v2/bank-accounts/set-deleted/${payload.id}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),
  banks: (payload) =>
    Api.get(`/json-server/banks`, {
      headers: { token: payload.token },
    }),
};
