import { Api, baseUrlPlasgos } from "./api";
import axios from "axios";

export default {
  getDetailProfile: (payload) =>
    Api.get("/v2/user", {
      headers: { token: payload.token },
    }),
  changePhoneNumber: (payload) =>
    Api.put(
      `/v1/users/update/phone-number`,
      {
        phone_number: payload.phone_number,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
  changeProfile: (payload) =>
    Api.put(`/v1/users/${payload.id}`, payload.data, {
      headers: { token: payload.token },
    }),
  changePassword: (payload) =>
    Api.put(`/v1/users/change_password/${payload.id}`, payload.data, {
      headers: { token: payload.token },
    }),
  changeAvatar: (payload) => {
    const formData = new FormData();
    formData.append("image", payload.image);
    return axios
      .put(`${baseUrlPlasgos}/v1/users/change_photo/${payload.id}`, formData, {
        headers: {
          token: payload.token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  },
  getOtpPhoneNumber: (payload) =>
    Api.post(
      `/v1/users/send-otp-activation-phone-number`,
      {
        phone_number: payload.phone_number,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
  resendGetOtpPhoneNumber: (payload) =>
    Api.post(
      `/v1/users/resend-otp`,
      {
        phone_number: payload.phone_number,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
  confirmOtpPhoneNumber: (payload) =>
    Api.post(
      `/v1/users/confirm-otp`,
      {
        otp_code: payload.otp_code,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
  requestChangeEmail: (payload) =>
    Api.post(
      `/v1/users/request-change-email`,
      {
        email: payload.email,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),

  getProfileByEmail: (payload) =>
    Api.get("/v1/users/email", {
      params: {
        email: payload.email,
      },
    }),
  getLinkResetPassword: (payload) =>
    Api.get("/v1/auth/link-reset-password", {
      params: {
        email: payload.email,
      },
    }),
  resetPassword: (payload) =>
    Api.get("/v1/auth/reset-password", {
      params: {
        token: payload.token,
      },
    }),
  getDetailForgotPassword: (payload) =>
    Api.get("/v1/auth/detail-forgot-password", {
      params: {
        token: payload.code,
      },
    }),
  requestOTPResetPassword: (payload) =>
    Api.get("/v1/users/request-otp/change-password", {
      headers: {
        token: payload.token,
      },
    }),
  sendOTPResetPassword: (payload) =>
    Api.post(
      "/v1/users/confirm-otp/change-password",
      {
        otp_code: payload.otp,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
  checkPassword: (payload) =>
    Api.post(
      "/v1/users/check-password",
      {
        password: payload.password,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
  requestLinkChangeEmail: (payload) =>
    Api.get("/v1/users/request-link/change-email", {
      headers: {
        token: payload.token,
      },
    }),
  getDetailByChangeEmailToken: (payload) =>
    Api.get("/v1/users/detail/change-email-by-token", {
      params: {
        token: payload.code,
      },
      headers: {
        token: payload.token,
      },
    }),
  checkNewEmail: (payload) =>
    Api.post(
      "/v1/users/change-email-otp",
      {
        new_email: payload.new_email,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
  updateNewEmailOTP: (payload) =>
    Api.post(
      "/v1/users/update-email/otp",
      {
        new_email: payload.new_email,
        otp_code: payload.otp_code,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),

  checkLastPhoneNumberByEmail: (payload) =>
    Api.get("/v1/users/check-last-phone-number/email", {
      headers: {
        token: payload.token,
      },
    }),
  checkLastPhoneNumberBySMS: (payload) =>
    Api.get("/v1/users/check-last-phone-number/phone", {
      headers: {
        token: payload.token,
      },
    }),
  confirmLastPhoneNumber: (payload) =>
    Api.post(
      "/v1/users/check-last-phone-number/confirm",
      {
        otp: payload.otp,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
  checkNewPhoneNumber: (payload) =>
    Api.post(
      "/v1/users/check-phone-number",
      {
        phone_number: payload.phone_number,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
  changePhoneNumberOtp: (payload) =>
    Api.post(
      "/v1/users/update-phone-number/otp",
      {
        phone_number: payload.phone_number,
        otp_code: payload.otp,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
};
