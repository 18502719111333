import types from './constant';

const initialState = {
  shop: {},
  isLoadingShop: false,
  isLoadingUpdate: false,
  isLoadingUploadAvatar: false,
  updated_shop:false
};

export default (state = initialState, action) => {
  switch(action.type){
    case types.GET_DETAIL_STORE_SUCCESS:
      return { ...state, shop: action.payload}
    case types.IS_LOADING_GET_DETAIL_STORE:
      return { ...state, isLoadingShop: action.payload}
    case types.UPDATE_STORE_SUCCESS:
      return {
        ...state,
        shop: {
          ...state.shop,
          ...action.payload
        }
      }
    case types.IS_LOADING_UPDATE_STORE:
      return { ...state, isLoadingUpdate: action.payload}
    case types.UPDATED_STORE:
      return { ...state, updated_shop: action.payload }
    case types.IS_LOADING_UPLOAD_AVATAR_STORE:
      return { ...state, isLoadingUploadAvatar: action.payload }
    default:
      return state
  }
}

export const getShop = payload => ({
  type: types.GET_DETAIL_STORE,
  payload
})
export const getShopSuccess = payload => ({
  type: types.GET_DETAIL_STORE_SUCCESS,
  payload
})
export const setIsLoadingShop = payload => ({
  type: types.IS_LOADING_GET_DETAIL_STORE,
  payload
})

export const updateShop = payload => ({
  type: types.UPDATE_STORE,
  payload
})
export const updateShopSuccess = payload => ({
  type: types.UPDATE_STORE_SUCCESS,
  payload
})
export const setIsLoadingUpdateShop = payload => ({
  type: types.IS_LOADING_UPDATE_STORE,
  payload
})

export const uploadAvatar = payload => ({
  type: types.UPLOAD_AVATAR_STORE,
  payload
})
export const setIsLoadingUploadAvatar = payload => ({
  type: types.IS_LOADING_UPLOAD_AVATAR_STORE,
  payload
})

export const updatedStore = payload => ({
  type: types.UPDATED_STORE,
  payload
})
