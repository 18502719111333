import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";

// import {
//   cibSkype,
//   cibFacebook,
//   cibTwitter,
//   cibLinkedin,
//   cibFlickr,
//   cibTumblr,
//   cibXing,
//   cibGithub,
//   // cibStackoverflow,
//   cibYoutube,
//   cibDribbble,
//   cibInstagram,
//   cibPinterest,
//   cibVk,
//   cibYahoo,
//   cibBehance,
//   cibReddit,
//   cibVimeo,
//   cibCcMastercard,
//   cibCcVisa,
//   cibStripe,
//   cibPaypal,
//   cibGooglePay,
//   cibCcAmex,
// } from "@coreui/icons";
// import { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl } from "@coreui/icons";
import * as coreIcons from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  // {
  //   cilAlignCenter,
  //   cilAlignLeft,
  //   cilAlignRight,
  //   cilApplicationsSettings,
  //   cilArrowRight,
  //   cilArrowTop,
  //   cilAsterisk,
  //   cilBan,
  //   cilBarChart,
  //   cilBasket,
  //   cilBell,
  //   cilBold,
  //   cilBookmark,
  //   cilCalculator,
  //   cilCalendar,
  //   cilCloudDownload,
  //   cilChatBubble,
  //   cilChartPie,
  //   cilCheck,
  //   cilChevronBottom,
  //   cilChevronLeft,
  //   cilChevronRight,
  //   cilChevronTop,
  //   cilCircle,
  //   cilCheckCircle,
  //   cilCode,
  //   cilCommentSquare,
  //   cilCreditCard,
  //   cilCursor,
  //   cilCursorMove,
  //   cilDrop,
  //   cilDollar,
  //   cilEnvelopeClosed,
  //   cilEnvelopeLetter,
  //   cilEnvelopeOpen,
  //   cilEuro,
  //   cilGlobeAlt,
  //   cilGrid,
  //   cilFile,
  //   cilFullscreen,
  //   cilFullscreenExit,
  //   cilGraph,
  //   cilHome,
  //   cilInbox,
  //   cilIndentDecrease,
  //   cilIndentIncrease,
  //   cilInputPower,
  //   cilItalic,
  //   cilJustifyCenter,
  //   cilJustifyLeft,
  //   cilLaptop,
  //   cilLayers,
  //   cilLightbulb,
  //   cilList,
  //   cilListNumbered,
  //   cilListRich,
  //   cilLocationPin,
  //   cilLockLocked,
  //   cilMagnifyingGlass,
  //   cilMap,
  //   cilMoon,
  //   cilMoney,
  //   cilNotes,
  //   cilOptions,
  //   cilPaperclip,
  //   cilPaperPlane,
  //   cilPencil,
  //   cilPeople,
  //   cilPhone,
  //   cilPrint,
  //   cilPuzzle,
  //   cilSave,
  //   cilScrubber,
  //   cilSettings,
  //   cilShare,
  //   cilShareAll,
  //   cilShareBoxed,
  //   cilShieldAlt,
  //   cilSpeech,
  //   cilSpeedometer,
  //   cilSpreadsheet,
  //   cilStar,
  //   cilSun,
  //   cilTags,
  //   cilTask,
  //   cilTrash,
  //   cilUnderline,
  //   cilUser,
  //   cilUserFemale,
  //   cilUserFollow,
  //   cilUserUnfollow,
  //   cilX,
  //   cilXCircle,
  //   cilWarning,
  //   cilWallet,
  // },
  coreIcons
  // {
  //   cifUs,
  //   cifBr,
  //   cifIn,
  //   cifFr,
  //   cifEs,
  //   cifPl,
  // },
  // {
  //   cibSkype,
  //   cibFacebook,
  //   cibTwitter,
  //   cibLinkedin,
  //   cibFlickr,
  //   cibTumblr,
  //   cibXing,
  //   cibGithub,
  //   // cibStackoverflow,
  //   cibYoutube,
  //   cibDribbble,
  //   cibInstagram,
  //   cibPinterest,
  //   cibVk,
  //   cibYahoo,
  //   cibBehance,
  //   cibReddit,
  //   cibVimeo,
  //   cibCcMastercard,
  //   cibCcVisa,
  //   cibStripe,
  //   cibPaypal,
  //   cibGooglePay,
  //   cibCcAmex,
  // }
);
