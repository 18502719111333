import types from './types';

const initialState = {
  data:[],
  isLoadingGet:false,
  isLoadingAdd:false,
  isLoadingEdit:false,
  isLoadingDelete:false,
  isLoadingSetDefault:false,
  banks:[],
  isLoadingGetBanks:false,
  success:false,
  requestOTPSuccess:false,
  isLoadingVerifyOTPBankAccount: false
}

export default (state=initialState, action) => {
  switch(action.type){
    //GET ALL
    case types.GET_ALL_BANK_ACCOUNT_SUCCESS:
      return {...state, data:action.payload}
    case types.IS_LOADING_GET_BANK_ACCOUNT:
      return {...state, isLoadingGet:action.payload}
    //CREATE
    case types.CREATE_BANK_ACCOUNT_SUCCESS:
      return {...state, requestOTPSuccess:action.payload}
    case types.IS_LOADING_CREATE_BANK_ACCOUNT:
      return {...state, isLoadingAdd:action.payload}
    case types.VERIFY_OTP_BANK_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        data: [...(state.data.concat(action.payload))]
      });
    case types.IS_LOADING_VERIFY_OTP_BANK_ACCOUNT:
      return { ...state, isLoadingVerifyOTPBankAccount: action.payload}
    //EDIT
    case types.EDIT_BANK_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.map(item => {
          return item.id === action.payload.id ? {...action.payload.data, id:action.payload.id} : item;
        })
      });
    case types.IS_LOADING_EDIT_BANK_ACCOUNT:
      return {...state, isLoadingEdit:action.payload}
    //SET DEFAULT
    case types.SET_DEFAULT_BANK_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.map(item => {
          return item.id === action.payload.id ? {
            ...action.payload.data,
            is_default:true
          } : {
            ...item,
            is_default:false
          };
        })
      });
    case types.IS_LOADING_SET_DEFAULT_BANK_ACCOUNT:
      return {...state, isLoadingSetDefault:action.payload}
    //DELETE
    case types.DELETE_BANK_ACCOUNT_SUCCESS:
      let newBankAccount = state.data.filter(item => {
        return item.id !== action.payload.id
      })
      return Object.assign({}, state, {
        data: [...(newBankAccount)]
      });
    case types.IS_LOADING_DELETE_BANK_ACCOUNT:
      return {...state, isLoadingDelete:action.payload}
    case types.GET_BANKS_SUCCESS:
      return {...state, banks:action.payload}
    case types.IS_LOADING_GET_BANKS:
      return {...state, isLoadingGetBanks:action.payload}
    case types.RESPONSE_SUCCESS:
      return {...state, success:action.payload}
    default:
      return state;
  }
}

//GET
export const getAllBankAccount = payload => ({
  type:types.GET_ALL_BANK_ACCOUNT,
  payload
})
export const getAllBankAccountSuccess = payload => ({
  type:types.GET_ALL_BANK_ACCOUNT_SUCCESS,
  payload
})
export const setIsLoadingGetBankAccount = payload => ({
  type:types.IS_LOADING_GET_BANK_ACCOUNT,
  payload
})
//CREATE
export const createNewBankAccount = payload => ({
  type:types.CREATE_BANK_ACCOUNT,
  payload
})
export const createNewBankAccountSuccess = payload => ({
  type:types.CREATE_BANK_ACCOUNT_SUCCESS,
  payload
})
export const setIsLoadingCreateBankAccount = payload => ({
  type:types.IS_LOADING_CREATE_BANK_ACCOUNT,
  payload
})
//VERIFY OTP
export const verifyOTPBankAccount = payload => ({
  type: types.VERIFY_OTP_BANK_ACCOUNT,
  payload
})
export const verifyOTPBankAccountSuccess = payload => ({
  type: types.VERIFY_OTP_BANK_ACCOUNT_SUCCESS,
  payload
})
export const setIsLoadingVerifyOTPBankAccount = payload => ({
  type: types.IS_LOADING_VERIFY_OTP_BANK_ACCOUNT,
  payload
})
//EDIT
export const editBankAccountById = payload => ({
  type:types.EDIT_BANK_ACCOUNT,
  payload
})
export const editBankAccountByIdSuccess = payload => ({
  type:types.EDIT_BANK_ACCOUNT_SUCCESS,
  payload
})
export const setIsLoadingEditBankAccount = payload => ({
  type:types.IS_LOADING_EDIT_BANK_ACCOUNT,
  payload
})
//SET DEFAULT
export const setDefaultBankAccountById = payload => ({
  type:types.SET_DEFAULT_BANK_ACCOUNT,
  payload
})
export const setDefaultBankAccountByIdSuccess = payload => ({
  type:types.SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
  payload
})
export const setIsLoadingSetDefaultBankAccount = payload => ({
  type:types.IS_LOADING_SET_DEFAULT_BANK_ACCOUNT,
  payload
})
//DELETE
export const deleteBankAccountById = payload => ({
  type:types.DELETE_BANK_ACCOUNT,
  payload
})
export const deleteBankAccountByIdSuccess = payload => ({
  type:types.DELETE_BANK_ACCOUNT_SUCCESS,
  payload
})
export const setIsLoadingDeleteBankAccount = payload => ({
  type:types.IS_LOADING_DELETE_BANK_ACCOUNT,
  payload
})
//GET BANKS
export const getBanks = payload => ({
  type:types.GET_BANKS,
  payload
})
export const getBanksSuccess = payload => ({
  type:types.GET_BANKS_SUCCESS,
  payload
})
export const setIsLoadingGetBanks = payload => ({
  type:types.IS_LOADING_GET_BANKS,
  payload
})
//respond
export const setIsResponseSuccess = payload => ({
  type:types.RESPONSE_SUCCESS,
  payload
})
