export default {
  GET_PRODUCTS: 'get-products',
  GET_PRODUCTS_SUCCESS: 'get-products-success',
  SET_COUNT_GET_PRODUCTS: 'set-count-get-products',
  IS_LOADING_GET_PRODUCTS: 'is-loading-get-products',

  GET_PRODUCTS_NOTIFICATION: 'get-products-notification',
  GET_PRODUCTS_NOTIFICATION_SUCCESS: 'get-products-notification-success',
  IS_LOADING_GET_PRODUCTS_NOTIFICATION: 'is-loadingget-products-notification',

  GET_PRODUCT: 'get-product',
  GET_PRODUCT_SUCCESS: 'get-product-success',
  IS_LOADING_GET_PRODUCT: 'is-loading-get-product',

  ADD_PRODUCT: 'add-product',
  ADD_PRODUCT_SUCCESS: 'add-product-success',
  IS_LOADING_ADD_PRODUCT: 'is-loading-add-product',

  UPDATE_PRODUCT: 'update-product',
  UPDATE_PRODUCT_SUCCESS: 'update-product-success',
  IS_LOADING_UPDATE_PRODUCT: 'is-loading-update-product',

  UPDATE_MULTIPLE_STATUS_PRODUCT: 'update-multiple-status-product',
  UPDATE_MULTIPLE_STATUS_PRODUCT_SUCCESS: 'update-multiple-status-product-success',
  IS_LOADING_UPDATE_MULTIPLE_STATUS_PRODUCT: 'is-loading-update-multiple-status-product',

  GET_SHOWCASES: 'get-showcases',
  GET_SHOWCASES_SUCCESS: 'get-showcases-success',
  IS_LOADING_GET_SHOWCASES: 'is-loading-get-showcases',

  ADD_SHOWCASE: 'add-showcase',
  ADD_SHOWCASE_SUCCESS: 'add-showcase-success',
  IS_LOADING_ADD_SHOWCASE: 'is-loading-add-showcase',

  GET_CATEGORIES: 'get-categories',
  GET_CATEGORIES_SUCCESS: 'get-categories-success',
  IS_LOADING_GET_CATEGORIES: 'is-loading-get-categories',

  SET_SUCCESS_PRODUCT: 'set-success-product',
  SET_MESSAGE_PRODUCT: 'set-message-product',
  RESET_PRODUCT: 'reset-product'
};
