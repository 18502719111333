export default {
  GET_ALL_ORDERS : 'get-all-orders',
  GET_NEW_ORDERS : 'get-new-orders',
  GET_PROCESSED_ORDERS : 'get-processed-orders',
  GET_PICKUP_ORDERS : 'get-pickup-orders',
  GET_SHIPPING_ORDERS : 'get-shipping-orders',
  GET_ARRIVED_ORDERS : 'get-arrived-orders',
  GET_DONE_ORDERS : 'get-done-orders',
  GET_IN_COMPLAINT_ORDERS : 'get-in-complaint-orders',
  GET_CANCELED_ORDERS : 'get-canceled-orders',

  GET_ORDERS_SUCCESS : 'get-orders-success',
  IS_LOADING_GET_ORDERS : 'set-is-loading-get-orders',

  UPDATE_ORDER : 'update-order',
  UPDATE_ACCEPT_TO_PROCESSED_ORDER : 'update-accept-to-process-order',
  UPDATE_CANCEL_TO_CANCELLED_ORDER : 'update-cancel-to-cancelled-order',
  UPDATE_SHIPPED_ORDER : 'update-shipped-order',
  UPDATE_ARRIVED_ORDER : 'update-arrived-order',
  UPDATE_DONE_ORDER : 'update-done-order',
  UPDATE_ORDER_SUCCESS : 'update-order-success',
  IS_LOADING_UPDATE_ORDER : 'is-loading-update-order',

  UPDATE_MULTIPLE_ACCEPT_ORDERS: 'update-multiple-accept-orders',
  UPDATE_MULTIPLE_ORDERS_SUCCESS : 'update-multiple-orders-success',
  IS_LOADING_UPDATE_MULTIPLE_ORDERS : 'is-loading-update-multiple-orders',

  UPDATE_LABEL_PRINTED_ORDER: 'update-label-printed-order',
  UPDATE_LABEL_PRINTED_ORDER_SUCCESS: 'update-label-printed-order-success',
  IS_LOADING_UPDATE_LABEL_PRINTED_ORDER: 'is-loading-update-label-printed-order',

  UPDATE_AWB_PRINTED_ORDER: 'update-awb-printed-order',
  UPDATE_AWB_PRINTED_ORDER_SUCCESS: 'update-awb-printed-order-success',
  IS_LOADING_UPDATE_AWB_PRINTED_ORDER: 'is-loading-update-awb-printed-order',

  ORDER_UPDATED : 'order-updated',
  SET_COUNT_ORDERS : 'set-count-orders',
  SET_MESSAGE_SELLING : 'set-message-selling',

  SHOW_CHAT_SELLING: 'show-chat-selling',
  SHOW_TRACKING_SELLING: 'show-tracking-selling',

  CHECK_COST_SINGLE_ORDER: 'check-cost-single-order',
  CHECK_COST_SINGLE_ORDER_SUCCESS: 'check-cost-single-order-success',
  IS_LOADING_CHECK_COST_SINGLE_ORDER: 'is-loading-check-cost-single-order',
  RESET_CHECK_COST_SINGLE_ORDER: 'reset-check-cost-single-order',

  GET_ORDER : 'get-order-on-selling',
  GET_ORDER_SUCCESS : 'get-order-on-selling-success',
  IS_LOADING_GET_ORDER : 'set-is-loading-get-order-on-selling',
  RESET_GET_ORDER : 'reset-get-order-on-selling',

  ADD_ORDER_PACKAGE : 'add-order-package-on-selling',
  ADD_ORDER_PACKAGE_SUCCESS : 'add-order-package-on-selling-success',
  IS_LOADING_ADD_ORDER_PACKAGE : 'set-is-loading-add-order-package-on-selling',
  RESET_ADD_ORDER_PACKAGE : 'reset-add-order-package-on-selling',

};
