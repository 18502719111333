import { put, call, takeLatest, delay } from 'redux-saga/effects';
import Api from '../../services';
import types from './types';
import * as actions from './reducer';

function* watchGetWarehouses(value){
  yield put(actions.setLoadingGetWarehouses(true));
  const { payload } = value;
  try{
    const response = yield call(Api.warehouse.get, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getWarehousesSuccess(data.data));
    }
  }catch(e){
    yield put(actions.setLoadingGetWarehouses(false));
  }finally{
    yield put(actions.setLoadingGetWarehouses(false));
  }
}
function* watchAddWarehouse(value){
  yield put(actions.setLoadingAddWarehouse(true));
  const { payload } = value;
  try{
    const response = yield call(Api.warehouse.add, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.addWarehouseSuccess(data.data));
      yield put(actions.setAddedWarehouse(true));
    }
  }catch(e){
    yield put(actions.setLoadingAddWarehouse(false));
  }finally{
    yield delay(1000);
    yield put(actions.setLoadingAddWarehouse(false));
    yield put(actions.setAddedWarehouse(false));
  }
}
function* watchUpdateWarehouse(value){
  yield put(actions.setLoadingUpdateWarehouse(true));
  const { payload } = value;
  try{
    const response = yield call(Api.warehouse.update, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updateWarehouseSuccess(payload));
      yield put(actions.setUpdatedWarehouse(true));
    }
  }catch(e){
    yield put(actions.setLoadingUpdateWarehouse(false));
  }finally{
    yield delay(1000);
    yield put(actions.setLoadingUpdateWarehouse(false));
    yield put(actions.setUpdatedWarehouse(false));
  }
}
function* watchDeleteWarehouse(value){
  yield put(actions.setLoadingDeleteWarehouse(true));
  const { payload } = value;
  try{
    const response = yield call(Api.warehouse.delete, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.deleteWarehouseSuccess(payload));
      yield put(actions.setDeletedWarehouse(true));
    }
  }catch(e){
    yield put(actions.setLoadingDeleteWarehouse(false));
  }finally{
    yield delay(1000);
    yield put(actions.setLoadingDeleteWarehouse(false));
    yield put(actions.setDeletedWarehouse(false));
  }
}
const sagas = [
  takeLatest(types.GET_WAREHOUSES, watchGetWarehouses),
  takeLatest(types.ADD_WAREHOUSE, watchAddWarehouse),
  takeLatest(types.UPDATE_WAREHOUSE, watchUpdateWarehouse),
  takeLatest(types.DELETE_WAREHOUSE, watchDeleteWarehouse),
]

export default sagas;
