import types from './constant';

const initialState = {
  data: [],
  count: 0,
  detail:{},
  histories:[],
  discussions:[],
  bid_success: false,
  approved:false,
  ask_help_success:false,
  delivery_receipt_success:false,
  services:[],
  isLoadingServices:false,
  isLoadingDeliveryReceipt:false,
  isLoadingAskForHelp: false,
  isLoadingApproved: false,
  isLoadingBidPrice: false,
  isLoadingGetDiscussions:false,
  isLoadingPostDiscussion:false,
  isLoadingGetHistories:false,
  isLoadingGetComplaints: false,
  isLoadingGetDetail:false,
};

export default (state = initialState, action) => {
  switch(action.type){
    case types.GET_COMPLAINTS_SUCCESS:
      return { ...state, data: action.payload}
    case types.IS_LOADING_GET_COMPLAINTS:
      return { ...state, isLoadingGetComplaints: action.payload}
    case types.SET_COUNT_COMPLAINTS:
      return { ...state, count: action.payload }
    case types.GET_DETAIL_COMPLAINT_SUCCESS:
      return { ...state, detail: action.payload}
    case types.IS_LOADING_GET_DETAIL_COMPLAINT:
      return { ...state, isLoadingGetDetail: action.payload}
    case types.GET_COMPLAINT_HISTORIES_SUCCESS:
      return { ...state, histories: action.payload }
    case types.IS_LOADING_GET_COMPLAINT_HISTORIES:
      return { ...state, isLoadingGetHistories: action.payload}
    case types.GET_COMPLAINT_DISCUSSIONS_SUCCESS:
      return { ...state, discussions: action.payload }
    case types.IS_LOADING_GET_COMPLAINT_DISCUSSIONS:
      return { ...state, isLoadingGetDiscussions: action.payload}
    case types.POST_COMPLAINT_DISCUSSION_SUCCESS:
      return {...state, discussions: [...state.discussions.concat(action.payload)]}
    case types.IS_LOADING_POST_COMPLAINT_DISCUSSION:
      return { ...state, isLoadingPostDiscussion: action.payload}
    case types.POST_BID_PRICE_COMPLAINT_SUCCESS:
      return {
        ...state,
        discussions: [...state.discussions.concat(action.payload.discussion)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt)),
        histories: [...state.histories.concat(action.payload.history)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt))
      }
    case types.IS_LOADING_POST_BID_PRICE_COMPLAINT:
      return {...state, isLoadingBidPrice: action.payload}
    case types.SET_BID_PRICE_SUCCESS:
      return {...state, bid_success: action.payload}
    case types.PUT_APPROVED_COMPLAINT_SUCCESS:
      return {
        ...state,
        data: state.data.map(dt=> dt.id === state.detail.id ? {
          ...dt,
          approved_by_seller: action.payload.approved_by_seller,
          approved_by_sellerAt: action.payload.approved_by_sellerAt
        }: dt ),
        detail: {
          ...state.detail,
          approved_by_seller: action.payload.approved_by_seller,
          approved_by_sellerAt: action.payload.approved_by_sellerAt
        },
        histories: [...state.histories.concat(action.payload.history)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt))
      }
    case types.IS_LOADING_PUT_APPROVED_COMPLAINT:
      return {...state, isLoadingApproved: action.payload}
    case types.SET_APPROVED_SUCCESS:
      return {...state, approved: action.payload}
    case types.ASK_FOR_HELP_COMPLAINT_SUCCESS:
      return {
        ...state,
        data: state.data.map(dt=> dt.id === state.detail.id ? {
          ...dt,
          ask_for_help: action.payload.ask_for_help,
          ask_for_helpAt: action.payload.ask_for_helpAt
        }: dt ),
        detail: {
          ...state.detail,
          ask_for_help: action.payload.ask_for_help,
          ask_for_helpAt: action.payload.ask_for_helpAt
        },
        histories: [...state.histories.concat(action.payload.history)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt)),
        discussions: [...state.discussions.concat(action.payload.discussion)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt)),
        ask_help_success:action.payload.success
      }
    case types.IS_LOADING_ASK_FOR_HELP_COMPLAINT:
      return {...state, isLoadingAskForHelp: action.payload}
    case types.SET_SUCCESS_ASK_FOR_HELP_COMPLAINT:
      return {...state, ask_help_success: action.payload}
    case types.POST_DELIVERY_RECEIPT_COMPLAINT_SUCCESS:
      return {
        ...state,
        discussions: [...state.discussions.concat(action.payload.discussion)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt)),
        histories: [...state.histories.concat(action.payload.history)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt)),
      }
    case types.IS_LOADING_POST_DELIVERY_RECEIPT_COMPLAINT:
      return {...state, isLoadingDeliveryReceipt: action.payload}
    case types.SET_DELIVERY_RECEIPT_SUCCESS:
      return {...state, delivery_receipt_success: action.payload}
    case types.GET_SERVICES_COMPLAINT_SUCCESS:
      return {...state, services: action.payload}
    case types.IS_LOADING_GET_SERVICES_COMPLAINT:
      return {...state, isLoadingServices: action.payload}
    default:
      return state
  }
}

export const getServices = payload => ({
  type: types.GET_SERVICES_COMPLAINT,
  payload
})
export const getServicesSuccess = payload => ({
  type: types.GET_SERVICES_COMPLAINT_SUCCESS,
  payload
})
export const setIsLoadingServices = payload => ({
  type: types.IS_LOADING_GET_SERVICES_COMPLAINT,
  payload
})

export const getComplaints = payload => ({
  type: types.GET_COMPLAINTS,
  payload
})
export const getComplaintsSuccess = payload => ({
  type: types.GET_COMPLAINTS_SUCCESS,
  payload
})
export const setIsLoadingComplaints = payload => ({
  type: types.IS_LOADING_GET_COMPLAINTS,
  payload
})
export const setCount = payload => ({
  type: types.SET_COUNT_COMPLAINTS,
  payload
})

export const getDetailComplaint = payload => ({
  type: types.GET_DETAIL_COMPLAINT,
  payload
})
export const getDetailComplaintSuccess = payload => ({
  type: types.GET_DETAIL_COMPLAINT_SUCCESS,
  payload
})
export const setIsLoadingDetailComplaint = payload => ({
  type: types.IS_LOADING_GET_DETAIL_COMPLAINT,
  payload
})

export const getHistories = payload => ({
  type: types.GET_COMPLAINT_HISTORIES,
  payload
})
export const getHistoriesSuccess = payload => ({
  type: types.GET_COMPLAINT_HISTORIES_SUCCESS,
  payload
})
export const setIsLoadingGetHistories = payload => ({
  type: types.IS_LOADING_GET_COMPLAINT_HISTORIES,
  payload
})
export const getDiscussions = payload => ({
  type: types.GET_COMPLAINT_DISCUSSIONS,
  payload
})
export const getDiscussionsSuccess = payload => ({
  type: types.GET_COMPLAINT_DISCUSSIONS_SUCCESS,
  payload
})
export const setIsLoadingGetDiscussions = payload => ({
  type: types.IS_LOADING_GET_COMPLAINT_DISCUSSIONS,
  payload
})
export const postDiscussion = payload => ({
  type: types.POST_COMPLAINT_DISCUSSION,
  payload
})
export const postDiscussionSuccess = payload => ({
  type: types.POST_COMPLAINT_DISCUSSION_SUCCESS,
  payload
})
export const setIsLoadingPostDiscussion = payload => ({
  type: types.IS_LOADING_POST_COMPLAINT_DISCUSSION,
  payload
})

export const postBidPrice = payload => ({
  type: types.POST_BID_PRICE_COMPLAINT,
  payload
})
export const postBidPriceSuccess = payload => ({
  type: types.POST_BID_PRICE_COMPLAINT_SUCCESS,
  payload
})
export const setIsLoadingPostBidPrice = payload => ({
  type: types.IS_LOADING_POST_BID_PRICE_COMPLAINT,
  payload
})
export const setPriceBidSuccess = payload => ({
  type: types.SET_BID_PRICE_SUCCESS,
  payload
})

export const putApproved = payload => ({
  type: types.PUT_APPROVED_COMPLAINT,
  payload
})
export const putApprovedSuccess = payload => ({
  type: types.PUT_APPROVED_COMPLAINT_SUCCESS,
  payload
})
export const setIsLoadingPutApproved = payload => ({
  type: types.IS_LOADING_PUT_APPROVED_COMPLAINT,
  payload
})
export const setApprovedSuccess = payload => ({
  type: types.SET_APPROVED_SUCCESS,
  payload
})

export const putAskForHelp = payload => ({
  type: types.ASK_FOR_HELP_COMPLAINT,
  payload
})
export const putAskForHelpSuccess = payload => ({
  type: types.ASK_FOR_HELP_COMPLAINT_SUCCESS,
  payload
})
export const setIsLoadingPutAskForHelp = payload => ({
  type: types.IS_LOADING_ASK_FOR_HELP_COMPLAINT,
  payload
})
export const setAskForHelpSuccess = payload => ({
  type: types.SET_SUCCESS_ASK_FOR_HELP_COMPLAINT,
  payload
})

export const postDeliveryReceipt = payload => ({
  type: types.POST_DELIVERY_RECEIPT_COMPLAINT,
  payload
})
export const postDeliveryReceiptSuccess = payload => ({
  type: types.POST_DELIVERY_RECEIPT_COMPLAINT_SUCCESS,
  payload
})
export const setIsLoadingPostDeliveryReceipt = payload => ({
  type: types.IS_LOADING_POST_DELIVERY_RECEIPT_COMPLAINT,
  payload
})
export const setDeliveryReceiptSuccess = payload => ({
  type: types.SET_DELIVERY_RECEIPT_SUCCESS,
  payload
})
