import { put, call, takeLatest, select, delay } from "redux-saga/effects";
import Api from "../../services";
import types from "./constant";
import * as actions from "./reducer";
import * as actionsDashboard from "../../modules/dashboard/reducer";
import { notification } from "../../helpers/notification";

// Get orders data
function* watchGetAllOrders(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetOrders(true));
  try {
    const response = yield call(Api.selling.all, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrdersSuccess(data.data));
      yield put(actions.setCountOrders(data.count));
    } else {
      throw Error;
    }
  } catch (e) {
    // yield put(actions.getAuthenticationSuccess(result));
  } finally {
    yield put(actions.setIsLoadingGetOrders(false));
  }
}
function* watchGetNewOrders(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetOrders(true));
  try {
    const response = yield call(Api.selling.new, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrdersSuccess(data.data));
      yield put(actions.setCountOrders(data.count));
    } else {
      throw Error;
    }
  } catch (e) {
    // yield put(actions.getAuthenticationSuccess(result));
  } finally {
    yield put(actions.setIsLoadingGetOrders(false));
  }
}
function* watchGetCancelOrders(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetOrders(true));
  try {
    const response = yield call(Api.selling.cancelled, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrdersSuccess(data.data));
      yield put(actions.setCountOrders(data.count));
    } else {
      throw Error;
    }
  } catch (e) {
    // yield put(actions.getAuthenticationSuccess(result));
  } finally {
    yield put(actions.setIsLoadingGetOrders(false));
  }
}
function* watchGetProcessOrders(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetOrders(true));
  try {
    const response = yield call(Api.selling.processed, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrdersSuccess(data.data));
      yield put(actions.setCountOrders(data.count));
    } else {
      throw Error;
    }
  } catch (e) {
    yield notification.error(e.response.data?.message);
    // yield put(actions.getAuthenticationSuccess(result));
  } finally {
    yield put(actions.setIsLoadingGetOrders(false));
  }
}
function* watchGetPickupOrders(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetOrders(true));
  try {
    const response = yield call(Api.selling.pickup, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrdersSuccess(data.data));
      yield put(actions.setCountOrders(data.count));
    } else {
      throw Error;
    }
  } catch (e) {
    yield notification.error(e.response.data?.message);
    // yield put(actions.getAuthenticationSuccess(result));
  } finally {
    yield put(actions.setIsLoadingGetOrders(false));
  }
}
function* watchGetShippedOrders(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetOrders(true));
  try {
    const response = yield call(Api.selling.shipped, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrdersSuccess(data.data));
      yield put(actions.setCountOrders(data.count));
    }
  } catch (e) {
    yield notification.error(e.response.data?.message);
  } finally {
    yield put(actions.setIsLoadingGetOrders(false));
  }
}
function* watchGetArrivedOrders(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetOrders(true));
  try {
    const response = yield call(Api.selling.arrived, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrdersSuccess(data.data));
      yield put(actions.setCountOrders(data.count));
    }
  } catch (e) {
    yield notification.error(e.response.data?.message);
  } finally {
    yield put(actions.setIsLoadingGetOrders(false));
  }
}
function* watchGetDoneOrders(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetOrders(true));
  try {
    const response = yield call(Api.selling.done, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrdersSuccess(data.data));
      yield put(actions.setCountOrders(data.count));
    } else {
      throw Error;
    }
  } catch (e) {
    yield notification.error(e.response.data?.message);
    // yield put(actions.getAuthenticationSuccess(result));
  } finally {
    yield put(actions.setIsLoadingGetOrders(false));
  }
}
function* watchGetComplaintOrders(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetOrders(true));
  try {
    const response = yield call(Api.selling.complaint, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrdersSuccess(data.data));
      yield put(actions.setCountOrders(data.count));
    } else {
      throw Error;
    }
  } catch (e) {
    yield notification.error(e.response.data?.message);
    // yield put(actions.getAuthenticationSuccess(result));
  } finally {
    yield put(actions.setIsLoadingGetOrders(false));
  }
}
function* watchGetOneOrder(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetOrder(true));
  try {
    const response = yield call(Api.selling.getOne, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrderSuccess(data.data));
    }
  } catch (e) {
    yield notification.error(e.response.data?.message);
    // yield put(actions.getAuthenticationSuccess(result));
  } finally {
    yield put(actions.setIsLoadingGetOrder(false));
  }
}
//Action update status order
function* watchUpdateAcceptOrder(value) {
  const { payload } = value;
  const { selling, dashboard } = yield select();
  yield put(actions.setIsLoadingUpdateOrder(true));
  try {
    const response = yield call(Api.selling.updateAcceptOrder, payload);
    const { data } = response;
    if (data.success) {
      let result = yield selling.data.filter((item) => item.id !== payload.id);
      let result_notification = {
        ...dashboard.selling,
        new_order: dashboard.selling.new_order - 1,
        order_processed: dashboard.selling.order_processed + 1,
        order_confirmation: dashboard.selling.order_confirmation + 1,
      };
      yield delay(1000);
      yield put(actions.getOrdersSuccess(result));
      yield put(actions.setCountOrders(selling.count - 1));
      yield put(
        actionsDashboard.getNotificationSellingSuccess(result_notification)
      );
      yield put(actions.orderUpdated(true));
    } else {
      throw Error;
    }
  } catch (e) {
    yield notification.error(e.response.data?.message);
  } finally {
    yield put(actions.orderUpdated(false));
    yield put(actions.setIsLoadingUpdateOrder(false));
  }
}
function* watchUpdateCancelOrder(value) {
  const { payload } = value;
  const { selling, dashboard } = yield select();
  yield put(actions.setIsLoadingUpdateOrder(true));
  try {
    const response = yield call(Api.selling.updateCancelOrders, payload);
    const { data } = response;
    if (data.success) {
      let result = yield selling.data.filter((item) => item.id !== payload.id);
      let result_notification = {
        ...dashboard.selling,
        new_order: dashboard.selling.new_order - 1,
      };
      yield delay(1000);
      yield put(actions.getOrdersSuccess(result));
      yield put(actions.setCountOrders(selling.count - 1));
      yield put(
        actionsDashboard.getNotificationSellingSuccess(result_notification)
      );
      yield put(actions.orderUpdated(true));
    } else {
      throw Error;
    }
  } catch (e) {
    yield notification.error(e.response.data?.message);
  } finally {
    yield put(actions.orderUpdated(false));
    yield put(actions.setIsLoadingUpdateOrder(false));
  }
}
// Action update status with multiple ids
function* watchUpdateMultipleAcceptOrders(value) {
  const { payload } = value;
  const { selling, dashboard } = yield select();
  yield put(actions.setIsLoadingUpdateMultipleAcceptOrders(true));
  try {
    const response = yield call(Api.selling.updateMultipleAcceptOrder, payload);
    const { data } = response;
    if (data.success) {
      let result = yield selling.data.filter(
        (item) => !payload.ids.includes(item.id)
      );
      let result_notification = {
        ...dashboard.selling,
        new_order: dashboard.selling.new_order - payload.ids.length,
        order_processed: dashboard.selling.order_processed + payload.ids.length,
        order_confirmation:
          dashboard.selling.order_confirmation + payload.ids.length,
      };
      yield delay(1000);
      yield put(actions.updateMultipleAcceptOrdersSuccess(result));
      yield put(actions.setCountOrders(selling.count - payload.ids.length));
      yield put(
        actionsDashboard.getNotificationSellingSuccess(result_notification)
      );
      yield put(actions.orderUpdated(true));
      yield put(actions.setMessage(data.message));
    } else {
      throw Error;
    }
  } catch (e) {
    yield notification.error(e.response.data?.message);
  } finally {
    yield put(actions.setIsLoadingUpdateMultipleAcceptOrders(false));
    yield delay(3000);
    yield put(actions.orderUpdated(false));
    yield put(actions.setMessage(""));
  }
}

//update printed
function* watchUpdateLabelPrintedOrder(value) {
  const { payload } = value;
  yield put(actions.isLoadingUpdateLabelPrinted(true));
  try {
    const response = yield call(Api.selling.updateIsPrintedOrder, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updateLabelPrintedSuccess(data));
      yield put(actions.orderUpdated(true));
    } else {
      throw Error;
    }
  } catch (e) {
    yield notification.error(e.response.data?.message);
  } finally {
    yield put(actions.orderUpdated(false));
    yield put(actions.isLoadingUpdateLabelPrinted(false));
  }
}
function* watchUpdateReceiptPrintedOrder(value) {
  const { payload } = value;
  yield put(actions.isLoadingUpdateAWBPrinted(true));
  try {
    const response = yield call(Api.selling.updateReceiptPrinted, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updateAWBPrintedSuccess(data));
      yield put(actions.orderUpdated(true));
    } else {
      throw Error;
    }
  } catch (e) {
    yield notification.error(e.response.data?.message);
  } finally {
    yield put(actions.orderUpdated(false));
    yield put(actions.isLoadingUpdateAWBPrinted(false));
  }
}

//Plasgos x kiriminaja
function* watchAddOrderPackage(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingAddOrderPackage(true));
  try {
    const response = yield call(Api.selling.addPackage, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.addOrderPackageSuccess(data));
      yield put(actions.orderUpdated(true));
    } else {
      throw Error;
    }
  } catch (e) {
    yield notification.error(e.response.data?.message);
  } finally {
    yield put(actions.orderUpdated(false));
    yield put(actions.setIsLoadingAddOrderPackage(false));
  }
}
function* watchUpdateShippingOrder(value) {
  const { payload } = value;
  const { selling, dashboard } = yield select();
  yield put(actions.setIsLoadingUpdateOrder(true));
  try {
    const response = yield call(Api.selling.updateToShipped, payload);
    const { data } = response;
    if (data.success) {
      // let result = yield selling.data.filter(item => item.id !== payload.id);
      let result_notification = {
        ...dashboard.selling,
        order_processed: dashboard.selling.order_processed - 1,
        order_shipped: dashboard.selling.order_shipped + 1,
      };
      yield delay(1000);
      // yield put(actions.getOrdersSuccess(selling.data));
      // yield put(actions.getOrdersSuccess(result));
      yield put(actions.setCountOrders(selling.count - 1));
      yield put(
        actionsDashboard.getNotificationSellingSuccess(result_notification)
      );
      yield put(actions.orderUpdated(true));
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.orderUpdated(false));
  } finally {
    yield put(actions.orderUpdated(false));
    yield put(actions.setIsLoadingUpdateOrder(false));
  }
}
function* watchUpdateArrivedOrder(value) {
  const { payload } = value;
  const { selling, dashboard } = yield select();
  yield put(actions.setIsLoadingUpdateOrder(true));
  try {
    const response = yield call(Api.selling.updateToArrived, payload);
    const { data } = response;
    if (data.success) {
      // let result = yield selling.data.filter(item => item.id !== payload.id);
      let result_notification = {
        ...dashboard.selling,
        order_shipped: dashboard.selling.order_shipped - 1,
        order_arrived: dashboard.selling.order_arrived
          ? dashboard.selling.order_arrived
          : 1,
      };
      yield delay(1000);
      // yield put(actions.getOrdersSuccess(selling.data));
      yield put(actions.setCountOrders(selling.count - 1));
      yield put(
        actionsDashboard.getNotificationSellingSuccess(result_notification)
      );
      yield put(actions.orderUpdated(true));
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.orderUpdated(false));
  } finally {
    yield put(actions.orderUpdated(false));
    yield put(actions.setIsLoadingUpdateOrder(false));
  }
}

const saga = [
  takeLatest(types.GET_ALL_ORDERS, watchGetAllOrders),
  takeLatest(types.GET_NEW_ORDERS, watchGetNewOrders),
  takeLatest(types.GET_CANCELED_ORDERS, watchGetCancelOrders),
  takeLatest(types.GET_PROCESSED_ORDERS, watchGetProcessOrders),
  takeLatest(types.GET_PICKUP_ORDERS, watchGetPickupOrders),
  takeLatest(types.GET_SHIPPING_ORDERS, watchGetShippedOrders),
  takeLatest(types.GET_ARRIVED_ORDERS, watchGetArrivedOrders),
  takeLatest(types.GET_DONE_ORDERS, watchGetDoneOrders),
  takeLatest(types.GET_IN_COMPLAINT_ORDERS, watchGetComplaintOrders),
  takeLatest(types.GET_ORDER, watchGetOneOrder),

  takeLatest(types.UPDATE_ORDER, watchGetAllOrders),
  takeLatest(types.UPDATE_ACCEPT_TO_PROCESSED_ORDER, watchUpdateAcceptOrder),
  takeLatest(types.UPDATE_CANCEL_TO_CANCELLED_ORDER, watchUpdateCancelOrder),

  takeLatest(
    types.UPDATE_MULTIPLE_ACCEPT_ORDERS,
    watchUpdateMultipleAcceptOrders
  ),

  takeLatest(types.UPDATE_LABEL_PRINTED_ORDER, watchUpdateLabelPrintedOrder),
  takeLatest(types.UPDATE_AWB_PRINTED_ORDER, watchUpdateReceiptPrintedOrder),
  //Plasgos x Kiriminaja
  takeLatest(types.ADD_ORDER_PACKAGE, watchAddOrderPackage),
  takeLatest(types.UPDATE_SHIPPED_ORDER, watchUpdateShippingOrder),
  takeLatest(types.UPDATE_ARRIVED_ORDER, watchUpdateArrivedOrder),
];

export default saga;
