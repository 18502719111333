import types from "./types";

const initialState = {
  notification: {
    data: 0,
    loading: false,
  },
  data: [],
  count: 0,
  added: false,
  deleted: false,
  loading_add: false,
  loading_delete: false,
  comment: {
    added: false,
    deleted: false,
    loading_add: false,
    loading_delete: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NOTIFICATION_DISCUSSIONS_SUCCESS:
      return {
        ...state,
        notification: {
          ...state.notification,
          data: action.payload,
        },
      };
    case types.IS_LOADING_GET_NOTIFICATION_DISCUSSIONS:
      return {
        ...state,
        notification: {
          ...state.notification,
          loading: action.payload,
        },
      };
    case types.RESET_GET_NOTIFICATION_DISCUSSIONS:
      return {
        ...state,
        notification: initialState.notification,
      };
    case types.GET_DISCUSSIONS_SUCCESS:
      return {
        ...state,
        count: action.payload.count,
        data: action.payload.data,
      };
    case types.IS_LOADING_GET_DISCUSSIONS:
      return {
        ...state,
        loading_get: action.payload,
      };
    case types.RESET_GET_DISCUSSIONS:
      return initialState;
    case types.ADD_DISCUSSION_SUCCESS:
      return {
        ...state,
        data: [action.payload, ...state.data],
      };
    case types.IS_LOADING_ADD_DISCUSSION:
      return {
        ...state,
        loading_add: action.payload,
      };
    case types.SET_ADDED_DISCUSSION:
      return {
        ...state,
        added: action.payload,
      };
    case types.DELETE_DISCUSSION_SUCCESS:
      return {
        ...state,
        count: action.payload.count - 1,
        data: state.data.filter((item) => item.id !== action.payload.id),
      };
    case types.IS_LOADING_DELETE_DISCUSSION:
      return {
        ...state,
        loading_delete: action.payload,
      };

    case types.SET_DELETED_DISCUSSION:
      return {
        ...state,
        deleted: action.payload,
      };
    case types.ADD_COMMENT_DISCUSSION_SUCCESS:
      return {
        ...state,
        data: state.data.map((item) =>
          item.id === action.payload.discussion_id
            ? {
                ...item,
                Comments: [...item.Comments.concat(action.payload)],
              }
            : item
        ),
      };
    case types.IS_LOADING_ADD_COMMENT_DISCUSSION:
      return {
        ...state,
        comment: {
          ...state.comment,
          loading_add: action.payload,
        },
      };
    case types.DELETE_COMMENT_DISCUSSION_SUCCESS:
      return {
        ...state,
        data: state.data.map((item) =>
          item.id === action.payload.discussion_id
            ? {
                ...item,
                Comments: item.Comments.filter(
                  (comment) => comment.id !== action.payload.id
                ),
              }
            : item
        ),
      };
    case types.IS_LOADING_DELETE_COMMENT_DISCUSSION:
      return {
        ...state,
        comment: {
          ...state.comment,
          loading_delete: action.payload,
        },
      };
    case types.SET_ADDED_COMMENT_DISCUSSION:
      return {
        ...state,
        comment: {
          ...state.comment,
          added: action.payload,
        },
      };
    case types.SET_DELETED_COMMENT_DISCUSSION:
      return {
        ...state,
        comment: {
          ...state.comment,
          deleted: action.payload,
        },
      };
    default:
      return state;
  }
};

export const getNotificationDiscussions = (payload) => ({
  type: types.GET_NOTIFICATION_DISCUSSIONS,
  payload,
});
export const getNotificationDiscussionsSuccess = (payload) => ({
  type: types.GET_NOTIFICATION_DISCUSSIONS_SUCCESS,
  payload,
});
export const setIsLoadingGetNotificationDiscussions = (payload) => ({
  type: types.IS_LOADING_GET_NOTIFICATION_DISCUSSIONS,
  payload,
});
export const resetGetNotificationDiscussions = (payload) => ({
  type: types.RESET_GET_NOTIFICATION_DISCUSSIONS,
  payload,
});

export const getProductDiscussions = (payload) => ({
  type: types.GET_PRODUCT_DISCUSSIONS,
  payload,
});
export const getDiscussions = (payload) => ({
  type: types.GET_DISCUSSIONS,
  payload,
});
export const getDiscussionsSuccess = (payload) => ({
  type: types.GET_DISCUSSIONS_SUCCESS,
  payload,
});
export const setIsLoadingGetDiscussions = (payload) => ({
  type: types.IS_LOADING_GET_DISCUSSIONS,
  payload,
});
export const resetGetDiscussions = (payload) => ({
  type: types.RESET_GET_DISCUSSIONS,
  payload,
});

export const addDiscussion = (payload) => ({
  type: types.ADD_DISCUSSION,
  payload,
});
export const addDiscussionSuccess = (payload) => ({
  type: types.ADD_DISCUSSION_SUCCESS,
  payload,
});
export const setIsLoadingAddDiscussion = (payload) => ({
  type: types.IS_LOADING_ADD_DISCUSSION,
  payload,
});
export const resetAddDiscussion = (payload) => ({
  type: types.RESET_ADD_DISCUSSION,
  payload,
});
export const setAddedDiscussion = (payload) => ({
  type: types.SET_ADDED_DISCUSSION,
  payload,
});
export const deleteDiscussion = (payload) => ({
  type: types.DELETE_DISCUSSION,
  payload,
});
export const deleteDiscussionSuccess = (payload) => ({
  type: types.DELETE_DISCUSSION_SUCCESS,
  payload,
});
export const setIsLoadingDeleteDiscussion = (payload) => ({
  type: types.IS_LOADING_DELETE_DISCUSSION,
  payload,
});
export const resetDeleteDiscussion = (payload) => ({
  type: types.RESET_DELETE_DISCUSSION,
  payload,
});

export const setDeletedDiscussion = (payload) => ({
  type: types.SET_DELETED_DISCUSSION,
  payload,
});

export const addComment = (payload) => ({
  type: types.ADD_COMMENT_DISCUSSION,
  payload,
});
export const addCommentSuccess = (payload) => ({
  type: types.ADD_COMMENT_DISCUSSION_SUCCESS,
  payload,
});
export const setIsLoadingAddComment = (payload) => ({
  type: types.IS_LOADING_ADD_COMMENT_DISCUSSION,
  payload,
});
export const resetAddComment = (payload) => ({
  type: types.RESET_ADD_COMMENT_DISCUSSION,
  payload,
});

export const deleteComment = (payload) => ({
  type: types.DELETE_COMMENT_DISCUSSION,
  payload,
});
export const deleteCommentSuccess = (payload) => ({
  type: types.DELETE_COMMENT_DISCUSSION_SUCCESS,
  payload,
});
export const setIsLoadingDeleteComment = (payload) => ({
  type: types.IS_LOADING_DELETE_COMMENT_DISCUSSION,
  payload,
});
export const resetDeleteComment = (payload) => ({
  type: types.RESET_DELETE_COMMENT_DISCUSSION,
  payload,
});
export const setAddedCommentDiscussion = (payload) => ({
  type: types.SET_ADDED_COMMENT_DISCUSSION,
  payload,
});
export const setDeletedCommentDiscussion = (payload) => ({
  type: types.SET_DELETED_COMMENT_DISCUSSION,
  payload,
});
