export default {
  //NEW
  GET_NOTIFICATION_CHATS: "get-notification-chats",
  GET_NOTIFICATION_CHATS_SUCCESS: "get-notification-chats-success",
  IS_LOADING_GET_NOTIFICATION_CHATS: "is-loading-get-notification-chats",
  RESET_GET_NOTIFICATION_CHATS: "reset-get-notification-chats",

  GET_CHATS: "get-chats",
  GET_CHATS_SUCCESS: "get-chats-success",
  IS_LOADING_GET_CHATS: "is-loading-get-chats",
  RESET_GET_CHATS: "reset-get-chats",

  GET_MESSAGES_BY_ID: "get-messages-by-id",
  GET_MESSAGES_BY_BUYER: "get-messages-by-buyer",
  GET_MESSAGES_SUCCESS: "get-messages-success",
  IS_LOADING_GET_MESSAGES: "is-loading-get-messages",
  RESET_GET_MESSAGES: "reset-get-messages",

  SEND_MESSAGE: "send-message",
  SEND_MESSAGE_SUCCESS: "send-message-success",
  IS_LOADING_SEND_MESSAGE: "is-loading-send-message",
  RESET_SEND_MESSAGE: "reset-send-message",

  UPDATE_MESSAGE: "update-message",
  UPDATE_MESSAGE_SUCCESS: "update-message-success",
  IS_LOADING_UPDATE_MESSAGE: "is-loading-update-message",
  RESET_UPDATE_MESSAGE: "reset-update-message",

  UPDATE_READ_MESSAGE: "update-read-message",
  UPDATE_READ_MESSAGE_SUCCESS: "update-read-message-success",
  IS_LOADING_UPDATE_READ_MESSAGE: "is-loading-update-read-message",
  RESET_UPDATE_READ_MESSAGE: "reset-update-read-message",

  SET_SENDING_SEND_MESSAGE: "set-sending-send-message",
  SET_ORDER: "set-order-chat",
  SET_PRODUCT: "set-product-chat",
  SET_STORE_CHAT: "set-store-chat",
  SET_USER_CHAT: "set-user-chat",
  SET_SHOW_CHATBOX: "set-show-chatbox",
};
