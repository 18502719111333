import { Api } from './api';

export default {
  notificationSelling: payload => Api.get('/v2/orders/seller/notification',{
    headers: {
      token: payload.token
    }
  }),
  notificationChat: payload => Api.get(`/v2/notifications/seller/chat`,{
    headers: {
      token: payload.token
    }
  }),
};
