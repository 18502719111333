import {put, call, takeLatest, select, delay} from 'redux-saga/effects';
import Api from '../../services';
import types from './constant';
import * as actions from './reducer';

function* watchGetDocument(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingDocument(true));
  try{
    const response = yield call(Api.document.get, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDocumentSuccess(data.data));
    }
  }catch(e){
    yield put(actions.setIsLoadingDocument(false));
  }finally{
    yield put(actions.setIsLoadingDocument(false));
  }
}

function* watchAddDocument(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingDocument(true));
  try{
    const response = yield call(Api.document.add, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.uploadKTP(payload))
    }
  }catch(e){
    yield put(actions.setIsLoadingDocument(false));
  }finally{
    yield put(actions.setIsLoadingDocument(false));
  }
}

function* watchUploadKTPDocument(value) {
  const { document } = yield select();
  const { payload } = value;
  yield put(actions.setIsLoadingUpload(true));
  try{
    const formData = new FormData();
    formData.append(`no_ktp`, payload.no_ktp);
    formData.append(`ktp`, payload.ktp);
    formData.append(`self_photo`, payload.self_photo);
    const response = yield call(Api.document.uploadKTP, {data:formData, token:payload.token});
    const { data } = response;
    if (data.success) {
      let result = {
        ...document.data,
        ...data.data
      }
      yield put(actions.getDocumentSuccess(result));
      yield put(actions.setSuccess(true));
      yield put(actions.setMessage(data.message));
    }
  }catch(e){
    yield put(actions.setIsLoadingUpload(false));
  }finally{
    yield put(actions.setIsLoadingUpload(false));
    yield delay(500)
    yield put(actions.setSuccess(false));
    yield put(actions.setMessage(''));
  }
}
function* watchUploadNPWPDocument(value) {
  const { document } = yield select();
  const { payload } = value;
  yield put(actions.setIsLoadingUpload(true));
  try{
    const formData = new FormData();
    formData.append(`npwp`, payload.npwp);
    const response = yield call(Api.document.uploadNPWP, {data:formData, token:payload.token});
    const { data } = response;
    if (data.success) {
      let result = {
        ...document.data,
        ...data.data
      }
      yield put(actions.getDocumentSuccess(result));
      yield put(actions.setSuccess(true));
      yield put(actions.setMessage(data.message));
    }
  }catch(e){
    yield put(actions.setIsLoadingUpload(false));
  }finally{
    yield put(actions.setIsLoadingUpload(false));
    yield delay(500)
    yield put(actions.setSuccess(false));
    yield put(actions.setMessage(''));
  }
}
function* watchUploadAKTADocument(value) {
  const { document } = yield select();
  const { payload } = value;
  yield put(actions.setIsLoadingUpload(true));
  try{
    const formData = new FormData();
    formData.append(`akta`, payload.akta);
    const response = yield call(Api.document.uploadAKTA, {data:formData, token:payload.token});
    const { data } = response;
    if (data.success) {
      let result = {
        ...document.data,
        ...data.data
      }
      yield put(actions.getDocumentSuccess(result));
      yield put(actions.setSuccess(true));
      yield put(actions.setMessage(data.message));
    }
  }catch(e){
    yield put(actions.setIsLoadingUpload(false));
  }finally{
    yield put(actions.setIsLoadingUpload(false));
    yield delay(500)
    yield put(actions.setSuccess(false));
    yield put(actions.setMessage(''));
  }
}
function* watchUploadSIUPDocument(value) {
  const { document } = yield select();
  const { payload } = value;
  yield put(actions.setIsLoadingUpload(true));
  try{
    const formData = new FormData();
    formData.append(`siup`, payload.siup);
    const response = yield call(Api.document.uploadSIUP, {data:formData, token:payload.token});
    const { data } = response;
    if (data.success) {
      let result = {
        ...document.data,
        ...data.data
      }
      yield put(actions.getDocumentSuccess(result));
      yield put(actions.setSuccess(true));
      yield put(actions.setMessage(data.message));
    }
  }catch(e){
    yield put(actions.setIsLoadingUpload(false));
  }finally{
    yield put(actions.setIsLoadingUpload(false));
    yield delay(500)
    yield put(actions.setSuccess(false));
    yield put(actions.setMessage(''));
  }
}
function* watchUploadSKDPDocument(value) {
  const { document } = yield select();
  const { payload } = value;
  yield put(actions.setIsLoadingUpload(true));
  try{
    const formData = new FormData();
    formData.append(`skdp`, payload.skdp);
    const response = yield call(Api.document.uploadSKDP, {data:formData, token:payload.token});
    const { data } = response;
    if (data.success) {
      let result = {
        ...document.data,
        ...data.data
      }
      yield put(actions.getDocumentSuccess(result));
      yield put(actions.setSuccess(true));
      yield put(actions.setMessage(data.message));
    }
  }catch(e){
    yield put(actions.setIsLoadingUpload(false));
  }finally{
    yield put(actions.setIsLoadingUpload(false));
    yield delay(500)
    yield put(actions.setSuccess(false));
    yield put(actions.setMessage(''));
  }
}
function* watchUploadTDPDocument(value) {
  const { document } = yield select();
  const { payload } = value;
  yield put(actions.setIsLoadingUpload(true));
  try{
    const formData = new FormData();
    formData.append(`tdp`, payload.tdp);
    const response = yield call(Api.document.uploadTDP, {data:formData, token:payload.token});
    const { data } = response;
    if (data.success) {
      let result = {
        ...document.data,
        ...data.data
      }
      yield put(actions.getDocumentSuccess(result));
      yield put(actions.setSuccess(true));
      yield put(actions.setMessage(data.message));
    }
  }catch(e){
    yield put(actions.setIsLoadingUpload(false));
  }finally{
    yield put(actions.setIsLoadingUpload(false));
    yield delay(500)
    yield put(actions.setSuccess(false));
    yield put(actions.setMessage(''));
  }
}
const sagas = [
  takeLatest(types.GET_DOCUMENT, watchGetDocument),
  takeLatest(types.ADD_DOCUMENT, watchAddDocument),
  takeLatest(types.UPLOAD_KTP, watchUploadKTPDocument),
  takeLatest(types.UPLOAD_NPWP, watchUploadNPWPDocument),
  takeLatest(types.UPLOAD_AKTA, watchUploadAKTADocument),
  takeLatest(types.UPLOAD_SIUP, watchUploadSIUPDocument),
  takeLatest(types.UPLOAD_SKDP, watchUploadSKDPDocument),
  takeLatest(types.UPLOAD_TDP, watchUploadTDPDocument),
];

export default sagas;
