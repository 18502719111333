import { Api } from './api';

export default {
  getFBPixel: payload => Api.get('/v2/apps/fb-pixel',{
    headers: {
      token: payload.token
    }
  }),
  addFBPixel: payload => Api.post('/v2/apps/fb-pixel', payload.data, {
    headers: {
      token: payload.token
    }
  }),
  updateFBPixel: payload => Api.put(`/v2/apps/fb-pixel/${payload.id}`, payload.data, {
    headers: {
      token: payload.token
    }
  }),
  updateStoreFBPixel: payload => Api.put(`/v2/apps/fb-pixel/store/${payload.id}`, payload.data, {
    headers: {
      token: payload.token
    }
  }),
  updateProductFBPixel: payload => Api.put(`/v2/apps/fb-pixel/product/update/${payload.id}`, payload.data, {
    headers: {
      token: payload.token
    }
  }),
  removeProductFBPixel: payload => Api.put(`/v2/apps/fb-pixel/product/remove/${payload.id}`, payload.data, {
    headers: {
      token: payload.token
    }
  }),
  deleteFBPixel: payload => Api.delete(`/v2/apps/fb-pixel/${payload.id}`, {
    headers: {
      token: payload.token
    }
  }),
};
