import types from './types';

const initialState = {
  coin:0,
  loading:false
};

export default (state = initialState, action) => {
  switch(action.type){
    case types.GET_COIN_BALANCE_SUCCESS:
      return { ...state, coin: action.payload}
    case types.IS_LOADING_GET_COIN_BALANCE:
      return { ...state, loading: action.payload}
    default:
      return state
  }
}


export const getCoin = payload => ({
  type: types.GET_COIN_BALANCE,
  payload
})
export const getCoinSuccess = payload => ({
  type: types.GET_COIN_BALANCE_SUCCESS,
  payload
})
export const setLoading = payload => ({
  type: types.IS_LOADING_GET_COIN_BALANCE,
  payload
})
