export default {
  GET_DETAIL_PROFILE: 'get-detail-profile',
  GET_DETAIL_PROFILE_SUCCESS: 'get-detail-profile-success',
  IS_LOADING_GET_DETAIL_PROFILE: 'is-loading-get-detail-profile',
  //UPDATE PROFILE
  UPDATE_PROFILE: 'update-profile',
  UPDATE_PROFILE_SUCCESS: 'update-profile-success',
  IS_LOADING_UPDATE_PROFILE: 'is-loading-update-profile',
  //UPDATE AVATAR
  UPDATE_AVATAR: 'update-avatar',
  UPDATE_AVATAR_SUCCESS: 'update-avatar-success',
  IS_LOADING_UPDATE_AVATAR: 'is-loading-update-avatar',

  GET_OTP_ACTIVATION_PHONE_NUMBER: 'get-otp-activation-phone-number',
  RESEND_GET_OTP_ACTIVATION_PHONE_NUMBER: 'resend-get-otp-activation-phone-number',
  GET_OTP_ACTIVATION_PHONE_NUMBER_SUCCESS: 'get-otp-activation-phone-number-success',
  IS_LOADING_GET_OTP_ACTIVATION_PHONE_NUMBER: 'is-loading-get-otp-activation-phone-number',

  CONFIRM_OTP_ACTIVATION_PHONE_NUMBER: 'confirm-otp-activation-phone-number',
  CONFIRM_OTP_ACTIVATION_PHONE_NUMBER_SUCCESS: 'confirm-otp-activation-phone-number-success',
  IS_LOADING_CONFIRM_OTP_ACTIVATION_PHONE_NUMBER: 'is-loading-confirm-otp-activation-phone-number',

  UPDATE_PHONE_NUMBER: 'update-phone-number',
  UPDATE_PHONE_NUMBER_SUCCESS: 'update-phone-number-success',
  IS_LOADING_UPDATE_PHONE_NUMBER: 'is-loading-update-phone-number',

  REQUEST_CHANGE_EMAIL: 'request-change-email',
  REQUEST_CHANGE_EMAIL_SUCCESS: 'request-change-email-success',
  IS_LOADING_REQUEST_CHANGE_EMAIL: 'is-loading-request-change-email',

  CHECK_PASSWORD: 'check-password',
  CHECK_PASSWORD_SUCCESS: 'check-password-success',
  IS_LOADING_CHECK_PASSWORD: 'is-loading-check-password',
  //RESPONSE SUCCESS
  RESPONSE_SUCCESS : 'response-success',
}
