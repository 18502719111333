import types from "./types";

const initialState = {
  data: [],
  isLoadingGet: false,
  isLoadingAdd: false,
  isLoadingUpdate: false,
  isLoadingDelete: false,
  added: false,
  updated: false,
  deleted: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WAREHOUSES_SUCCESS:
      return {
        ...state,
        data: action.payload.sort((x, y) =>
          x.is_default === y.is_default ? 0 : x.is_default ? -1 : 1
        ),
      };
    case types.IS_LOADING_GET_WAREHOUSES:
      return { ...state, isLoadingGet: action.payload };
    case types.ADD_WAREHOUSE_SUCCESS:
      return { ...state, data: [...state.data, action.payload] };
    case types.IS_LOADING_ADD_WAREHOUSE:
      return { ...state, isLoadingAdd: action.payload };
    case types.ADDED_WAREHOUSE:
      return { ...state, added: action.payload };
    case types.UPDATE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        data: state.data
          .map((item) =>
            item.id === action.payload.id
              ? {
                  ...action.payload.data,
                  id: action.payload.id,
                }
              : action.payload.data.is_default
              ? {
                  ...item,
                  is_default: false,
                }
              : item
          )
          .sort((x, y) =>
            x.is_default === y.is_default ? 0 : x.is_default ? -1 : 1
          ),
      };
    case types.IS_LOADING_UPDATE_WAREHOUSE:
      return { ...state, isLoadingUpdate: action.payload };
    case types.UPDATED_WAREHOUSE:
      return { ...state, updated: action.payload };
    case types.DELETE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload.id),
      };
    case types.IS_LOADING_DELETE_WAREHOUSE:
      return { ...state, isLoadingDelete: action.payload };
    case types.DELETED_WAREHOUSE:
      return { ...state, deleted: action.payload };
    default:
      return state;
  }
};

export const getWarehouses = (payload) => ({
  type: types.GET_WAREHOUSES,
  payload,
});
export const getWarehousesSuccess = (payload) => ({
  type: types.GET_WAREHOUSES_SUCCESS,
  payload,
});
export const setLoadingGetWarehouses = (payload) => ({
  type: types.IS_LOADING_GET_WAREHOUSES,
  payload,
});

export const addWarehouse = (payload) => ({
  type: types.ADD_WAREHOUSE,
  payload,
});
export const addWarehouseSuccess = (payload) => ({
  type: types.ADD_WAREHOUSE_SUCCESS,
  payload,
});
export const setLoadingAddWarehouse = (payload) => ({
  type: types.IS_LOADING_ADD_WAREHOUSE,
  payload,
});
export const setAddedWarehouse = (payload) => ({
  type: types.ADDED_WAREHOUSE,
  payload,
});

export const updateWarehouse = (payload) => ({
  type: types.UPDATE_WAREHOUSE,
  payload,
});
export const updateWarehouseSuccess = (payload) => ({
  type: types.UPDATE_WAREHOUSE_SUCCESS,
  payload,
});
export const setLoadingUpdateWarehouse = (payload) => ({
  type: types.IS_LOADING_UPDATE_WAREHOUSE,
  payload,
});
export const setUpdatedWarehouse = (payload) => ({
  type: types.UPDATED_WAREHOUSE,
  payload,
});

export const deleteWarehouse = (payload) => ({
  type: types.DELETE_WAREHOUSE,
  payload,
});
export const deleteWarehouseSuccess = (payload) => ({
  type: types.DELETE_WAREHOUSE_SUCCESS,
  payload,
});
export const setLoadingDeleteWarehouse = (payload) => ({
  type: types.IS_LOADING_DELETE_WAREHOUSE,
  payload,
});
export const setDeletedWarehouse = (payload) => ({
  type: types.DELETED_WAREHOUSE,
  payload,
});
