import React from "react";
import { CModal, CModalBody, CSpinner } from "@coreui/react";
// import ReactLoading from "react-loading";

const ModalLoading = ({ show }) => {
  return (
    <>
      <CModal
        centered
        closeOnBackdrop={false}
        show={show}
        style={{ background: "transparent", border: 0 }}
        className="fade"
        size="sm"
      >
        <CModalBody className="bg-transparent p-3 d-flex justify-content-center align-items-center">
          {/* <ReactLoading type="spin" color="#fa541c" width={35} height={35} /> */}
          <CSpinner color="primary" />
        </CModalBody>
      </CModal>
    </>
  );
};

export default ModalLoading;
