import { put, call, takeLatest, delay } from "redux-saga/effects";
import Api from "../../services";
import types from "./constant";
import * as actions from "./reducer";
import { toast } from "react-toastify";

function* watchGetProducts(values) {
  yield put(actions.setIsLoadingGetProducts(true));
  const { payload } = values;
  try {
    const response = yield call(Api.product.get, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getProductsSuccess(data.data));
      yield put(actions.setCountGetProducts(data.count));
    }
  } catch (error) {
    yield put(actions.getProductsSuccess([]));
    yield put(actions.setCountGetProducts(0));
  } finally {
    yield put(actions.setIsLoadingGetProducts(false));
  }
}
function* watchGetProductsNotification(values) {
  yield put(actions.setIsLoadingGetProductsNotification(true));
  const { payload } = values;
  try {
    const response = yield call(Api.product.getNotification, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getProductsNotificationSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.setIsLoadingGetProductsNotification(false));
  } finally {
    yield put(actions.setIsLoadingGetProductsNotification(false));
  }
}

function* watchGetProduct(values) {
  yield put(actions.setIsLoadingGetProducts(true));
  const { payload } = values;
  try {
    const response = yield call(Api.product.getOne, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getProductSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getProductSuccess({}));
    yield payload.history.replace("/");
  } finally {
    yield put(actions.setIsLoadingGetProducts(false));
  }
}

function* watchAddProduct(values) {
  yield put(actions.setIsLoadingAddProduct(true));
  const { payload } = values;
  try {
    const response = yield call(Api.product.add, payload);
    const { data } = response;
    if (data.success) {
      let { image_added } = payload.data;
      if (image_added && image_added.length > 0) {
        const formData = new FormData();
        image_added.forEach((item, idx) =>
          formData.append(`image_${idx}`, item)
        );
        yield call(Api.product.addImages, {
          data: formData,
          id: data.data.id,
          token: payload.token,
        });
      }
      yield delay(1000);
      yield toast(payload.m_success, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      yield put(actions.setSuccess(true));
    }
  } catch (error) {
    yield put(actions.addProductSuccess({}));
    yield delay(1000);
    yield toast.error(
      error.response.data ? error.response.data.message : payload.m_fail,
      {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      }
    );
  } finally {
    yield put(actions.setIsLoadingAddProduct(false));
    yield delay(500);
    yield put(actions.setSuccess(false));
  }
}

function* watchUpdateProduct(values) {
  yield put(actions.setIsLoadingUpdateProduct(true));
  const { payload } = values;
  try {
    const response = yield call(Api.product.update, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        ...payload.data,
        tab: payload.tab,
        updated: payload.updated,
      };
      let { image_added, image_default } = payload.data;
      if (image_added && image_added.length > 0) {
        const formData = new FormData();
        image_added.forEach((item, idx) =>
          formData.append(
            `image_${image_default && image_default === true ? idx : idx + 1}`,
            item
          )
        );
        const resimagesadded = yield call(Api.product.addImages, {
          data: formData,
          id: payload.id,
          token: payload.token,
        });
        result.image_added = resimagesadded.data.data;
      }
      yield put(actions.updateProductSuccess(result));
      yield put(actions.getProductsNotification({ token: payload.token }));
      yield delay(500);
      yield toast(payload.m_success, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      yield put(actions.setSuccess(true));
    }
  } catch (error) {
    yield delay(500);
    yield toast.error(payload.m_fail, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } finally {
    yield put(actions.setIsLoadingUpdateProduct(false));
    yield delay(500);
    yield put(actions.setSuccess(false));
  }
}

function* watchGetShowcases(values) {
  yield put(actions.setLoadingGetShowcases(true));
  const { payload } = values;
  try {
    const response = yield call(Api.product.getShowcases, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getShowcasesSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getShowcasesSuccess([]));
  } finally {
    yield put(actions.setLoadingGetShowcases(false));
  }
}

function* watchAddShowcase(values) {
  yield put(actions.setLoadingAddShowcase(true));
  const { payload } = values;
  try {
    const response = yield call(Api.product.addShowcase, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.addShowcaseSuccess(data));
    }
  } catch (error) {
    // yield put(actions.addShowcaseSuccess());
    yield toast.error(error.response.data.message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } finally {
    yield put(actions.setLoadingAddShowcase(false));
  }
}

function* watchGetCategories(values) {
  yield put(actions.setLoadingGetCategories(true));
  const { payload } = values;
  try {
    const response = yield call(Api.product.getCategories, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getCategoriesSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getCategoriesSuccess([]));
  } finally {
    yield put(actions.setLoadingGetCategories(false));
  }
}

function* watchUpdateMultipleStatus(values) {
  yield put(actions.setIsLoadingUpdateMultipleStatus(true));
  const { payload } = values;
  try {
    const response = yield call(Api.product.updateMultipleStatus, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        ...payload.data,
        updated: payload.updated,
      };
      yield put(actions.updateMultipleStatusSuccess(result));
      yield put(actions.getProductsNotification({ token: payload.token }));
      yield delay(500);
      yield toast(payload.m_success, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      yield put(actions.setSuccess(true));
    }
  } catch (error) {
    yield delay(500);
    yield toast.error(payload.m_fail, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } finally {
    yield put(actions.setIsLoadingUpdateMultipleStatus(false));
    yield delay(500);
    yield put(actions.setSuccess(false));
  }
}

const sagas = [
  takeLatest(types.GET_PRODUCTS, watchGetProducts),
  takeLatest(types.GET_PRODUCT, watchGetProduct),
  takeLatest(types.ADD_PRODUCT, watchAddProduct),
  takeLatest(types.UPDATE_PRODUCT, watchUpdateProduct),
  takeLatest(types.GET_SHOWCASES, watchGetShowcases),
  takeLatest(types.ADD_SHOWCASE, watchAddShowcase),
  takeLatest(types.GET_CATEGORIES, watchGetCategories),
  takeLatest(types.GET_PRODUCTS_NOTIFICATION, watchGetProductsNotification),
  takeLatest(types.UPDATE_MULTIPLE_STATUS_PRODUCT, watchUpdateMultipleStatus),
];

export default sagas;
