import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../services';
import types from './constant';
import * as actions from './reducer';

function* watchGetRFQ(values) {
  yield put(actions.setLoadingGetRFQ(true));
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.getRFQ, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.setCountGetRFQ(data.count));
      yield put(actions.getRFQSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getRFQSuccess([]));
  } finally {
    yield put(actions.setLoadingGetRFQ(false));
  }
}
function* watchGetRFQDetail(values) {
  yield put(actions.setLoadingGetRFQ(true));
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.getDetail, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getRFQDetailSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getRFQDetailSuccess({}));
  } finally {
    yield put(actions.setLoadingGetRFQ(false));
  }
}
function* watchCreateOfferRFQ(values) {
  yield put(actions.setIsLoadingCreateOfferRFQ(true));
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.createOffer, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.createOfferRFQSuccess(data));
      yield put(actions.setCreatedOfferRFQ(true));
    }
  } catch (error) {
    yield put(actions.createOfferRFQSuccess({}));
  } finally {
    yield put(actions.setCreatedOfferRFQ(false));
    yield put(actions.setIsLoadingCreateOfferRFQ(false));
  }
}
function* watchDeleteOfferRFQ(values) {
  yield put(actions.setIsLoadingDeleteOfferRFQ(true));
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.deleteOffer, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.deleteOfferRFQSuccess(payload));
      yield put(actions.setDeletedOfferRFQ(true));
    }
  } catch (error) {
    // yield put(actions.deleteOfferRFQSuccess({}));
  } finally {
    yield put(actions.setDeletedOfferRFQ(false));
    yield put(actions.setIsLoadingDeleteOfferRFQ(false));
  }
}
function* watchUpdateOfferRFQ(values) {
  yield put(actions.setIsLoadingUpdateOfferRFQ(true));
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.updateOffer, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        id: payload.id,
        ...payload.data,
      }
      yield put(actions.updateOfferRFQSuccess(result));
      yield put(actions.setUpdatedOfferRFQ(true));
    }
  } catch (error) {
    // yield put(actions.deleteOfferRFQSuccess({}));
  } finally {
    yield put(actions.setUpdatedOfferRFQ(false));
    yield put(actions.setIsLoadingUpdateOfferRFQ(false));
  }
}

function* watchCreateCommentOfferRFQ(values) {
  yield put(actions.setIsLoadingCreateCommentOfferRFQ(true));
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.createComment, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.createCommentOfferRFQSuccess(data));
    }
  } catch (error) {
    // yield put(actions.createOfferRFQSuccess({}));
  } finally {
    yield put(actions.setIsLoadingCreateCommentOfferRFQ(false));
  }
}

function* watchGetMyRequest(values) {
  yield put(actions.setLoadingGetRFQ(true));
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.getRequest, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.setCountGetRFQ(data.count));
      yield put(actions.getMyRequestSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getMyRequestSuccess([]));
  } finally {
    yield put(actions.setLoadingGetRFQ(false));
  }
}
function* watchGetMyOffer(values) {
  yield put(actions.setLoadingGetRFQ(true));
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.getOffer, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.setCountGetRFQ(data.count));
      yield put(actions.getMyOfferSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getMyOfferSuccess([]));
  } finally {
    yield put(actions.setLoadingGetRFQ(false));
  }
}
const sagas = [
  takeLatest(types.GET_RFQ, watchGetRFQ),
  takeLatest(types.GET_MY_REQUEST, watchGetMyRequest),
  takeLatest(types.GET_MY_OFFER, watchGetMyOffer),
  takeLatest(types.GET_RFQ_DETAIL, watchGetRFQDetail),
  takeLatest(types.CREATE_OFFER_RFQ, watchCreateOfferRFQ),
  takeLatest(types.DELETE_OFFER_RFQ, watchDeleteOfferRFQ),
  takeLatest(types.UPDATE_OFFER_RFQ, watchUpdateOfferRFQ),
  takeLatest(types.CREATE_COMMENT_OFFER_RFQ, watchCreateCommentOfferRFQ),

];

export default sagas;
