import {put, call, takeLatest, delay} from 'redux-saga/effects';
import Api from '../../services';
import types from './constant';
import * as actions from './reducer';

function* watchGetShop(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingShop(true));
  try{
    const response = yield call(Api.store.getInfo, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getShopSuccess(data.data));
    }
  }catch(e){
    yield put(actions.setIsLoadingShop(false));
  }finally{
    yield put(actions.setIsLoadingShop(false));
  }
}

function* watchUpdateShop(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingUpdateShop(true));
  try{
    const response = yield call(Api.setting.updateInfo, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updateShopSuccess(payload.data));
      yield put(actions.updatedStore(true))
    }else{
      throw Error;
    }
  }catch(e){
    yield put(actions.setIsLoadingUpdateShop(false));
  }finally{
    yield put(actions.setIsLoadingUpdateShop(false));
    yield delay(5000);
    yield put(actions.updatedStore(false))
  }
}

function* watchUploadAvatar(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingUploadAvatar(true));
  try{
    const formData = new FormData();
    formData.append(`avatar_img`, payload.avatar_img);
    const response = yield call(Api.setting.uploadAvatar, {data:formData, token:payload.token});
    const { data } = response;
    if (data.success) {
      yield put(actions.updateShopSuccess({avatar_img: data.avatar_img}));
      yield put(actions.updatedStore(true))
    }else{
      throw Error;
    }
  }catch(e){
    yield put(actions.setIsLoadingUploadAvatar(false));
  }finally{
    yield put(actions.setIsLoadingUploadAvatar(false));
    yield delay(5000);
    yield put(actions.updatedStore(false))
  }
}
const sagas = [
  takeLatest(types.GET_DETAIL_STORE, watchGetShop),
  takeLatest(types.UPDATE_STORE, watchUpdateShop),
  takeLatest(types.UPLOAD_AVATAR_STORE, watchUploadAvatar)
];

export default sagas;
