import types from './constant';

const initialState = {
  detail: {
    data: {},
    loading: false
  },
  tracking:{
    show: false,
    detail: {}
  },
  package:{
    data:{},
    loading: false
  },
  data: [],
  count:0,
  updated: false,
  cost: {
    data: [],
    loading: false
  },
  isLoadingGetOrders: false,
  isLoadingUpdateOrder: false,
  isLoadingUpdateMultipleOrders: false,
  isLoadingUpdateIsPrintedOrder: false,
  message:'',
  chat: {
    show:false,
    detail:{}
  }
};

export default (state = initialState, action) => {
  switch(action.type){
    case types.GET_ORDERS_SUCCESS:
      return { ...state, data: action.payload}
    case types.SET_COUNT_ORDERS:
      return {...state, count: action.payload}
    case types.IS_LOADING_GET_ORDERS:
      return { ...state, isLoadingGetOrders: action.payload}
    case types.UPDATE_ORDER_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.map(item => item.id === action.payload.id ? {...item, ...action.payload} : item)
      })
    case types.IS_LOADING_UPDATE_ORDER:
      return {...state, isLoadingUpdateOrder: action.payload}
    case types.UPDATE_MULTIPLE_ORDERS_SUCCESS:
      return { ...state, data: action.payload }
    case types.IS_LOADING_UPDATE_MULTIPLE_ORDERS:
      return { ...state, isLoadingUpdateMultipleOrders: action.payload }
    case types.ORDER_UPDATED:
      return { ...state, updated: action.payload}
    case types.SET_MESSAGE_SELLING:
      return { ...state, message:action.payload}
    case types.SHOW_CHAT_SELLING:
      return {...state, chat:action.payload}
    case types.SHOW_TRACKING_SELLING:
      return {...state, tracking:action.payload}
    case types.UPDATE_LABEL_PRINTED_ORDER_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.map(item => action.payload.ids.includes(item.id) ? {
          ...item,
          label_printed: action.payload.label_printed
        } : item)
      })
    case types.UPDATE_AWB_PRINTED_ORDER_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.map(item => action.payload.ids.includes(item.id) ? {
          ...item,
          receipt_printed: action.payload.receipt_printed
        } : item)
      })

    case types.CHECK_COST_SINGLE_ORDER_SUCCESS:
      return {
        ...state,
        cost: {
          ...state.cost,
          data: action.payload
        }
      }
    case types.IS_LOADING_CHECK_COST_SINGLE_ORDER:
      return {
        ...state,
        cost: {
          ...state.cost,
          loading: action.payload
        }
      }
    case types.RESET_CHECK_COST_SINGLE_ORDER:
      return { ...state, cost: initialState.cost }
    case types.GET_ORDER_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          data: action.payload
        }
      }
    case types.IS_LOADING_GET_ORDER:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: action.payload
        }
      }
    case types.RESET_GET_ORDER:
      return { ...state, detail: initialState.detail }
    case types.ADD_ORDER_PACKAGE_SUCCESS:
      return {
        ...state,
        package: {
          ...state.package,
          data: action.payload
        }
      }
    case types.IS_LOADING_ADD_ORDER_PACKAGE:
      return {
        ...state,
        package: {
          ...state.package,
          loading: action.payload
        }
      }
    case types.RESET_ADD_ORDER_PACKAGE:
      return {...state, package: initialState.package}
    default:
      return state
  }
}

export const setShowTracking = payload => ({
  type: types.SHOW_TRACKING_SELLING,
  payload
})
export const showChat = payload => ({
  type: types.SHOW_CHAT_SELLING,
  payload
})

export const updateLabelPrinted = payload => ({
  type: types.UPDATE_LABEL_PRINTED_ORDER,
  payload
})
export const updateLabelPrintedSuccess = payload => ({
  type: types.UPDATE_LABEL_PRINTED_ORDER_SUCCESS,
  payload
})
export const isLoadingUpdateLabelPrinted = payload => ({
  type: types.IS_LOADING_UPDATE_LABEL_PRINTED_ORDER,
  payload
})

export const updateAWBPrinted = payload => ({
  type: types.UPDATE_AWB_PRINTED_ORDER,
  payload
})
export const updateAWBPrintedSuccess = payload => ({
  type: types.UPDATE_AWB_PRINTED_ORDER_SUCCESS,
  payload
})
export const isLoadingUpdateAWBPrinted = payload => ({
  type: types.IS_LOADING_UPDATE_AWB_PRINTED_ORDER,
  payload
})

export const getAllOrders = payload => ({
  type: types.GET_ALL_ORDERS,
  payload
})
export const getNewOrders = payload => ({
  type: types.GET_NEW_ORDERS,
  payload
})
export const getProcessedOrders = payload => ({
  type: types.GET_PROCESSED_ORDERS,
  payload
})
export const getProcessOfDeliveryOrders = payload => ({
  type: types.GET_SHIPPING_ORDERS,
  payload
})
export const getPickupOrders = payload => ({
  type: types.GET_PICKUP_ORDERS,
  payload
})
export const getArrivedOrders = payload => ({
  type: types.GET_ARRIVED_ORDERS,
  payload
})
export const getDoneOrders = payload => ({
  type: types.GET_DONE_ORDERS,
  payload
})
export const getComplaintOrders = payload => ({
  type: types.GET_IN_COMPLAINT_ORDERS,
  payload
})
export const getCanceledOrders = payload => ({
  type: types.GET_CANCELED_ORDERS,
  payload
})
export const getOrdersSuccess = payload => ({
  type: types.GET_ORDERS_SUCCESS,
  payload
})
export const setCountOrders = payload => ({
  type: types.SET_COUNT_ORDERS,
  payload
})
export const setIsLoadingGetOrders = payload => ({
  type: types.IS_LOADING_GET_ORDERS,
  payload
})

export const updateOrder = payload => ({
  type: types.UPDATE_ORDER,
  payload
})
export const updateAcceptOrder = payload => ({
  type: types.UPDATE_ACCEPT_TO_PROCESSED_ORDER,
  payload
})
export const updateCancelOrder = payload => ({
  type: types.UPDATE_CANCEL_TO_CANCELLED_ORDER,
  payload
})
export const updateShippedOrder = payload => ({
  type: types.UPDATE_SHIPPED_ORDER,
  payload
})
export const updateArrivedOrder = payload => ({
  type: types.UPDATE_ARRIVED_ORDER,
  payload
})
export const updateDoneOrder = payload => ({
  type: types.UPDATE_DONE_ORDER,
  payload
})
export const updateOrderSuccess = payload => ({
  type: types.UPDATE_ORDER_SUCCESS,
  payload
})
export const setIsLoadingUpdateOrder = payload => ({
  type: types.IS_LOADING_UPDATE_ORDER,
  payload
})
//UPDATE MULTIPLE IDS
export const updateMultipleAcceptOrders = payload => ({
  type: types.UPDATE_MULTIPLE_ACCEPT_ORDERS,
  payload
})
export const updateMultipleAcceptOrdersSuccess = payload => ({
  type: types.UPDATE_MULTIPLE_ORDERS_SUCCESS,
  payload
})
export const setIsLoadingUpdateMultipleAcceptOrders = payload => ({
  type: types.IS_LOADING_UPDATE_MULTIPLE_ORDERS,
  payload
})

export const orderUpdated = payload => ({
  type: types.ORDER_UPDATED,
  payload
})
export const setMessage = payload => ({
  type: types.SET_MESSAGE_SELLING,
  payload
})

export const checkCostSingle = payload => ({
  type: types.CHECK_COST_SINGLE_ORDER,
  payload
})
export const checkCostSingleSuccess = payload => ({
  type: types.CHECK_COST_SINGLE_ORDER_SUCCESS,
  payload
})
export const setIsLoadingCostSingle = payload => ({
  type: types.IS_LOADING_CHECK_COST_SINGLE_ORDER,
  payload
})
export const resetCostSingle = payload => ({
  type: types.RESET_CHECK_COST_SINGLE_ORDER,
  payload
})

export const getOrder = payload => ({
    type: types.GET_ORDER,
    payload
  })
export const getOrderSuccess = payload => ({
  type: types.GET_ORDER_SUCCESS,
  payload
})
export const setIsLoadingGetOrder = payload => ({
  type: types.IS_LOADING_GET_ORDER,
  payload
})
export const resetGetOrder = payload => ({
  type: types.RESET_GET_ORDER,
  payload
})

export const addOrderPackage = payload => ({
  type: types.ADD_ORDER_PACKAGE,
  payload
})
export const addOrderPackageSuccess = payload => ({
  type: types.ADD_ORDER_PACKAGE_SUCCESS,
  payload
})
export const setIsLoadingAddOrderPackage = payload => ({
  type: types.IS_LOADING_ADD_ORDER_PACKAGE,
  payload
})
export const resetAddOrderPackage = payload => ({
  type: types.RESET_ADD_ORDER_PACKAGE,
  payload
})
