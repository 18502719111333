import types from './types';

const initialState = {
  subdistricts:[],
  loading:false
};

export default (state = initialState, action) => {
  switch(action.type){
    case types.GET_DISTRICT_AREAS_SUCCESS:
      return { ...state, subdistricts: action.payload}
    case types.IS_LOADING_GET_AREAS:
      return { ...state, loading: action.payload}
    default:
      return state
  }
}


export const getDistrictAreas = payload => ({
  type: types.GET_DISTRICT_AREAS,
  payload
})
export const getDistrictAreasSuccess = payload => ({
  type: types.GET_DISTRICT_AREAS_SUCCESS,
  payload
})
export const setLoading = payload => ({
  type: types.IS_LOADING_GET_AREAS,
  payload
})
