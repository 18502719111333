import types from './constant';

const initialState = {
  data: {},
  data_web: {},
  create:{},
  isLoadingGet: false,
  isLoadingCreate: false,
  success: false
};

export default (state = initialState, action) => {
  switch(action.type){
    case types.GET_DECORATION_MOBILE_SUCCESS:
      return { ...state, data: action.payload}
    case types.GET_DECORATION_WEB_SUCCESS:
      return { ...state, data_web: action.payload}
    case types.IS_LOADING_GET_DECORATION:
      return { ...state, isLoadingGet: action.payload}
    case types.CREATE_DECORATION_SUCCESS:
      return { ...state, create: action.payload}
    case types.IS_LOADING_CREATE_DECORATION:
      return { ...state, isLoadingCreate: action.payload}
    case types.SET_DECORATION_SUCCESS:
      return { ...state, success: action.payload}
    default:
      return state
  }
}


export const getDecorationMobile = payload => ({
  type: types.GET_DECORATION_MOBILE,
  payload
})
export const getDecorationMobileSuccess = payload => ({
  type: types.GET_DECORATION_MOBILE_SUCCESS,
  payload
})
export const getDecorationWeb = payload => ({
  type: types.GET_DECORATION_WEB,
  payload
})
export const getDecorationWebSuccess = payload => ({
  type: types.GET_DECORATION_WEB_SUCCESS,
  payload
})
export const setIsLoadingGetDecoration = payload => ({
  type: types.IS_LOADING_GET_DECORATION,
  payload
})

export const createDecorationMobile = payload => ({
  type: types.CREATE_DECORATION_MOBILE,
  payload
})
export const createDecorationWeb = payload => ({
  type: types.CREATE_DECORATION_WEB,
  payload
})
export const createDecorationSuccess = payload => ({
  type: types.CREATE_DECORATION_SUCCESS,
  payload
})
export const setIsLoadingCreateDecoration = payload => ({
  type: types.IS_LOADING_CREATE_DECORATION,
  payload
})

export const setDecorationSuccess = payload => ({
  type: types.SET_DECORATION_SUCCESS,
  payload
})
