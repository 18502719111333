import { all } from "redux-saga/effects";
import loginSaga from "./components/login/saga";
import shippingSaga from "./components/shipping/saga";
import settingSaga from "./components/settings/saga";
import sellingSaga from "./components/selling/saga";
import depositSaga from "./components/deposit/saga";
import productSaga from "./components/product/saga";
import showcaseSaga from "./components/showcase/saga";
import reviewSaga from "./components/review/saga";
import documentSaga from "./components/document/saga";
import decorationSaga from "./components/decoration/saga";
import complaintSaga from "./components/complaint/saga";
import rfqSaga from "./components/rfq/saga";
import profileSaga from "./components/profile/saga";

import appsSaga from "./modules/apps/saga";
import chatSaga from "./modules/chat/saga";
import coinSaga from "./modules/coin/saga";
import coverageAreaSaga from "./modules/coveragearea/saga";
import dashboardSaga from "./modules/dashboard/saga";
import bankSaga from "./modules/bank/saga";
import packageSaga from "./modules/package/saga";
import paymentSaga from "./modules/payment/saga";
import warehouseSaga from "./modules/warehouse/saga";
import discussionSaga from "./modules/discussion/saga";
import landingPageSaga from "./modules/landingpage/saga";

export default function* rootSaga() {
  yield all([
    all(loginSaga),
    all(dashboardSaga),
    all(shippingSaga),
    all(settingSaga),
    all(sellingSaga),
    all(chatSaga),
    all(depositSaga),
    all(productSaga),
    all(showcaseSaga),
    all(reviewSaga),
    all(documentSaga),
    all(discussionSaga),
    all(decorationSaga),
    all(complaintSaga),
    all(rfqSaga),
    all(coinSaga),
    all(bankSaga),
    all(profileSaga),
    all(appsSaga),
    all(landingPageSaga),
    all(warehouseSaga),
    all(coverageAreaSaga),
    all(packageSaga),
    all(paymentSaga),
  ]);
}
