import {put, call, takeLatest} from 'redux-saga/effects';
import Api from '../../services';
import types from './types';
import * as actions from './reducer';


function* watchGetNotificationSelling(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingNotificationSelling(true));
  try{
    const response = yield call(Api.dashboard.notificationSelling, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getNotificationSellingSuccess(data.data));
    }else{
      throw Error;
    }
  }catch(e){
    yield put(actions.setIsLoadingNotificationSelling(false));
  }finally{
    yield put(actions.setIsLoadingNotificationSelling(false));
  }
}

function* watchGetNotificationChat(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingNotificationChat(true));
  try{
    const response = yield call(Api.dashboard.notificationChat, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getNotificationChatSuccess(data.data));
    }else{
      throw Error;
    }
  }catch(e){
    yield put(actions.setIsLoadingNotificationChat(false));
  }finally{
    yield put(actions.setIsLoadingNotificationChat(false));
  }
}

const sagas = [
  takeLatest(types.GET_NOTIFICATION_SELLING, watchGetNotificationSelling),
  takeLatest(types.GET_NOTIFICATION_CHAT, watchGetNotificationChat),
];

export default sagas;
