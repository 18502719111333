import types from './constant';

const initialState = {
  saldo:0,
  loading:false
};

export default (state = initialState, action) => {
  switch(action.type){
    case types.GET_DEPOSIT_SUCCESS:
      return { ...state, saldo: action.payload}
    case types.IS_LOADING_GET_DEPOSIT:
      return { ...state, loading: action.payload}
    default:
      return state
  }
}


export const getDeposit = payload => ({
  type: types.GET_DEPOSIT,
  payload
})
export const getDepositSuccess = payload => ({
  type: types.GET_DEPOSIT_SUCCESS,
  payload
})
export const setLoading = payload => ({
  type: types.IS_LOADING_GET_DEPOSIT,
  payload
})
