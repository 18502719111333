export default {
  GET_DETAIL_STORE : 'get-detail-store',
  GET_DETAIL_STORE_SUCCESS : 'get-detail-store-success',
  IS_LOADING_GET_DETAIL_STORE : 'set-is-loading-get-detail-store',

  UPDATE_STORE : 'update-store',
  UPDATE_STORE_SUCCESS : 'update-store-success',
  IS_LOADING_UPDATE_STORE :'is-loading-update-store',

  UPLOAD_AVATAR_STORE : 'upload-avatar-store',
  IS_LOADING_UPLOAD_AVATAR_STORE : 'is-loading-upload-avatar-store',

  UPDATED_STORE : 'updated-store',
};
