import { apiUrl } from './api';
import axios from 'axios';

export default {
  upload: {
    proof: payload => {
      const formData = new FormData();
      formData.append('image', payload.data);
      return axios.put(`${apiUrl}/v2/payments/buyer/upload/proof-payment/${payload.payment_id}`, formData, {
        headers:{
          token: payload.token,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => response)
      .catch((error) => {throw Error(error)});
    }
  }
}
