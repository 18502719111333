import { put, call, takeLatest, delay } from "redux-saga/effects";
import Api from "../../services";
import types from "./constant";
import * as actions from "./reducer";
import { toast } from "react-toastify";

function* watchGetShowcases(values) {
  yield put(actions.setLoadingGetShowcases(true));
  const { payload } = values;
  try {
    const response = yield call(Api.showcase.get, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getShowcasesSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getShowcasesSuccess([]));
  } finally {
    yield put(actions.setLoadingGetShowcases(false));
  }
}

function* watchAddShowcase(values) {
  yield put(actions.setLoadingAddShowcase(true));
  const { payload } = values;
  try {
    const response = yield call(Api.showcase.add, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.addShowcaseSuccess(data));
      yield put(actions.setSuccess(true));
      yield delay(500);
      yield toast(data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  } catch (error) {
    yield toast.error(error.response.data.message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } finally {
    yield put(actions.setLoadingAddShowcase(false));
    yield put(actions.setSuccess(false));
  }
}

function* watchUpdateShowcase(values) {
  yield put(actions.setIsLoadingUpdateShowcase(true));
  const { payload } = values;
  try {
    const response = yield call(Api.showcase.update, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updateShowcaseSuccess(payload));
      yield put(actions.setSuccess(true));
      yield delay(500);
      yield toast(data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  } catch (error) {
    yield toast.error(error.response.data.message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } finally {
    yield put(actions.setIsLoadingUpdateShowcase(false));
    yield put(actions.setSuccess(false));
  }
}

function* watchDeleteShowcase(values) {
  yield put(actions.setIsLoadingDeleteShowcase(true));
  const { payload } = values;
  try {
    const response = yield call(Api.showcase.delete, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.deleteShowcaseSuccess(payload));
      yield put(actions.setSuccess(true));
      yield delay(500);
      yield toast(data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  } catch (error) {
    yield toast.error(error.response.data.message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } finally {
    yield put(actions.setIsLoadingDeleteShowcase(false));
    yield put(actions.setSuccess(false));
  }
}

const sagas = [
  takeLatest(types.GET_SHOWCASES, watchGetShowcases),
  takeLatest(types.ADD_SHOWCASE, watchAddShowcase),
  takeLatest(types.UPDATE_SHOWCASE, watchUpdateShowcase),
  takeLatest(types.DELETE_SHOWCASE, watchDeleteShowcase),
];

export default sagas;
