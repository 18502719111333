import dashboard from './dashboard';
import authentication from './authentication';
import shipping from './shipping';
import setting from './setting';
import selling from './selling';
import chat from './chat';
import deposit from './deposit';
import product from './product';
import showcase from './showcase';
import review from './review';
import discussion from './discussion';
import store from './store';
import document from './document';
import decoration from './decoration';
import complaint from './complaint';
import rfq from './rfq';
import coin from './coin';
import bank from './bank';
import user from './user';
import apps from './apps';
import landingpage from './landingpage';
import warehouse from './warehouse';
import coveragearea from './coveragearea';
import checkcost from './checkcost';
import packages from './package';
import payment from './payment';

export default {
  dashboard,
  authentication,
  shipping,
  setting,
  selling,
  chat,
  deposit,
  product,
  showcase,
  review,
  discussion,
  store,
  document,
  decoration,
  complaint,
  rfq,
  coin,
  bank,
  user,
  apps,
  landingpage,
  warehouse,
  coveragearea,
  checkcost,
  packages,
  payment
}
