export default {
  GET_SHIPPING : 'get-shipping',
  GET_SHIPPING_SUCCESS : 'get-shipping-success',
  IS_LOADING_GET_SHIPPING : 'set-is-loading-het-shipping',

  UPDATE_SHIPPING : 'update-shipping',
  UPDATE_SHIPPING_SUCCESS : 'update-shipping-success',
  IS_LOADING_UPDATE_SHIPPING : 'set-is-loading-update-shipping',
  UPDATED_SHIPPING : 'updated-shipping',

  GET_CITIES : 'get-cities',
  GET_CITIES_SUCCESS : 'get-cities-success',
  IS_LOADING_GET_CITIES : 'set-is-loading-get-cities',

  GET_SCHEDULES_PICKUP: 'get-schedules-pickup-shipping',
  GET_SCHEDULES_PICKUP_SUCCESS: 'get-schedules-pickup-shipping-success',
  IS_LOADING_GET_SCHEDULES_PICKUP: 'is-loading-get-schedules-pickup-shipping',
  RESET_SCHEDULES_PICKUP: 'reset-schedules-pickup-shipping',

  CHECK_TRACKING: 'check-tracking',
  CHECK_TRACKING_SUCCESS: 'check-tracking-success',
  IS_LOADING_CHECK_TRACKING: 'is-loading-check-tracking',
  RESET_CHECK_TRACKING: 'check-tracking-reset',

  CHECK_COSTS: 'check-costs-shipping',
  CHECK_COSTS_SUCCESS: 'check-costs-shipping-success',
  IS_LOADING_CHECK_COSTS: 'is-loading-check-costs-shipping',
  RESET_CHECK_COSTS: 'check-costs-shipping-reset',
};
