import types from './constant';

const initialState = {
  user: {},
  token: '',
  logged_in: false,
  loading: false,
  last_login: {},
  isLoadingLastLogin:false
};

export default (state = initialState, action) => {
  switch(action.type){
    case types.AUTHENTICATION_SUCCESS:
      return { ...state, user: action.payload.user, token: action.payload.token, logged_in: action.payload.logged_in}
    case types.IS_LOADING_AUTHENTICATION:
      return { ...state, loading: action.payload}
    case types.LAST_LOGIN_SUCCESS:
      return { ...state, last_login:action.payload }
    case types.IS_LOADING_LAST_LOGIN:
      return { ...state, isLoadingLastLogin:action.payload }
    default:
      return state
  }
}


export const getLastLogin = payload => ({
  type: types.LAST_LOGIN,
  payload
})
export const getLastLoginSuccess = payload => ({
  type: types.LAST_LOGIN_SUCCESS,
  payload
})
export const setIsLoadingLastLogin = payload => ({
  type: types.IS_LOADING_LAST_LOGIN,
  payload
})

export const getAuthentication = payload => ({
  type: types.AUTHENTICATION,
  payload
})
export const getAuthenticationSuccess = payload => ({
  type: types.AUTHENTICATION_SUCCESS,
  payload
})
export const setIsLoadingAuthentication = payload => ({
  type: types.IS_LOADING_AUTHENTICATION,
  payload
})
