import types from './types';

const initialState = {
  selling: {},
  chat: 0,
  isLoadingSelling: false,
  isLoadingChat: false,
};

export default (state = initialState, action) => {
  switch(action.type){
    case types.GET_NOTIFICATION_SELLING_SUCCESS:
      return { ...state, selling: action.payload}
    case types.IS_LOADING_GET_NOTIFICATION_SELLING:
      return { ...state, isLoadingSelling: action.payload}
    case types.GET_NOTIFICATION_CHAT_SUCCESS:
      return { ...state, chat: action.payload}
    case types.IS_LOADING_GET_NOTIFICATION_CHAT:
      return { ...state, isLoadingChat: action.payload}
    default:
      return state
  }
}

export const getNotificationSelling = payload => ({
  type: types.GET_NOTIFICATION_SELLING,
  payload
})
export const getNotificationSellingSuccess = payload => ({
  type: types.GET_NOTIFICATION_SELLING_SUCCESS,
  payload
})
export const setIsLoadingNotificationSelling = payload => ({
  type: types.IS_LOADING_GET_NOTIFICATION_SELLING,
  payload
})

export const getNotificationChat = payload => ({
  type: types.GET_NOTIFICATION_CHAT,
  payload
})
export const getNotificationChatSuccess = payload => ({
  type: types.GET_NOTIFICATION_CHAT_SUCCESS,
  payload
})
export const setIsLoadingNotificationChat = payload => ({
  type: types.IS_LOADING_GET_NOTIFICATION_CHAT,
  payload
})
