export default {
  GET_DECORATION_MOBILE : 'get-decoration-mobile',
  GET_DECORATION_MOBILE_SUCCESS : 'get-decoration-mobile-success',
  GET_DECORATION_WEB : 'get-decoration-web',
  GET_DECORATION_WEB_SUCCESS : 'get-decoration-web-success',
  IS_LOADING_GET_DECORATION : 'set-is-loading-get-decoration',

  CREATE_DECORATION_MOBILE : 'create-decoration-mobile',
  CREATE_DECORATION_WEB : 'create-decoration-web',
  CREATE_DECORATION_SUCCESS : 'create-decoration-success',
  IS_LOADING_CREATE_DECORATION : 'set-is-loading-create-decoration',

  SET_DECORATION_SUCCESS: 'set-decoration-success'
};
