import types from "./types";

const initialState = {
  chats: {
    count: 0,
    data: [],
    loading: false,
  },
  messages: {
    count: 0,
    data: [],
    loading: false,
    sending: false,
  },
  chatbox: {
    show: false,
    store_id: null,
    user_id: null,
    chat_id: null,
    detail: {},
  },
  selected: {
    store: {},
    user: {},
    id: null,
    order: {},
    product: {},
  },
  notification: {
    data: 0,
    loading: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NOTIFICATION_CHATS_SUCCESS:
      return {
        ...state,
        notification: {
          ...state.notification,
          data: action.payload,
        },
      };
    case types.IS_LOADING_GET_NOTIFICATION_CHATS:
      return {
        ...state,
        notification: {
          ...state.notification,
          loading: action.payload,
        },
      };
    case types.RESET_GET_NOTIFICATION_CHATS:
      return {
        ...state,
        notification: initialState.notification,
      };
    case types.GET_CHATS_SUCCESS:
      return {
        ...state,
        chats: {
          ...state.chats,
          data: action.payload.data,
          count: action.payload.count,
        },
      };
    case types.IS_LOADING_GET_CHATS:
      return {
        ...state,
        chats: {
          ...state.chats,
          loading: action.payload,
        },
      };
    case types.RESET_GET_CHATS:
      return {
        ...state,
        chats: initialState.chats,
      };

    case types.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          data: action.payload.data,
          count: action.payload.count,
        },
      };
    case types.IS_LOADING_GET_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: action.payload,
        },
      };
    case types.RESET_GET_MESSAGES:
      return {
        ...state,
        messages: initialState.messages,
      };

    case types.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          data: [...state.messages.data.concat(action.payload)].sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          ),
        },
      };
    case types.IS_LOADING_SEND_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: action.payload,
        },
      };
    case types.RESET_SEND_MESSAGE:
      return {
        ...state,
        messages: initialState.messages,
      };
    case types.SET_SENDING_SEND_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          sending: action.payload,
        },
      };
    case types.UPDATE_READ_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          data: action.payload.data,
          count: action.payload.count,
        },
      };
    case types.IS_LOADING_UPDATE_READ_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: action.payload,
        },
      };
    case types.SET_USER_CHAT:
      return {
        ...state,
        selected: {
          ...state.selected,
          user: action.payload,
        },
        chats: {
          ...state.chats,
          data: state.chats.data.map((item) =>
            item.user_id === action.payload.id
              ? {
                  ...item,
                  notification: 0,
                }
              : item
          ),
        },
      };
    case types.SET_ORDER:
      return {
        ...state,
        selected: {
          ...state.selected,
          order: action.payload,
        },
      };
    case types.SET_PRODUCT:
      return {
        ...state,
        selected: {
          ...state.selected,
          product: action.payload,
        },
      };
    case types.SET_SHOW_CHATBOX:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          show: action.payload.show,
          detail: action.payload.detail,
          store_id: action.payload.store_id,
          chat_id: action.payload.chat_id,
          user_id: action.payload.user_id,
        },
      };
    default:
      return state;
  }
};

export const getNotificationChats = (payload) => ({
  type: types.GET_NOTIFICATION_CHATS,
  payload,
});
export const getNotificationChatsSuccess = (payload) => ({
  type: types.GET_NOTIFICATION_CHATS_SUCCESS,
  payload,
});
export const setIsLoadingGetNotificationChats = (payload) => ({
  type: types.IS_LOADING_GET_NOTIFICATION_CHATS,
  payload,
});
export const resetGetNotificationChats = (payload) => ({
  type: types.RESET_GET_NOTIFICATION_CHATS,
  payload,
});

export const getChats = (payload) => ({
  type: types.GET_CHATS,
  payload,
});
export const getChatsSuccess = (payload) => ({
  type: types.GET_CHATS_SUCCESS,
  payload,
});
export const setIsLoadingGetChats = (payload) => ({
  type: types.IS_LOADING_GET_CHATS,
  payload,
});
export const resetGetChats = (payload) => ({
  type: types.RESET_GET_CHATS,
  payload,
});
export const getMessagesById = (payload) => ({
  type: types.GET_MESSAGES_BY_ID,
  payload,
});
export const getMessagesByBuyer = (payload) => ({
  type: types.GET_MESSAGES_BY_BUYER,
  payload,
});
export const getMessagesSuccess = (payload) => ({
  type: types.GET_MESSAGES_SUCCESS,
  payload,
});
export const setIsLoadingGetMessages = (payload) => ({
  type: types.IS_LOADING_GET_MESSAGES,
  payload,
});
export const resetGetMessages = (payload) => ({
  type: types.RESET_GET_MESSAGES,
  payload,
});
export const sendMessage = (payload) => ({
  type: types.SEND_MESSAGE,
  payload,
});
export const sendMessageSuccess = (payload) => ({
  type: types.SEND_MESSAGE_SUCCESS,
  payload,
});
export const setIsLoadingSendMessage = (payload) => ({
  type: types.IS_LOADING_SEND_MESSAGE,
  payload,
});
export const resetSendMessage = (payload) => ({
  type: types.RESET_SEND_MESSAGE,
  payload,
});
export const updateReadChat = (payload) => ({
  type: types.UPDATE_READ_CHAT,
  payload,
});
export const updateReadChatSuccess = (payload) => ({
  type: types.UPDATE_READ_MESSAGE_SUCCESS,
  payload,
});
export const setIsLoadingUpdateReadChat = (payload) => ({
  type: types.IS_LOADING_UPDATE_READ_MESSAGE,
  payload,
});
export const setUserChat = (payload) => ({
  type: types.SET_USER_CHAT,
  payload,
});
export const setOrder = (payload) => ({
  type: types.SET_ORDER,
  payload,
});
export const setProduct = (payload) => ({
  type: types.SET_PRODUCT,
  payload,
});
export const setShowChatbox = (payload) => ({
  type: types.SET_SHOW_CHATBOX,
  payload,
});
export const setSendingChat = (payload) => ({
  type: types.SET_SENDING_SEND_MESSAGE,
  payload,
});
