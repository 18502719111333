import { put, call, takeLatest, delay } from "redux-saga/effects";
import Api from "../../services";
import types from "./constant";
import * as actions from "./reducer";

function* watchGetComplaints(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingComplaints(true));
  try {
    const response = yield call(Api.complaint.get, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getComplaintsSuccess(data.data));
      yield put(actions.setCount(data.count));
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.setIsLoadingComplaints(false));
  } finally {
    yield put(actions.setIsLoadingComplaints(false));
  }
}

function* watchGetDetailComplaint(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingDetailComplaint(true));
  try {
    const response = yield call(Api.complaint.getDetail, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDetailComplaintSuccess(data.data));
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.setIsLoadingDetailComplaint(false));
  } finally {
    yield put(actions.setIsLoadingDetailComplaint(false));
  }
}

function* watchGetComplaintHistories(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetHistories(true));
  try {
    const response = yield call(Api.complaint.getHistories, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getHistoriesSuccess(data.data));
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetHistories(false));
  } finally {
    yield put(actions.setIsLoadingGetHistories(false));
  }
}

function* watchGetComplaintDiscussions(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetDiscussions(true));
  try {
    const response = yield call(Api.complaint.getDiscussions, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDiscussionsSuccess(data.data));
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetDiscussions(false));
  } finally {
    yield put(actions.setIsLoadingGetDiscussions(false));
  }
}
function* watchPostDiscussion(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingPostDiscussion(true));
  try {
    const response = yield call(Api.complaint.postDiscussion, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.postDiscussionSuccess(data));
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.setIsLoadingPostDiscussion(false));
  } finally {
    yield put(actions.setIsLoadingPostDiscussion(false));
  }
}

function* watchPostPriceBid(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingPostBidPrice(true));
  try {
    const response = yield call(Api.complaint.postBid, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.postBidPriceSuccess(data));
      yield put(actions.setPriceBidSuccess(true));
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.setIsLoadingPostBidPrice(false));
  } finally {
    yield delay(100);
    yield put(actions.setPriceBidSuccess(false));
    yield put(actions.setIsLoadingPostBidPrice(false));
  }
}
function* watchPostDeliveryReceipt(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingPostDeliveryReceipt(true));
  try {
    const response = yield call(Api.complaint.postDeliveryReceipt, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.postDeliveryReceiptSuccess(data));
      yield put(actions.setDeliveryReceiptSuccess(true));
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.setIsLoadingPostDeliveryReceipt(false));
  } finally {
    yield put(actions.setDeliveryReceiptSuccess(false));
    yield put(actions.setIsLoadingPostDeliveryReceipt(false));
  }
}
function* watchPutApproved(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingPutApproved(true));
  try {
    const response = yield call(Api.complaint.putApprove, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.putApprovedSuccess(data));
      yield put(actions.setApprovedSuccess(true));
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.setIsLoadingPutApproved(false));
  } finally {
    yield delay(100);
    yield put(actions.setApprovedSuccess(false));
    yield put(actions.setIsLoadingPutApproved(false));
  }
}
function* watchPutAskForHelp(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingPutAskForHelp(true));
  try {
    const response = yield call(Api.complaint.askForHelp, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.putAskForHelpSuccess(data));
      yield put(actions.setAskForHelpSuccess(true));
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.setIsLoadingPutAskForHelp(false));
  } finally {
    yield put(actions.setAskForHelpSuccess(false));
    yield put(actions.setIsLoadingPutAskForHelp(false));
  }
}

function* watchGetServices(value) {
  yield put(actions.setIsLoadingServices(true));
  try {
    const response = yield call(Api.complaint.getServices);
    const { data } = response;
    if (data.success) {
      yield put(actions.getServicesSuccess(data.data));
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.setIsLoadingServices(false));
  } finally {
    yield put(actions.setIsLoadingServices(false));
  }
}
const sagas = [
  takeLatest(types.GET_SERVICES_COMPLAINT, watchGetServices),
  takeLatest(types.GET_COMPLAINTS, watchGetComplaints),
  takeLatest(types.GET_DETAIL_COMPLAINT, watchGetDetailComplaint),
  takeLatest(types.GET_COMPLAINT_HISTORIES, watchGetComplaintHistories),
  takeLatest(types.GET_COMPLAINT_DISCUSSIONS, watchGetComplaintDiscussions),
  takeLatest(types.POST_COMPLAINT_DISCUSSION, watchPostDiscussion),
  takeLatest(types.POST_BID_PRICE_COMPLAINT, watchPostPriceBid),
  takeLatest(types.POST_DELIVERY_RECEIPT_COMPLAINT, watchPostDeliveryReceipt),
  takeLatest(types.PUT_APPROVED_COMPLAINT, watchPutApproved),
  takeLatest(types.ASK_FOR_HELP_COMPLAINT, watchPutAskForHelp),
];

export default sagas;
