import types from './constant';

const initialState = {
  detail:{},
  count:0,
  data:[],
  my_request: [],
  my_offer: [],
  create_offer:{},
  created_offer: false,
  updated_offer: false,
  deleted_offer: false,
  create_comment:{},
  isLoadingCreateComment:false,
  isLoadingCreateOffer:false,
  isLoadingUpdateOffer:false,
  isLoadingDeleteOffer:false,
  isLoadingGet: false,
};

export default (state = initialState, action)=>{
  switch (action.type) {
    case types.CREATE_OFFER_RFQ_SUCCESS:
      return {
        ...state,
        create_offer: action.payload,
        detail: {
          ...state.detail,
          response_request: [action.payload, ...state.detail.response_request]
        }
      }
    case types.IS_LOADING_CREATE_OFFER_RFQ:
      return {...state, isLoadingCreateOffer: action.payload}
    case types.SET_CREATED_OFFER_RFQ:
      return {...state, created_offer: action.payload}
    case types.DELETE_OFFER_RFQ_SUCCESS:
      return {...state,
        detail: {
          ...state.detail,
          response_request: state.detail.response_request.filter(item => item._id !== action.payload.id)
        }
      }
    case types.IS_LOADING_DELETE_OFFER_RFQ:
      return {...state, isLoadingDeleteOffer: action.payload}
    case types.UPDATE_OFFER_RFQ_SUCCESS:
      return {...state,
        detail: {
          ...state.detail,
          response_request: state.detail.response_request.map(item => item._id === action.payload.id ? {
            ...item,
            ...action.payload
          }:item)
        }
      }
    case types.IS_LOADING_UPDATE_OFFER_RFQ:
      return {...state, isLoadingUpdateOffer: action.payload}
    case types.SET_UPDATED_OFFER_RFQ:
      return {...state, updated_offer: action.payload}
    case types.SET_DELETED_OFFER_RFQ:
      return {...state, deleted_offer: action.payload}
    case types.CREATE_COMMENT_OFFER_RFQ_SUCCESS:
      return {
        ...state,
        create_comment: action.payload,
        detail: {
          ...state.detail,
          response_request: state.detail.response_request.map(item => item._id === action.payload.ResponseRFQ ? {
            ...item,
            comments: [action.payload, ...item.comments]
          }:item)
        }
      }
    case types.IS_LOADING_CREATE_COMMENT_OFFER_RFQ:
      return {...state, isLoadingCreateComment: action.payload}
    case types.GET_MY_REQUEST_SUCCESS:
      return {...state, my_request: action.payload}
    case types.GET_MY_OFFER_SUCCESS:
      return {...state, my_offer: action.payload}
    case types.GET_RFQ_DETAIL_SUCCESS:
      return {...state, detail: action.payload}
    case types.GET_RFQ_SUCCESS:
      return {...state, data:action.payload}
    case types.SET_COUNT_RFQ:
      return {...state, count:action.payload}
    case types.IS_LOADING_GET_RFQ:
      return {...state, isLoadingGet:action.payload}
    case types.RESET_RFQ:
      return initialState
    default:
      return state;
  }
}


export const getMyRequest = payload => ({
  type: types.GET_MY_REQUEST,
  payload
})
export const getMyRequestSuccess = payload => ({
  type: types.GET_MY_REQUEST_SUCCESS,
  payload
})

export const getMyOffer = payload => ({
  type: types.GET_MY_OFFER,
  payload
})
export const getMyOfferSuccess = payload => ({
  type: types.GET_MY_OFFER_SUCCESS,
  payload
})

export const createCommentOfferRFQ = payload => ({
  type: types.CREATE_COMMENT_OFFER_RFQ,
  payload
})
export const createCommentOfferRFQSuccess = payload => ({
  type: types.CREATE_COMMENT_OFFER_RFQ_SUCCESS,
  payload
})
export const setIsLoadingCreateCommentOfferRFQ = payload => ({
  type: types.IS_LOADING_CREATE_COMMENT_OFFER_RFQ,
  payload
})

export const updateOfferRFQ = payload => ({
  type: types.UPDATE_OFFER_RFQ,
  payload
})
export const updateOfferRFQSuccess = payload => ({
  type: types.UPDATE_OFFER_RFQ_SUCCESS,
  payload
})
export const setIsLoadingUpdateOfferRFQ = payload => ({
  type: types.IS_LOADING_UPDATE_OFFER_RFQ,
  payload
})
export const setUpdatedOfferRFQ = payload => ({
  type: types.SET_UPDATED_OFFER_RFQ,
  payload
})

export const deleteOfferRFQ = payload => ({
  type: types.DELETE_OFFER_RFQ,
  payload
})
export const deleteOfferRFQSuccess = payload => ({
  type: types.DELETE_OFFER_RFQ_SUCCESS,
  payload
})
export const setIsLoadingDeleteOfferRFQ = payload => ({
  type: types.IS_LOADING_DELETE_OFFER_RFQ,
  payload
})
export const setDeletedOfferRFQ = payload => ({
  type: types.SET_DELETED_OFFER_RFQ,
  payload
})

export const createOfferRFQ = payload => ({
  type: types.CREATE_OFFER_RFQ,
  payload
})
export const createOfferRFQSuccess = payload => ({
  type: types.CREATE_OFFER_RFQ_SUCCESS,
  payload
})
export const setIsLoadingCreateOfferRFQ = payload => ({
  type: types.IS_LOADING_CREATE_OFFER_RFQ,
  payload
})
export const setCreatedOfferRFQ = payload => ({
  type: types.SET_CREATED_OFFER_RFQ,
  payload
})

export const getRFQ = payload => ({
  type: types.GET_RFQ,
  payload
})
export const getRFQSuccess = payload => ({
  type: types.GET_RFQ_SUCCESS,
  payload
})

export const getRFQDetail = payload => ({
  type: types.GET_RFQ_DETAIL,
  payload
})
export const getRFQDetailSuccess = payload => ({
  type: types.GET_RFQ_DETAIL_SUCCESS,
  payload
})

export const setCountGetRFQ = payload => ({
  type: types.SET_COUNT_RFQ,
  payload
})
export const setLoadingGetRFQ = payload => ({
  type: types.IS_LOADING_GET_RFQ,
  payload
})

export const resetRFQ = payload => ({
  type: types.RESET_RFQ
})
