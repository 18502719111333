import { Api } from './api';

export default {
  get: payload => Api.get(`/v2/showcases/seller`,{
    headers: {
      token: payload.token
    }
  }),
  add: payload => Api.post(`/v2/showcases/seller/`,{name:payload.name},{
    headers: {
      token: payload.token
    }
  }),
  update: payload => Api.put(`/v2/showcases/seller/${payload.id}`,{name:payload.name},{
    headers: {
      token: payload.token
    }
  }),
  delete: payload => Api.delete(`/v2/showcases/seller/${payload.id}`,{
    headers: {
      token: payload.token
    }
  }),
};
