import { put, call, takeLatest, select, delay } from "redux-saga/effects";
import { toast } from "react-toastify";
import Api from "../../services";
import types from "./types";
import * as actions from "./reducer";
import * as actionsSettings from "../../components/settings/reducer";
import * as actionsProduct from "../../components/product/reducer";

function* watchGetFBPixel(values) {
  yield put(actions.setIsLoadingGetFBPixel(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.apps.getFBPixel, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getFBPixelSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getFBPixelSuccess(state.apps.data));
  } finally {
    yield put(actions.setIsLoadingGetFBPixel(false));
  }
}
function* watchAddFBPixel(values) {
  yield put(actions.setIsLoadingAddFBPixel(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.apps.addFBPixel, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.addFBPixelSuccess(data.data));
      yield put(actions.setAddedFBPixel(true));
      yield toast("Meta / Facebook Pixel telah ditambahkan", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  } catch (e) {
    yield put(actions.getFBPixelSuccess(state.apps.data));
    yield toast("Meta / Facebook Pixel gagal ditambahkan", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } finally {
    yield delay(500);
    yield put(actions.setAddedFBPixel(false));
    yield put(actions.setIsLoadingAddFBPixel(false));
  }
}
function* watchUpdateFBPixel(values) {
  yield put(actions.setIsLoadingUpdateFBPixel(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.apps.updateFBPixel, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updateFBPixelSuccess(data.data));
      yield put(actions.setUpdatedFBPixel(true));
      yield toast("Meta / Facebook Pixel telah diubah", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  } catch (e) {
    yield put(actions.getFBPixelSuccess(state.apps.data));
    yield toast("Meta / Facebook Pixel gagal didiubah", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } finally {
    yield delay(500);
    yield put(actions.setUpdatedFBPixel(false));
    yield put(actions.setIsLoadingUpdateFBPixel(false));
  }
}
function* watchUpdateStoreFBPixel(values) {
  yield put(actions.setIsLoadingUpdateFBPixel(true));
  const { apps, product, setting } = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.apps.updateStoreFBPixel, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updateFBPixelSuccess(data.data));
      yield put(actions.setUpdatedFBPixel(true));
      yield put(
        actionsSettings.getShopSuccess({ ...setting.shop, fb_pixel: data.data })
      );
      yield put(
        actionsProduct.getProductsSuccess(
          product.data.map((item) => ({
            ...item,
            fb_pixel:
              item.fb_pixel && item.fb_pixel.pixel_id !== payload.pixel_id
                ? item.fb_pixel
                : null,
          }))
        )
      );
    }
  } catch (e) {
    yield put(actions.getFBPixelSuccess(apps.data));
  } finally {
    yield delay(500);
    yield put(actions.setUpdatedFBPixel(false));
    yield put(actions.setIsLoadingUpdateFBPixel(false));
  }
}
function* watchUpdateProductFBPixel(values) {
  yield put(actions.setIsLoadingUpdateFBPixel(true));
  const { apps } = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.apps.updateProductFBPixel, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updateFBPixelSuccess(data.data));
      yield put(actions.setUpdatedFBPixel(true));
    }
  } catch (e) {
    yield put(actions.getFBPixelSuccess(apps.data));
  } finally {
    yield delay(500);
    yield put(actions.setUpdatedFBPixel(false));
    yield put(actions.setIsLoadingUpdateFBPixel(false));
  }
}
function* watchDeleteFBPixel(values) {
  yield put(actions.setIsLoadingDeleteFBPixel(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.apps.deleteFBPixel, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.deleteFBPixelSuccess({ id: payload.id }));
      yield put(actions.setDeletedFBPixel(true));
      yield toast("Meta / Facebook Pixel telah dihapus", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  } catch (e) {
    yield put(actions.getFBPixelSuccess(state.apps.data));
    yield toast("Meta / Facebook Pixel gagal dihapus", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } finally {
    yield delay(500);
    yield put(actions.setDeletedFBPixel(false));
    yield put(actions.setIsLoadingDeleteFBPixel(false));
  }
}

const sagas = [
  takeLatest(types.GET_FB_PIXEL, watchGetFBPixel),
  takeLatest(types.ADD_FB_PIXEL, watchAddFBPixel),
  takeLatest(types.UPDATE_FB_PIXEL, watchUpdateFBPixel),
  takeLatest(types.UPDATE_STORE_FB_PIXEL, watchUpdateStoreFBPixel),
  takeLatest(types.UPDATE_PRODUCT_FB_PIXEL, watchUpdateProductFBPixel),
  takeLatest(types.DELETE_FB_PIXEL, watchDeleteFBPixel),
];

export default sagas;
