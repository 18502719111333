import types from './constant';

const initialState = {
  sidebarShow: 'responsive'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SIDEBAR:{
      return {...state, sidebarShow: action.payload }
    }
    default:
      return state
  }
}

export const setSidebar = payload => ({
  type: types.SET_SIDEBAR,
  payload
})
