import { Api } from "./api";

export default {
  get: (payload) =>
    Api.get(`/v2/services/seller`, {
      headers: {
        token: payload.token,
      },
    }),
  cities: () => Api.get(`/subdistricts`),
  update: (payload) =>
    Api.post(
      `/v2/services/seller`,
      {
        services: payload.service_type_ids,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
  schedules: (payload) =>
    Api.get(`/v2/shipping/check/schedules`, {
      headers: {
        token: payload.token,
      },
    }),
  tracking: (payload) =>
    Api.post(
      `/v2/shipping/tracking`,
      {
        awb: payload.awb,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
};
