import { Api } from "./api";

export default {
  get: {
    chats: (payload) =>
      Api.get("/v2/chat/seller", {
        headers: {
          token: payload.token,
        },
        params: {
          limit: payload.limit,
          page: payload.page,
        },
      }),
    message: (payload) =>
      Api.post(
        "/v2/chat/seller/message",
        {
          user_id: payload.user_id,
        },
        {
          headers: {
            token: payload.token,
          },
          params: {
            limit: payload.limit,
            page: payload.page,
          },
        }
      ),
    notification: (payload) =>
      Api.get("/v2/chat/seller/notification", {
        headers: {
          token: payload.token,
        },
      }),
  },
  send: (payload) =>
    Api.post("/v2/chat/seller/send-message", payload.data, {
      headers: {
        token: payload.token,
      },
    }),
};
