import { Api } from './api';

export default {
  getMobile: payload => Api.get(`/v2/store-decorations/mobile/${payload.id}`),
  createMobile: payload => Api.post(`/v2/store-decorations/mobile`, payload.data, {
    headers: {
      token:payload.token,
      'Content-Type': 'multipart/form-data'
    }
  }),
  getWeb: payload => Api.get(`/v2/store-decorations/web/${payload.id}`),
  createWeb: payload => Api.post(`/v2/store-decorations/web`, payload.data, {
    headers: {
      token:payload.token,
      'Content-Type': 'multipart/form-data'
    }
  }),
};
